import React, { useState } from "react";
import { Text, Flex, Box, Button } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { AccountsDataContext } from "contexts/accountsDataContext";
import TitleAndDescription from "../components/TitleAndDescription";
import { IoMdInformationCircle } from "react-icons/io";
import AccountsGrid from "../../accounts/AccountsGrid";
import AccountTile from "../../accounts/AccountTile";
import { useHistory } from "react-router-dom";
import ImageUploader from "../../accounts/pages/offers/components/ImageUploader";
import ContentExampleUgc from "../components/ContentExampleUGC";
import PrioritySelection from "../components/PrioritySelection";

interface SetPriorityProps {
  setStep: Function;
  setAutopilotDataMap: Function;
  autopilotDataMap: Map<string, any>;
}

function SetPriority({
  setStep,
  setAutopilotDataMap,
  autopilotDataMap,
}: SetPriorityProps) {
  const contentTypes = autopilotDataMap.get("contentTypes");
  const contentTypesPreSelected = autopilotDataMap.get(
    "contentTypesPreSelected"
  );
  const editingContentType = autopilotDataMap.get("editingContentType");

  async function handleClick() {
    for (let i = 0; i < contentTypes.length; i++) {
      let contentTypeData = autopilotDataMap.get(contentTypes[i]);

      if (contentTypes[i] === "instagramStory") {
        contentTypeData.set("priority", instagramStoryPriority);
      } else if (contentTypes[i] === "instagramPost") {
        contentTypeData.set("priority", instagramPostPriority);
      } else if (contentTypes[i] === "instagramReel") {
        contentTypeData.set("priority", instagramReelPriority);
      } else if (contentTypes[i] === "ugc") {
        contentTypeData.set("priority", ugcPriority);
      } else if (contentTypes[i] === "tiktokVideo") {
        contentTypeData.set("priority", tiktokVideoPriority);
      }

      setAutopilotDataMap(() => {
        const tempMap = new Map(autopilotDataMap);
        tempMap.set(contentTypes[i], contentTypeData);
        return tempMap;
      });
    }

    setStep(4);
  }


  function backFunction() {
    setStep(2);
  }

  const [instagramStoryPriority, setInstagramStoryPriority] = useState(
    autopilotDataMap.get("instagramStory")?.get("priority") ?? "low"
  );
  const [instagramPostPriority, setInstagramPostPriority] = useState(
    autopilotDataMap.get("instagramPost")?.get("priority") ?? "low"
  );
  const [instagramReelPriority, setInstagramReelPriority] = useState(
    autopilotDataMap.get("instagramReel")?.get("priority") ?? "low"
  );
  const [ugcPriority, setUgcPriority] = useState(
    autopilotDataMap.get("ugc")?.get("priority") ?? "low"
  );
  const [tiktokVideoPriority, setTiktokVideoPriority] = useState(
    autopilotDataMap.get("tiktokVideo")?.get("priority") ?? "low"
  );

  return (
    <Flex flexDirection="column" alignItems="start">
      <Flex justifyContent="start" alignItems="center" width="100%">
        <TitleAndDescription
          hideBackButton={false}
          title={"Content Priority"}
          description={"Select the priority of each content type"}
          backFunction={backFunction}
        />

        <Box
          marginLeft="auto"
          color="white"
          display="inline-block"
          width="50px"
          height="50px"
          borderRadius="50px"
          backgroundImage={autopilotDataMap.get("clientProfilePic")}
          backgroundSize="cover"
        />
      </Flex>

      <Box borderTop="1.3px solid #E2E8F0" width="100%" mt="1.5%" />
      <Box
        height="650px"
        borderRadius="lg"
        boxShadow="lg"
        overflowY="auto"
        width="100%"
        mt="2.5%"
        p={7}
      >
        <Flex justifyContent="start" alignItems="center">
          <Flex width="25%" justifyContent="start" alignItems="center">
            <Text fontSize="20px" fontWeight="400">
              Content Type
            </Text>
            <Box
              ml="2%"
              as="div"
              cursor="pointer"
              onClick={() => {}}
              display="inline-block" // Ensures the icon doesn't take full width
            >
              <IoMdInformationCircle color="#6F767E" size="20px" />
            </Box>
          </Flex>
          <Flex width="25%" justifyContent="start" alignItems="center">
            <Text fontSize="20px" fontWeight="400">
              Priority
            </Text>
            <Box
              ml="2%"
              as="div"
              cursor="pointer"
              onClick={() => {}}
              display="inline-block" // Ensures the icon doesn't take full width
            >
              <IoMdInformationCircle color="#6F767E" size="20px" />
            </Box>
          </Flex>
        </Flex>
        <Box borderTop="1.3px solid #E2E8F0" width="100%" mt="1.5%" />
        {((contentTypes.includes("instagramStory") &&
          !contentTypesPreSelected.includes("instagramStory")) ||
          editingContentType === "instagramStory") && (
          <PrioritySelection
            setPriority={setInstagramStoryPriority}
            contentType={"instagramStory"}
            priority={instagramStoryPriority}
          />
        )}
        {((contentTypes.includes("instagramPost") &&
          !contentTypesPreSelected.includes("instagramPost")) ||
          editingContentType === "instagramPost") && (
          <PrioritySelection
            setPriority={setInstagramPostPriority}
            contentType={"instagramPost"}
            priority={instagramPostPriority}
          />
        )}
        {((contentTypes.includes("instagramReel") &&
          !contentTypesPreSelected.includes("instagramReel")) ||
          editingContentType === "instagramReel") && (
          <PrioritySelection
            setPriority={setInstagramReelPriority}
            contentType={"instagramReel"}
            priority={instagramReelPriority}
          />
        )}
        {((contentTypes.includes("ugc") &&
          !contentTypesPreSelected.includes("ugc")) ||
          editingContentType === "ugc") && (
          <PrioritySelection
            setPriority={setUgcPriority}
            contentType={"ugc"}
            priority={ugcPriority}
          />
        )}
        {((contentTypes.includes("tiktokVideo") &&
          !contentTypesPreSelected.includes("tiktokVideo")) ||
          editingContentType === "tiktokVideo") && (
          <PrioritySelection
            setPriority={setTiktokVideoPriority}
            contentType={"tiktokVideo"}
            priority={tiktokVideoPriority}
          />
        )}
      </Box>
      <Box borderTop="1.3px solid #E2E8F0" width="100%" mt="7.5%" />

      {/* {contentTypes.includes("instagramPost") && (
          <ContentExampleUgc
            setContentExampleUGC={setInstagramPostExampleUGC}
            contentType={"instagramPost"}
            setIsContentExampleUGCValid={setIsInstagramPostExampleUGCValid}
          />
        )}
        {contentTypes.includes("instagramReel") && (
          <ContentExampleUgc
            setContentExampleUGC={setInstagramReelExampleUGC}
            contentType={"instagramReel"}
            setIsContentExampleUGCValid={setIsInstagramReelExampleUGCValid}
          />
        )}
        {contentTypes.includes("ugc") && (
          <ContentExampleUgc
            setContentExampleUGC={setUgcExampleUGC}
            contentType={"ugc"}
            setIsContentExampleUGCValid={setIsUgcExampleUGCValid}
          />
        )}
 */}
      <Flex justifyContent="flex-end" width="100%" p={4} mt="1.5%">
        <Button
          onClick={handleClick}
          backgroundColor="black"
          width="150px"
          p="10px 15px"
          borderRadius="10px"
          transition="transform 0.2s, box-shadow 0.2s"
          transform="translateY(0)"
          _hover={{
            transform: "scale(1.05)",
          }}
          _active={{}}
        >
          <Text
            fontWeight="500"
            fontSize="15px"
            textAlign="center"
            color="white"
          >
            Next
          </Text>
        </Button>
      </Flex>
    </Flex>
  );
}

export default SetPriority;
