import React from "react";
import { Button, Text } from "@chakra-ui/react";
import GeneralSettings from "./GeneralSettings";
import { auth } from "../../../../firebase";
import SeatSettings from "./SeatSettings";
import PaymentSetting from "./PaymentSetting";

interface SideBarProps {
  activeSection: string;
}

const SettingSideBar: React.FC<SideBarProps> = ({ activeSection }) => {
  // content for to sidebard icons
  // add more icon and tab if needed in sidebar
  const getActiveSectionContent = () => {
    switch (activeSection) {
      case "general":
        return <GeneralSettings />;
      case "seats":
        return <SeatSettings />;
      case "payment":
        return <PaymentSetting />;
      //tempory hide this section
      // case 'integrations':
      default:
        return null;
    }
  };
  return <>{getActiveSectionContent()}</>;
};

export default SettingSideBar;
