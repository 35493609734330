// Chakra imports
import { Flex } from "@chakra-ui/react";
import Logo from "../../../assets/logos/storyItLogo.png";
import SuperUserLogo from "../../../assets/logos/superUser.png";
import "../../../assets/css/StoryIt.css";
import { useHistory } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebase";

export function SidebarStoryItLogo() {
	const [user] = useAuthState(auth);

	const isSuperUser =
		user?.email?.includes("@storyit.us") ||
		user?.email?.includes("bransonjpackard@gmail.com") ||
		user?.email?.includes("wilgeller@gmail.com") ||
		user?.email?.includes("hardikg@evincedev.com") ||
		user?.email?.includes("nirmal@evincedev.com") || user?.email?.includes("nirmalevince@gmail.com");; // for now, give all storyit emails superuser access
	const history = useHistory();

	return (
		<Flex alignItems="left" flexDirection="column" cursor="pointer">
			<img
				src={isSuperUser ? SuperUserLogo : Logo}
				alt="Storyit Logo"
				width="60px"
				height="auto"
				onClick={() =>
					isSuperUser
						? history.push("/superuser/")
						: history.push("/admin/overview/")
				}
				className="sideBar-storyit-logo"
			/>
		</Flex>
	);
}

export default SidebarStoryItLogo;
