import React, { useContext, useState, useEffect } from "react";
import { Box, Text, Flex, Spinner, background } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import { CustomersDataContext } from "contexts/customersDataContext";
import BackButton from "components/buttons/BackButton";
import Card from "components/card/Card";
import CustomerCard from "../components/customerTile";
import DropdownMenuCommunity from "../components/DropdownMenuCommunity";
import { AdminDataContext } from "../../../../contexts/adminDataContext";
import { AccountsDataContext } from '../../../../contexts/accountsDataContext';

const CustomerList = () => {
  const { data: customersData, customerDataContextIsLoading } =
    useContext(CustomersDataContext);
  const location = useLocation();
  const [isBackButtonHovered, setIsBackButtonHovered] = useState(false);
  const  accountsData = useContext(AccountsDataContext);
  const queryParams = new URLSearchParams(location.search);
  const clientID = queryParams.get("accountId");
  const clientInformation = accountsData[clientID] ?? {};

  // add qury param getting the client ID, filter automatically

  if ( customerDataContextIsLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="200px">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (!customersData || Object.keys(customersData).length === 0) {
    return <div>No Data Available</div>;
  }

  const customerMap = new Map();
  const processCustomerData = (clientData: any) => {

    Object.keys(clientData)
      .filter((key) => key !== "id")
      .forEach((phoneNumber) => {

        const customer = clientData[phoneNumber];


        // Check if customer is an influencer if isInfluencerMode is true
        // If isInfluencerMode is false, process all customers
        // Assumes customer.isInfluencer is a boolean that's true for influencers
        // if (isInfluencerMode && !customer.isInfluencer) {
        //   return; // Skip this customer if not an influencer when in influencer mode
        // }
        // if (!customer.instagramHandle) return; // Skip if no Instagram handle
  
        if (customerMap.has(phoneNumber)) {
          const existingCustomer = customerMap.get(phoneNumber);
          existingCustomer.acceptedOffers = Array.from(
            new Set([
              ...(existingCustomer?.acceptedOffers ?? []),
              ...(customer?.acceptedOffers ?? []),
            ])
          );
          existingCustomer.postedOffers = Array.from(
            new Set([
              ...(existingCustomer?.postedOffers ?? []),
              ...(customer?.postedOffers ?? []),
            ])
          );
          existingCustomer.redeemedOffers = Array.from(
            new Set([
              ...(existingCustomer?.redeemedOffers ?? []),
              ...(customer?.redeemedOffers ?? []),
            ])
          );
        } else {
          customerMap.set(phoneNumber, { ...customer, "phoneNumber": phoneNumber });
        }
      });
  };
  if (clientID) {
    // If a specific clientID is provided, process only that client's customers
    if (customersData[clientID]) {
      processCustomerData(customersData[clientID]);
    }
  } else {
    // If no specific clientID is provided, process all clients' customers
    Object.keys(customersData).forEach((clientId) => {
      const clientData = customersData[clientId];
      processCustomerData(clientData);
    });
  }

  // Sort and select the top 5 customers based on the specified criteria
  const customers = Array.from(customerMap.values())
    .sort((a, b) => {
      const redeemedOffersDiff =
        b.redeemedOffers.length - a.redeemedOffers.length;
      if (redeemedOffersDiff !== 0) return redeemedOffersDiff;

      const postedOffersDiff = b.postedOffers.length - a.postedOffers.length;
      if (postedOffersDiff !== 0) return postedOffersDiff;

      const acceptedOffersDiff =
        b.acceptedOffers.length - a.acceptedOffers.length;
      if (acceptedOffersDiff !== 0) return acceptedOffersDiff;

      return b.instagramFollowerCount - a.instagramFollowerCount;
    })
    ;

  // const clientData = customersData[clientID];
  // if (clientData !== null && clientData !== undefined) {
  //   Object.keys(clientData)
  //     .filter((key) => key !== "id")
  //     .forEach((phoneNumber) => {
  //       if (!clientData[phoneNumber].instagramHandle) return;
  //       const customer = clientData[phoneNumber];

  //       if (customerMap.has(phoneNumber)) {
  //         const existingCustomer = customerMap.get(phoneNumber);
  //         existingCustomer.acceptedOffers = Array.from(
  //           new Set([
  //             ...existingCustomer.acceptedOffers,
  //             ...customer.acceptedOffers,
  //           ])
  //         );
  //         existingCustomer.postedOffers = Array.from(
  //           new Set([
  //             ...existingCustomer.postedOffers,
  //             ...customer.postedOffers,
  //           ])
  //         );
  //         existingCustomer.redeemedOffers = Array.from(
  //           new Set([
  //             ...existingCustomer.redeemedOffers,
  //             ...customer.redeemedOffers,
  //           ])
  //         );
  //       } else {
  //         customerMap.set(phoneNumber, { ...customer, phoneNumber });
  //       }
  //     });
  // }

  // const customers = Array.from(customerMap.values()).sort((a, b) => {
  //   const redeemedOffersDiff =
  //     b.redeemedOffers.length - a.redeemedOffers.length;
  //   if (redeemedOffersDiff !== 0) return redeemedOffersDiff;

  //   const postedOffersDiff = b.postedOffers.length - a.postedOffers.length;
  //   if (postedOffersDiff !== 0) return postedOffersDiff;

  //   const acceptedOffersDiff =
  //     b.acceptedOffers.length - a.acceptedOffers.length;
  //   if (acceptedOffersDiff !== 0) return acceptedOffersDiff;

  //   return b.instagramFollowerCount - a.instagramFollowerCount;
  // });

  if ( customerDataContextIsLoading) {
    return (
      <Card flexDirection="column" mt="25px">
        <Flex justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
        </Flex>
      </Card>
    );
  }

  return (
    <Box backgroundColor="white" borderRadius="10px">
      <Flex justifyContent="space-between" pr="10px">
        <Flex alignItems="center" pt="25px" pb="25px">
          <BackButton
            isHovered={isBackButtonHovered}
            setIsHovered={setIsBackButtonHovered}
          />
          <Text ml="10px" fontWeight="bold" fontSize="30px">
            All Customers for {Object.keys(clientInformation).length === 0 ? "all accounts" : clientInformation["accountName"]}
          </Text>
        </Flex>

        {/* <DropdownMenuCommunity
          clientIDs={adminData.clientIDs}
          setfilteredClientID={setfilteredClientID}
        /> */}
      </Flex>

      <Flex alignItems="center" justifyContent="center">
        <Box borderTop="1.3px solid #E2E8F0" mb="10px" width="96%" />
      </Flex>
      <Box width="100%">
        <Flex flexDirection="column" alignItems="center">
          {customers.map((customer: any, index: any) => (
            <CustomerCard
              width="90%"
              my="0px"
              customer={customer}
              showStats={true}
              key={index}
              clientID={clientID}
            />
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

export default CustomerList;
