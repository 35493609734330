import { Box, Flex, Text } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { useState } from 'react';




const CopyLink = ({ url }: { url: string }) => {
	const [isCopied, setIsCopied] = useState(false);
  
	const copyToClipboard = () => {
	  navigator.clipboard.writeText(url);
	  setIsCopied(true);
	  setTimeout(() => setIsCopied(false), 2000);
	};
	const textStyles = {
		wordWrap: "break-word",
	  };
	
  
	return (
		<Box textAlign="center" mt="2%">
		<Flex align="center" justify="center">
		  <Box maxWidth = "90%"><Text  color = "#1877F2"mr="2">{url} </Text></Box>
		  <CopyIcon cursor="pointer" onClick={copyToClipboard} />
		</Flex>
		{isCopied && <Text mt="10px"color="green.500" fontSize="15px">Copied to clipboard successfully!</Text>}
	  </Box>
	  );
  };
  
  export default CopyLink;
  