import { mode } from '@chakra-ui/theme-tools';
export const inputStyles = {
	components: {
		Input: {
			baseStyle: {
				field: {
					fontWeight: 400,
					borderRadius: '8px'
				}
			},

			variants: {
				chakra_input_default: (props: any) => ({
					field: {
						bg: mode('#fff', '#fff')(props),
						border: '0.5px solid',
						color: mode('#33383F', '#33383F')(props),
						borderColor: mode('#000', '#000')(props),
						borderRadius: '12px',
						transition: 'all 0.4s ease-in-out 0s',
						_focus: {
							borderColor: mode('#000', '#000')(props),
							bg: mode('#f4f4f4', '#f4f4f4')(props),
						},
						_active: {
							borderColor: mode('#000', '#000')(props),
							bg: mode('#f4f4f4', '#f4f4f4')(props),
						},

						// we don't need it as we have implemented it for read only mode 
						_readOnly: {
							borderColor: mode('#f4f4f4', '#f4f4f4')(props),
							bg: mode('#f4f4f4', '#f4f4f4')(props),
						}
					}
				}),
				chakra_input_light: (props: any) => ({
					field: {
						bg: mode('#F4F4F4', '#F4F4F4')(props),
						border: '1px solid',
						color: mode('#000', '#33383F')(props),
						borderColor: mode('#F4F4F4', '#F4F4F4')(props),
						borderRadius: '12px',
						transition: 'all 0.4s ease-in-out 0s',
						_focus: {
							borderColor: mode('#33383F', '#33383F')(props),
						},
						_active: {
							borderColor: mode('#33383F', '#33383F')(props),
						},
					}
				}),
				main: (props: any) => ({
					field: {
						bg: mode('transparent', 'navy.800')(props),
						border: '1px solid',
						color: mode('black', 'white')(props),
						borderColor: mode('secondaryGray.100', 'whiteAlpha.100')(props),
						borderRadius: '16px',
						fontSize: 'sm',
						p: '20px',
						_placeholder: { color: 'secondaryGray.400' }
					}
				}),
				auth: (props: any) => ({
					field: {
						fontWeight: '500',
						color: mode('navy.700', 'white')(props),
						bg: mode('transparent', 'transparent')(props),
						border: '1px solid',
						borderColor: mode('secondaryGray.100', 'rgba(135, 140, 189, 0.3)')(props),
						borderRadius: '16px',
						_placeholder: { color: 'secondaryGray.600', fontWeight: '400' }
					}
				}),
				authSecondary: () => ({
					field: {
						bg: 'transparent',
						border: '1px solid',
						borderColor: 'secondaryGray.100',
						borderRadius: '16px',
						_placeholder: { color: 'secondaryGray.600' }
					}
				}),
				search: () => ({
					field: {
						border: 'none',
						py: '11px',
						borderRadius: 'inherit',
						_placeholder: { color: 'secondaryGray.600' }
					}
				})
			},
			sizes: {
				'chakra_md_medium': (props: any) => ({
					field: {
						h: 'auto',
						fontSize: '14px',
						lineHeight: '24px',
						p: '9px 12px',
						fontWeight: 500
					}
				}),
				'chakra_md': (props: any) => ({
					field: {
						h: 'auto',
						fontSize: '14px',
						lineHeight: '24px',
						p: '11px 12px',
					}
				}),
				'chakra_sm': (props: any) => ({
					field: {
						h: 'auto',
						fontSize: '14px',
						lineHeight: '24px',
						p: '2px 12px',
						fontWeight: 600,
						borderRadius: '8px'
					}
				})
			}
		},
		NumberInput: {
			baseStyle: {
				field: {
					fontWeight: 400
				}
			},

			variants: {
				main: () => ({
					field: {
						bg: 'transparent',
						border: '1px solid',

						borderColor: 'secondaryGray.100',
						borderRadius: '16px',
						_placeholder: { color: 'secondaryGray.600' }
					}
				}),
				auth: () => ({
					field: {
						bg: 'transparent',
						border: '1px solid',

						borderColor: 'secondaryGray.100',
						borderRadius: '16px',
						_placeholder: { color: 'secondaryGray.600' }
					}
				}),
				authSecondary: () => ({
					field: {
						bg: 'transparent',
						border: '1px solid',

						borderColor: 'secondaryGray.100',
						borderRadius: '16px',
						_placeholder: { color: 'secondaryGray.600' }
					}
				}),
				search: () => ({
					field: {
						border: 'none',
						py: '11px',
						borderRadius: 'inherit',
						_placeholder: { color: 'secondaryGray.600' }
					}
				})
			}
		},
		Select: {
			baseStyle: {
				field: {
					fontWeight: 400
				}
			},

			variants: {
				chakra_select_default: (props: any) => ({
					field: {
						bg: mode('#F4F4F4', '#F4F4F4')(props),
						border: '1px solid',
						color: mode('#101828', '#101828')(props),
						borderColor: mode('#F4F4F4', '#F4F4F4')(props),
						borderRadius: '8px',
						transition: 'all 0.4s ease-in-out 0s',
						_focus: {
							borderColor: mode('#f4f4f4', '#33383F')(props),
						},
						_active: {
							borderColor: mode('#f4f4f4', '#f4f4f4')(props),
						},
						_hover: {
							borderColor: mode('#f4f4f4', '#f4f4f4')(props),
						},
					}
				}),
				main: (props: any) => ({
					field: {
						bg: mode('transparent', 'navy.800')(props),
						border: '1px solid',
						color: 'secondaryGray.600',
						borderColor: mode('secondaryGray.100', 'whiteAlpha.100')(props),
						borderRadius: '16px',
						_placeholder: { color: 'secondaryGray.600' }
					},
					icon: {
						color: 'secondaryGray.600'
					}
				}),
				mini: (props: any) => ({
					field: {
						bg: mode('transparent', 'navy.800')(props),
						border: '0px solid transparent',
						fontSize: '0px',
						p: '10px',
						_placeholder: { color: 'secondaryGray.600' }
					},
					icon: {
						color: 'secondaryGray.600'
					}
				}),
				subtle: () => ({
					box: {
						width: 'unset'
					},
					field: {
						bg: 'transparent',
						border: '0px solid',
						color: 'secondaryGray.600',
						borderColor: 'transparent',
						width: 'max-content',
						_placeholder: { color: 'secondaryGray.600' }
					},
					icon: {
						color: 'secondaryGray.600'
					}
				}),
				transparent: (props: any) => ({
					field: {
						bg: 'transparent',
						border: '0px solid',
						width: 'min-content',
						color: mode('secondaryGray.600', 'secondaryGray.600')(props),
						borderColor: 'transparent',
						padding: '0px',
						paddingLeft: '8px',
						paddingRight: '20px',
						fontWeight: '700',
						fontSize: '14px',
						_placeholder: { color: 'secondaryGray.600' }
					},
					icon: {
						transform: 'none !important',
						position: 'unset !important',
						width: 'unset',
						color: 'secondaryGray.600',
						right: '0px'
					}
				}),
				auth: () => ({
					field: {
						bg: 'transparent',
						border: '1px solid',

						borderColor: 'secondaryGray.100',
						borderRadius: '16px',
						_placeholder: { color: 'secondaryGray.600' }
					}
				}),
				authSecondary: (props: any) => ({
					field: {
						bg: 'transparent',
						border: '1px solid',

						borderColor: 'secondaryGray.100',
						borderRadius: '16px',
						_placeholder: { color: 'secondaryGray.600' }
					}
				}),
				search: (props: any) => ({
					field: {
						border: 'none',
						py: '11px',
						borderRadius: 'inherit',
						_placeholder: { color: 'secondaryGray.600' }
					}
				})
			},
			sizes: {
				'chakra_md_medium': (props: any) => ({
					field: {
						h: 'auto',
						fontSize: '16px',
						lineHeight: '24px',
						p: '10px 12px',
						fontWeight: 500
					}
				}),
				'chakra_md': (props: any) => ({
					field: {
						h: 'auto',
						fontSize: '14px',
						lineHeight: '24px',
						p: '11px 12px',
					}
				}),
				'chakra_sm': (props: any) => ({
					field: {
						h: 'auto',
						fontSize: '14px',
						lineHeight: '24px',
						p: '2px 12px',
						fontWeight: 600,
						borderRadius: '8px'
					}
				})
			}
		},
		FormLabel: {
			variants: {
				chakra_label_default: (props: any) => ({
					fontSize: 'chakra_sm',
					lineHeight: "24px",
					mb: '14px',
					fontWeight: 600,
					color: "#33383F", // Change to your desired color
					minWidth: '305px',
				}),
				chakra_label_light: (props: any) => ({
					fontSize: 'chakra_xs',
					lineHeight: 1.5,
					color: "#787878",
					mb: '7px',
				})

			}
		}
	}
};
