import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { FaChevronLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

interface BackButtonProps {
    isHovered: boolean;
    setIsHovered: React.Dispatch<React.SetStateAction<boolean>>;
}

const BackButton: React.FC<BackButtonProps> = ({ isHovered, setIsHovered }) => {
    const history = useHistory();

    return (
        <IconButton
            aria-label="Go Back"
            icon={<FaChevronLeft size="20" />}
            onClick={() => {
                history.goBack();
            }}
            style={{
                background: "transparent",
                border: "none",
                paddingLeft: 25,
                paddingRight: 10,
                transition: "transform 0.3s, shadow 0.3s",
                transform: isHovered ? "scale(1.3)" : "scale(1)",
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        />
    );
};

export default BackButton;
