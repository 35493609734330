import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
import Card from "components/card/Card";

interface DefaultProps {
  startContent?: JSX.Element;
  backgroundColor?: string;
  endContent?: JSX.Element;
  name: string;
  growth?: string | number;
  value: string | number;
  [x: string]: any;
}

const Default: React.FC<DefaultProps> = ({
  startContent,
  backgroundColor,
  endContent,
  name,
  growth,
  value,
  ...rest
}) => {
  const textColor = useColorModeValue("black", "white");
  const textColorSecondary = "secondaryGray.600";

  return (
    <Card
      width="24.5%"
      boxShadow="0 2px 6px rgba(.05, .05,.05, 0.05)"
      {...rest}
    >
      <Flex
        my="auto"
        backgroundColor={backgroundColor ? backgroundColor : "white"}
        align={{ base: "center", xl: "start" }}
        justify={{ base: "center", xl: "center" }}
      >
        {startContent}
        <Stat my="auto" ms={startContent ? "25px" : "0px"}>
          <StatLabel
            lineHeight="100%"
            color={textColorSecondary}
            fontSize={{
              base: "md",
            }}
          >
            {name}
          </StatLabel>
          <StatNumber
            color={textColor}
            fontSize={{
              base: "2xl",
            }}
          >
            {value}
          </StatNumber>
          {growth ? (
            <Flex align="center">
              <Text color="green.500" fontSize="xs" fontWeight="700" me="5px">
                {growth}
              </Text>
              <Text color="secondaryGray.600" fontSize="xs" fontWeight="400">
                since last month
              </Text>
            </Flex>
          ) : null}
        </Stat>
        <Flex ms="auto" w="max-content">
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
};

export default Default;
