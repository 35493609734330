import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { IoMdInformationCircle } from "react-icons/io";
import TitleAndDescription from "../components/TitleAndDescription";

interface ContentParametersProps {
  contentDataMap: Map<String, any>;
  setContentDataMap: Function;
  isOpen: boolean;
  handleClose: () => void;
  integrations: String[];
}

function ContentParameters({
  setContentDataMap,
  contentDataMap,
  isOpen,
  handleClose,
  integrations,
}: ContentParametersProps) {
  const [contentType] = useState<string>(contentDataMap.get("contentType"));

  const [minCash, setMinCash] = useState<number>(
    contentDataMap.get("min$") ?? null
  );
  const [maxCash, setMaxCash] = useState<number>(
    contentDataMap.get("max$") ?? null
  );
  const [minPercent, setMinPercent] = useState<number>(
    contentDataMap.get("min%") ?? null
  );
  const [maxPercent, setMaxPercent] = useState<number>(
    contentDataMap.get("max%") ?? null
  );
  const [integration, setIntegration] = useState<string>(
    contentDataMap.get("integration") ?? ""
  );

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent minW="60%">
        <ModalHeader pl="5%" pt="3%">
          <TitleAndDescription
            hideBackButton={true}
            backFunction={() => {}}
            title={
              contentType === "instagramStory"
                ? "Instagram Story"
                : contentType === "instagramPost"
                ? "Instagram Post"
                : contentType === "instagramReel"
                ? "Instagram Reel"
                : contentType === "ugc" ? "UGC" : "Tiktok Video"
            }
            description={
              contentType === "instagramStory"
                ? "24 hours instagram story, photo or video format"
                : contentType === "instagramPage"
                ? "Instagram post, picture format"
                : contentType === "instagramReel"
                ? "Instagram reel, video format"
                : contentType === "ugc" ? "User generated content sent directly to you, picture or video format" : "Tiktok post, video format"
            }
          />
          <Box
            borderTop="1.3px solid #E2E8F0"
            width="100%"
            mt="2.5%"
            ml="-2%"
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            width="100%"
            justifyContent="space-between"
            alignItems="start"
            flexDirection="row"
          >
            <Flex width="50%" flexDirection="column">
              <Flex
                ml="2%"
                justifyContent="start"
                alignItems="center"
                flexDirection="row"
                width="85%"
              >
                <Flex width="40%" alignItems="center">
                  <Text fontSize="18px" fontWeight="400">
                    Min $ off
                  </Text>
                  <Box
                    ml="3%"
                    as="div"
                    cursor="pointer"
                    onClick={() => {}}
                    display="inline-block" // Ensures the icon doesn't take full width
                  >
                    <IoMdInformationCircle color="#6F767E" size="20px" />
                  </Box>

                  {/* enter textfield here */}
                </Flex>
                <Input
                  isRequired={true}
                  value={minCash}
                  width="80%"
                  fontSize="md"
                  type="number" // Change the type to "text"
                  borderRadius="10px"
                  placeholder="Minimum $ reward"
                  fontWeight="500"
                  size="md"
                  onChange={(e) => {
                    const value = e.target.value;
                    const numericValue = value.replace(/[^\d.]/g, ""); // Remove non-numeric characters except for dot
                    setMinCash(parseInt(numericValue) || null); // Parse as integer or default to 0
                  }}
                  pattern="[0-9]*" // Allow only numeric values
                />
              </Flex>
              <Flex
                ml="2%"
                justifyContent="start"
                alignItems="center"
                flexDirection="row"
                width="85%"
                mt="10%"
              >
                <Flex width="40%" alignItems="center">
                  <Text fontSize="18px" fontWeight="400">
                    Max $ off
                  </Text>
                  <Box
                    ml="3%"
                    as="div"
                    cursor="pointer"
                    onClick={() => {}}
                    display="inline-block"
                  >
                    <IoMdInformationCircle color="#6F767E" size="20px" />
                  </Box>
                </Flex>
                <Input
                  isRequired={true}
                  value={maxCash}
                  width="80%"
                  fontSize="md"
                  type="number"
                  borderRadius="10px"
                  placeholder="Maximum $ reward"
                  fontWeight="500"
                  size="md"
                  onChange={(e) => {
                    const value = e.target.value;
                    const numericValue = value.replace(/[^\d.]/g, "");
                    setMaxCash(parseInt(numericValue) || null); // Parse as integer or default to 0
                  }}
                  pattern="[0-9]*" // Allow only numeric values
                />
              </Flex>
              <Flex
                ml="2%"
                justifyContent="start"
                alignItems="center"
                flexDirection="row"
                width="85%"
                mt="10%"
              >
                <Flex width="40%" alignItems="center">
                  <Text fontSize="18px" fontWeight="400">
                    Min % off
                  </Text>
                  <Box
                    ml="3%"
                    as="div"
                    cursor="pointer"
                    onClick={() => {}}
                    display="inline-block" // Ensures the icon doesn't take full width
                  >
                    <IoMdInformationCircle color="#6F767E" size="20px" />
                  </Box>

                  {/* enter textfield here */}
                </Flex>
                <Input
                  isRequired={true}
                  value={minPercent}
                  width="80%"
                  fontSize="md"
                  type="number" // Change the type to "text"
                  borderRadius="10px"
                  placeholder="Minimum % reward"
                  fontWeight="500"
                  size="md"
                  onChange={(e) => {
                    const value = e.target.value;
                    const numericValue = value.replace(/[^\d.]/g, ""); // Remove non-numeric characters except for dot
                    setMinPercent(parseInt(numericValue) || null); // Parse as integer or default to 0
                  }}
                  pattern="[0-9]*" // Allow only numeric values
                />
              </Flex>

              <Flex
                ml="2%"
                justifyContent="start"
                alignItems="center"
                flexDirection="row"
                width="85%"
                mt="10%"
              >
                <Flex width="40%" alignItems="center">
                  <Text fontSize="18px" fontWeight="400">
                    Max % off
                  </Text>
                  <Box
                    ml="3%"
                    as="div"
                    cursor="pointer"
                    onClick={() => {}}
                    display="inline-block" // Ensures the icon doesn't take full width
                  >
                    <IoMdInformationCircle color="#6F767E" size="20px" />
                  </Box>

                  {/* enter textfield here */}
                </Flex>
                <Input
                  isRequired={true}
                  value={maxPercent}
                  width="80%"
                  fontSize="md"
                  type="number" // Change the type to "text"
                  borderRadius="10px"
                  placeholder="Maximum % reward"
                  fontWeight="500"
                  size="md"
                  onChange={(e) => {
                    const value = e.target.value;
                    const numericValue = value.replace(/[^\d.]/g, ""); // Remove non-numeric characters except for dot
                    setMaxPercent(parseInt(numericValue) || null); // Parse as integer or default to 0
                  }}
                  pattern="[0-9]*" // Allow only numeric values
                />
              </Flex>
            </Flex>

            <Flex width="50%" flexDirection="column">
              {integrations.length > 0 && (
                <Flex
                  ml="2%"
                  justifyContent="start"
                  alignItems="center"
                  flexDirection="row"
                  width="100%"
                >
                  <Flex width="40%" alignItems="center">
                    <Text fontSize="18px" fontWeight="400">
                      Integration
                    </Text>
                    <Box
                      ml="3%"
                      as="div"
                      cursor="pointer"
                      onClick={() => {}}
                      display="inline-block" // Ensures the icon doesn't take full width
                    >
                      <IoMdInformationCircle color="#6F767E" size="20px" />
                    </Box>
                  </Flex>
                  <Select
                    // placeholder={integration}
                    width="180px"
                    value={integration.toUpperCase()}
                    onChange={(event) => {
                      const newIntegration = event.target.value.toLowerCase();
                      console.log("Setting integration to: ", newIntegration);
                      setIntegration(newIntegration);
                    }}
                  >
                    ={" "}
                    {integrations.map((integrationOption: any) => (
                      <option
                        key={integrationOption}
                        value={integrationOption.toUpperCase()}
                      >
                        {integrationOption.toUpperCase()}
                      </option>
                    ))}
                    {/* Add "none" as an additional option */}
                    <option value="NONE">None</option>
                  </Select>
                </Flex>
              )}
              {/* <Flex
                ml="2%"
                justifyContent="start"
                alignItems="center"
                flexDirection="row"
                width="100%"
                mt={integrations.length > 0 ? "10%" : "0%"}
              >
                <Flex width="40%" alignItems="center">
                  <Text fontSize="18px" fontWeight="400">
                    Example UGC
                  </Text>
                  <Box
                    ml="3%"
                    as="div"
                    cursor="pointer"
                    onClick={() => {}}
                    display="inline-block" // Ensures the icon doesn't take full width
                  >
                    <IoMdInformationCircle color="#6F767E" size="20px" />
                  </Box>
                </Flex>
                {/* {(!contentDataMap.get("validExampleUGC") ?? true) && (
                  <ImageUploader
                    isButton={true}
                    setExampleUGC={setExampleUGC}
                    setIsImageValid={setIsExampleUGCValid}
                  />
                )}
                {(contentDataMap.get("validExampleUGC") ?? false) && (
                  <Text>photo has been uploaded</Text>
                )} */}
              {/* </Flex>  */}
            </Flex>
          </Flex>

          {/* Your modal content goes here */}
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={
              minCash === null ||
              maxCash === null ||
              minPercent === null ||
              maxPercent === null
            }
            onClick={() => {
              setContentDataMap(() => {
                const tempMap = new Map(contentDataMap);
                tempMap.set("min$", minCash);
                tempMap.set("max$", maxCash);
                tempMap.set("min%", minPercent);
                tempMap.set("max%", maxPercent);
                tempMap.set("integration", integration);
                return tempMap;
              });
              handleClose();
            }}
            backgroundColor="black"
            width="150px"
            p="10px 15px"
            borderRadius="10px"
            transition="transform 0.2s, box-shadow 0.2s"
            transform="translateY(0)"
            _hover={{
              transform: "scale(1.05)",
            }}
            _active={{}}
          >
            <Text
              fontWeight="500"
              fontSize="15px"
              textAlign="center"
              color="white"
            >
              Finish
            </Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ContentParameters;
