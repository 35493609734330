/* eslint-disable react-hooks/rules-of-hooks */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Environment from "environment";
import { Dispatch, SetStateAction } from "react";

import StripePaymentMethodForm from "./StripePaymentMethodForm";


/**
 * props for paymentsettingmodel 
 */
interface PaymentSettingModelProps {
  isOpen: boolean;
  onClose: () => any;
  setLoadPaymentMethod: Dispatch<SetStateAction<boolean>>;
  modelTitle: string,
  email: string
}


/**
 * it will open on click of add paymentsetting from the payment setting page
 * @param PaymentSettingModelProps
 * @returns 
 */
function PaymentSettingModel({
  isOpen,
  onClose,
  modelTitle,
  setLoadPaymentMethod,
  email
}: PaymentSettingModelProps) {

  const stripePromise = loadStripe(Environment.STRIPE_SECRET_KEY);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'xl'} isCentered >
      <ModalOverlay />
      <ModalContent p={'20px'}>
        <ModalHeader p={'0px'} mb={'16px'}>
          {modelTitle}
        </ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody p={'0'}>

          {
            <Elements stripe={stripePromise} >

              <StripePaymentMethodForm onClose={onClose} email={email} setLoadPaymentMethod={setLoadPaymentMethod} />
            </Elements>
          }
        </ModalBody>
      </ModalContent >
    </Modal >
  );
}

export default PaymentSettingModel
