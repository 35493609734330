import React from "react";
import { Text, Box, Flex, Switch } from "@chakra-ui/react";

type InfluencerSwitchProps = {
  isInfluencerMode: boolean;
  toggleSwitch: Function;
};

const InfluencerSwitch = ({
  isInfluencerMode,
  toggleSwitch,
}: InfluencerSwitchProps) => {
  return (
    <Box width="45%" bg="#F4F4F4" p={3} borderRadius="10px">
      <Flex alignItems="center" height="100%" justifyContent="space-evenly">
        <Switch
          colorScheme="green"
          defaultChecked={isInfluencerMode}
          onChange={() => {
            toggleSwitch(!isInfluencerMode);
          }}
        />
        <Text>Influencers</Text>
      </Flex>
    </Box>
  );
};

export default InfluencerSwitch;
