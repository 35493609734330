import React, { useState, ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useRef } from 'react';
import { Box, Text, Select, Button, Flex } from '@chakra-ui/react';
import { OffersDataContext } from 'contexts/offersDataContext';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { createPaymentIntent } from 'services/flaskService';


// changes made by nirmal on date :- 26/03/2024

interface DropdownMenuProps {
  setFilteredClientId: Dispatch<SetStateAction<string>>;
  clientAccounts?: any[],
  filteredClientId: string
}

const Dropdown: React.FC<DropdownMenuProps> = ({
  setFilteredClientId,
  clientAccounts,
  filteredClientId
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const getAccountName = (clientId: string) => {
    /**
     * Changes made by nirmal as per discussed with uv need to remove all account option from dropdown.
     */
    // let account = "All accounts";
    let account = ""
    if (!filteredClientId && clientAccounts?.length) {
      const { accountName = "", clientID = "" } = clientAccounts[0];
      account = accountName;
      setFilteredClientId(clientID)
    }


    if (clientId && clientAccounts?.length && clientId !== 'allAccounts') {
      const { accountName = "" } = clientAccounts.find((a) => a.clientID === clientId);
      account = accountName;
    }
    return account
  }
  // set state for the showing filterName
  const [accountName, setAccountName] = useState(
    getAccountName(filteredClientId));

  // handle for dropdown open and close
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  // update filter Name when select from the dropdown
  const updateFilterName = (clientId: string) => {
    setAccountName(getAccountName(clientId));
  };

  const clientModifiedAccount = [...clientAccounts];

  const dropdownRef = useRef<HTMLDivElement>(null);
  const closeDropdown = (e) => {
    if (!dropdownRef.current || dropdownRef.current !== e.target && isOpen) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => document.removeEventListener("click", closeDropdown);
  }, [isOpen]);

  return (
    <Box position="relative">
      <Flex
        align="center"
        p="3"
        bg="#F4F4F4"
        borderRadius="10px"
        cursor="pointer"
        onClick={toggleDropdown}
        //added this closedropdown when click outside
        ref={dropdownRef}
      >
        <Text fontSize="15px" color="black" fontWeight="400" width={200} >
          {accountName}
        </Text>
        {isOpen ? (
          <ChevronDownIcon ml={10} w="5" h="5" color="grey" />
        ) : (
          <ChevronUpIcon ml={10} w="5" h="5" color="grey" />
        )}
      </Flex>

      {isOpen && (
        <Box
          position="absolute"
          top="100%"
          bg="#F4F4F4"
          boxShadow="sm"
          borderRadius="10px"
          mt="2"
          zIndex="2"
          maxHeight={190}
          overflow={"auto"}
          width={284}

        >
          <Flex direction="column" p="2">
            {
              clientModifiedAccount?.length > 0 && clientModifiedAccount.map(({ accountName, clientID }, index) => (

                <Box
                  background={filteredClientId === clientID
                    ? "#a9ff5a" : ""}
                  cursor="pointer"
                  key={index}
                  p="2"
                  borderRadius="10px"
                  _hover={{
                    bg: filteredClientId === clientID
                      ? "#90F831"
                      : "gray.200"
                  }}

                  onClick={() => {
                    // check is offerName is equal to all offers
                    // if (accountName === 'All accounts' && clientID === 'allAccounts') {
                    //   setFilteredClientId("");
                    //   updateFilterName(clientID);
                    // } else {
                    setFilteredClientId(clientID);
                    updateFilterName(clientID);
                    // }
                    toggleDropdown();
                  }}
                >
                  <Text >{accountName}</Text>
                </Box>
              ))
            }
          </Flex>
        </Box >
      )}
    </Box >
  );
};

export default Dropdown;
