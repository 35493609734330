// Chakra imports
import { Box, Text, Flex, Button } from "@chakra-ui/react";
import React, { useContext } from "react";
import AccountsGrid from "./AccountsGrid";
import { AccountsDataContext } from "contexts/accountsDataContext";
import { AdminDataContext } from "contexts/adminDataContext";
import { useHistory } from "react-router-dom";

export default function Accounts() {
  const history = useHistory();
  const accountsData = useContext(AccountsDataContext);
  const { adminData, isLoading } = useContext(AdminDataContext);

  return (
    <Box>
      <Box
        overflow="hidden"
        backgroundColor="white"
        borderRadius="10px"
        width="100%"
        height={
          adminData && adminData.clientIDs
            ? Math.ceil(adminData.clientIDs.length / 4) * 290 + 90
            : 500
        }
        position="relative"
      >
        <Box position="absolute" mt="40px" left={5} width="100%" height="100%">
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <Text ml="10px" mt="-17.5px" fontWeight="bold" fontSize="25px">
                Choose an account
              </Text>
            </Flex>
            <Button
              onClick={() => {
                history.push("/admin/accounts/create-account/");
              }}
              backgroundColor="black"
              mr="40px"
              mt="-17.5px"
              p="10px 15px"
              borderRadius="10px"
              transition="transform 0.2s, box-shadow 0.2s"
              transform="translateY(0)"
              boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
              _hover={{
                transform: "scale(1.05)",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
              _focus={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
              _active={{
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Text
                fontWeight="500"
                fontSize="18px"
                textAlign="center"
                color="white"
              >
                Add +
              </Text>
            </Button>
          </Flex>

          <Flex alignItems="center">
            <Box borderTop="1.3px solid #E2E8F0" mt="15px" width="96%" />
          </Flex>

          <AccountsGrid accountsData={accountsData} />
        </Box>
      </Box>
    </Box>
  );
}
