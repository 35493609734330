import { Button, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

export default function CreateOfferButton({ clientID }: { clientID: string }) {
  const history = useHistory();
  return (
    <Button
      onClick={() => {
        if (clientID=== "") {
          history.push("/admin/accounts/accounts/");
        }
        else {
          history.push(`/admin/create-offer/?accountId=${clientID}`);
        }
      }}
      backgroundColor="black"
      borderRadius="10px"
      transition="transform 0.2s, box-shadow 0.2s"
      transform="translateY(0)"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
      _hover={{
        transform: "scale(1.05)",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
      }}
      _focus={{
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
      }}
      _active={{
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Text fontWeight="500" fontSize="18px" textAlign="center" color="white">
        + Create Offer
      </Text>
    </Button>
  );
}
