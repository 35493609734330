import React, { createContext, useState, useEffect } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase";

export const CollaborationsDataContext = createContext();

export const CollaborationsDataProvider = ({ children }) => {
    const [collaborations, setCollaborations] = useState({});

    useEffect(() => {
        const collaborationsRef = collection(firestore, "collabs");


        const unsubscribe = onSnapshot(collaborationsRef, (snapshot) => {
            const fetchedCollaborations = {};
            snapshot.forEach((docSnap) => {
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    fetchedCollaborations[docSnap.id] = { id: docSnap.id, ...data };
                }
            });
            setCollaborations(fetchedCollaborations);
        });

        return () => unsubscribe();
    }, []);

    return (
        <CollaborationsDataContext.Provider value={collaborations}>
            {children}
        </CollaborationsDataContext.Provider>
    );
};

export default CollaborationsDataProvider;