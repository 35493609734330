import {
  Button,
  Heading,
  Text,
  Tooltip,
  Image,
  Spinner,
  Link,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import PaymentSettingModel from "./PaymentSettingModel";
import { Flex } from "@chakra-ui/react";
import { Wrap, WrapItem } from "@chakra-ui/react";
import { Box, Table, Tbody, Tr, Td, TableContainer } from "@chakra-ui/react";
import { PencilIcon } from "components/icons/Icons";
import {
  deletePaymentMethod,
  getInvoices,
  getPaymentMethods,
} from "services/flaskService";
import { ActiveUidContext } from "contexts/activeUidContext";
import { PaymentIconLink } from "components/icons/paymentIcons";
import defaultPaymentIcon from "assets/paymentIcons/generic.png";
import { DeleteIcon } from "@chakra-ui/icons";
import DeleteConfirmPopup from "./DeleteConfirmModel";

interface PaymentSettingProps { }

const PaymentSetting: React.FC<PaymentSettingProps> = () => {
  const [isPaymentSettingOpenModel, setIsPaymentSettingOpenModel] =
    useState(false);
  const [isDeletePaymentMethodOpenModel, setIsDeletePaymentMethodOpenModel] =
    useState(false);
  const [paymentMethodLoading, setPayMethodLoading] = useState(false);
  const [invoiceDetailLoading, setInvoiceDetailLoading] = useState(false);

  const [loadPaymentMethod, setLoadPaymentMethod] = useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [modelTitle, setModelTitle] = useState("");
  const handleModelContentType = (title: string) => {
    setModelTitle(title);
    setLoadPaymentMethod(false);
    setIsPaymentSettingOpenModel(true);
  };
  const { email = null } = useContext(ActiveUidContext);
  const handlePaymentSettingModelClose = () => {
    setIsPaymentSettingOpenModel(false);
  };

  const handleDeletePaymentMethodModelClose = () => {
    setIsDeletePaymentMethodOpenModel(false);
    setPaymentMethodId("");
  };

  useEffect(() => {
    const fetchInvoices = async () => {
      setInvoiceDetailLoading(true);
      const invoices: any = await getInvoices(email);
      // Do something with the invoices here
      // For example, setting them to state
      if (
        invoices?.payment_invoices &&
        invoices?.payment_invoices?.data?.length
      ) {
        setInvoiceDetail(invoices?.payment_invoices?.data);
      }
      setInvoiceDetailLoading(false);
    };
    fetchInvoices();
  }, []);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      setPayMethodLoading(true);
      const paymentMethods: any = await getPaymentMethods(email);
      if (
        paymentMethods?.payment_method &&
        paymentMethods?.payment_method?.data?.length
      ) {
        setPaymentMethods(paymentMethods?.payment_method?.data);
      }
      setPayMethodLoading(false);
    };
    fetchPaymentMethods();
  }, [loadPaymentMethod]);

  const handleDeletePaymentMethod = async () => {
    setLoadPaymentMethod(false);
    setIsDeletePaymentMethodOpenModel(false);
    const deleteMethod: any = await deletePaymentMethod(paymentMethodId, email);
    if (deleteMethod) {
      setLoadPaymentMethod(true);
      setPaymentMethodId("");
    }
  };

  return (
    <>
      <Flex alignItems="center" gap="30px" mb={"20px"}>
        <Heading as={"h1"} size="chakra_5xl">
          Payment Method
        </Heading>
        <Button
          size="chakra_md"
          variant="chakra_btn_gray"
          onClick={() => {
            handleModelContentType("Add Payment Method");
          }}
        >
          Add new payment method
        </Button>
      </Flex>

      {paymentMethodLoading ? (
        <Flex justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <>
          {paymentMethods?.length > 0 ? (
            <Box
              maxW={invoiceDetail?.length ? 1000 : "100%"}
              w={"100%"}
              maxHeight={"353px"}
              m={"-5px"}
              overflowY={"auto"}
              overflowX={"hidden"}
            >
              <Wrap spacing="0px" flexWrap="wrap">
                {paymentMethods.map((method, idx) => (
                  <WrapItem w={"33.33%"} p={"5px"} key={`${idx}-icon`}>
                    <Box
                      py={"14px"}
                      px={"16px"}
                      bg="#F7FAFC"
                      w="100%"
                      borderRadius={"21px"}
                    >
                      <Flex
                        w="100%"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="16px"
                      >
                        <Flex w="100%" alignItems="center" gap="16px">
                          <>
                            <Image
                              w="40px"
                              h="24px"
                              objectFit="cover"
                              src={
                                PaymentIconLink[
                                method?.card?.brand?.toLowerCase()
                                ] || defaultPaymentIcon
                              }
                              alt="Card"
                              border="1px"
                              borderColor="#C4C4C4"
                              borderRadius={"4px"}
                            />
                            <Flex flexWrap="wrap">
                              <Text
                                w="100%"
                                fontSize="chakra_md"
                                color="#0D121C"
                                fontWeight="medium"
                              >
                                ............{method?.card?.last4}
                              </Text>
                              <Text
                                w="100%"
                                fontSize="chakra_sm"
                                color="#4F6696"
                                fontWeight="normal"
                              >
                                {method?.card?.brand}
                              </Text>
                            </Flex>
                          </>
                        </Flex>
                        <DeleteIcon
                          color={"inherit"}
                          h="24px"
                          w="24px"
                          onClick={() => {
                            setPaymentMethodId(method.id);
                            setIsDeletePaymentMethodOpenModel(true);
                          }}
                        />
                      </Flex>
                    </Box>
                  </WrapItem>
                ))}
              </Wrap>
            </Box>
          ) : (
            <Flex
              width="100%"
              alignItems="center"
              justifyContent="center"
              fontWeight="medium"
            >
              No payment methods available
            </Flex>
          )}
        </>
      )}

      <Heading as={"h2"} size="chakra_5xl" mt={"69px"} mb={"30px"}>
        Invoices
      </Heading>

      {invoiceDetailLoading ? (
        <Flex justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Box
          maxW={invoiceDetail?.length ? 1000 : "100%"}
          overflow={"hidden"}
          w={"100%"}
        >
          <Box
            maxHeight={"353px"}
            overflow={"auto"}
            maxW={invoiceDetail?.length ? 1000 : "100%"}
            w={"100%"}
          >
            <TableContainer w={"100%"}>
              <Table variant="simple" fontWeight="medium">
                <Tbody>
                  {invoiceDetail?.length > 0 ? (
                    invoiceDetail.map((invoice, index) => (
                      <Tr key={index}>
                        <Td
                          fontSize="chakra_md"
                          color="black"
                          p={"10px 20px 10px 0"}
                        >
                          Invoice #{invoice.id}
                        </Td>
                        <Td
                          fontSize="chakra_sm"
                          color="#4F6696"
                          p={"10px 20px 10px 0"}
                        >
                          Amount: ${invoice.amount_due / 100}
                        </Td>
                        <Td
                          fontSize="chakra_sm"
                          color="#4F6696"
                          p={"10px 20px 10px 0"}
                        >
                          Date:{" "}
                          {new Date(invoice.created * 1000).toDateString()}
                        </Td>
                        <Td
                          fontSize="chakra_md"
                          color="black"
                          p={"10px 20px 10px 0"}
                        >
                          <Flex w="100%" gap="16px">
                            <Button
                              id={index.toString()}
                              variant="link"
                              fontWeight="medium"
                              fontSize="chakra_md"
                              color="black"
                              as="a"
                              href={`${invoice.invoice_pdf}?download=true`}
                              target="_blank"
                            >
                              Download
                            </Button>
                          </Flex>
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr key={`tr-no-content`} h={100}>
                      <Td
                        borderRight={"1px solid #DFDFDF"}
                        border={"1px solid #EFEFEF !important"}
                        textAlign={"center"}
                        fontSize="chakra_md"
                        lineHeight={1.5}
                        color={"#000000"}
                        p={"5px 15px"}
                        colSpan={4}
                      >
                        No invoices available.
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}

      {/* <Flex
                width="100%"
                alignItems="center"
                flexWrap="wrap"
                justifyContent="end"
                fontWeight="medium"
                mt={"40px"}

            >
                Please contact support at &nbsp;
                <Link
                    href="mailto:upsport@storyit.us"
                    color="red"
                    textDecoration="underline"
                    target={"_blank"}
                >
                    upsport@storyit.us
                </Link> &nbsp;for questions and assistance about your current plan
            </Flex> */}

      <PaymentSettingModel
        isOpen={isPaymentSettingOpenModel}
        onClose={handlePaymentSettingModelClose}
        setLoadPaymentMethod={setLoadPaymentMethod}
        email={email}
        modelTitle={modelTitle}
      />
      <DeleteConfirmPopup
        modelTitle="Delete Payment Method"
        modelContent="Are you sure want to delete payment method?"
        isOpen={isDeletePaymentMethodOpenModel}
        onClose={handleDeletePaymentMethodModelClose}
        handleDeleteModel={handleDeletePaymentMethod}
      />
    </>
  );
};

export default PaymentSetting;
