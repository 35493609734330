import { Flex, Text, Box, Switch, IconButton } from "@chakra-ui/react";
import { useContext } from "react";
import { Icon } from "@chakra-ui/react";
import { BiMap } from "react-icons/bi"; // Importing a different icon for location
import { MdLocationOn } from "react-icons/md"; // Importing a different icon for location
import { AccountsDataContext } from "contexts/accountsDataContext";
import { AiOutlineQrcode, AiOutlineDelete } from "react-icons/ai";
import { PiQrCodeBold, PiTrashSimpleBold } from "react-icons/pi";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import IconBox from "components/icons/IconBox";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import LocationStatTiles from './LocationStatTiles';
import RemoveLocationButton from "./RemoveLocationButton";
import QrCodeButton from "./QrCodeButton";


export default function LocationCardBig(props: {
  location: any;
  clientID: string;
}) {
  const { location, clientID } = props;

  const history = useHistory();

  function extractCityState(address: string) {
    const parts = address.split(",").map((part) => part.trim());
    let city, state;

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      if (/\b[A-Z]{2}\b/.test(part) && !state) {
        state = part;
      } else if (part && !city) {
        city = part;
      }
    }

    return `${city || ""}${city && state ? ", " : ""}${state || ""}`;
  }

  return (
    <Box
      borderRadius="10px"
      backgroundColor="#F5F5F5"
      width="95%"
      p="2%"
      mb="1%"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex flexDirection="row" ml={2} alignItems="center" >
          <IconBox
            w="55px"
            h="55px"
            bg="#598FE727"
            icon={<Icon as={BiMap} boxSize={7} color="#1877F2" />}
          />
          <Text color="#6F767E" fontWeight="500" fontSize="21px" ml={4}>
            {location.address}
          </Text>
        </Flex>

        <Flex flexDirection="column" >
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex ml="20px">
              <QrCodeButton clientID={clientID} locationID={location?.locationID} />
              {/* <RemoveLocationButton clientID={clientID} locationID={location?.locationID} address={location?.address} /> */}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Text fontWeight="500" fontSize="14px" color="grey" mb="2%">
        {location.locationDescription}
      </Text>

      <Flex alignItems="center">
        <Box borderTop="1.5px solid #EAECF0" mt="5px" mb="15px" width="100%" />
      </Flex>

      <Flex width="100%" justifyContent="space-between" alignItems="center">

        <LocationStatTiles stats={location.stats} offersCount={location?.offers?.length} />

        {/* <Text>{location.stats.signups}</Text> */}
        <IconBox
          _hover={{
            cursor: "pointer",
            transform: "scale(1.1)",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
          }}
          onClick={() => {
            history.push("/admin/view-location/?locationId=" + location.id + "&accountId=" +
              clientID);
          }}
          w="45px"
          h="45px"
          bg="black"
          icon={
            <ChevronRightIcon
              w="30px"
              h="30px"
              color="white"
            />
          }
        />
      </Flex>
    </Box>
  );
}
