/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Text,
  ModalCloseButton,
} from "@chakra-ui/react";

interface AddAdminAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleAccountCreation: Function;

  isAddingAccount: boolean;
}

function AddAdminAccountModal({ isOpen, onClose, handleAccountCreation, isAddingAccount }: AddAdminAccountModalProps) {
  const [accountName, setAccountName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("storyit1234");


  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="10px" maxW="70vw" maxH="80vh">
        <ModalHeader>
          <Flex alignItems="center">
            <Text ml="10px" fontWeight="bold" fontSize="25px">
              Create admin account
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
          <FormControl>
            <FormLabel
              mt="25px"
              display="flex"
              ms="30px"
              fontSize="lg"
              fontWeight="500"
              color="black"
              mb="12px"
            >
              Account Name<Text color="red">*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="lg"
              ml="30px"
              width="95%"
              type="text"
              borderRadius="13px"
              placeholder="Account Name"
              mb="24px"
              fontWeight="500"
              size="lg"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setAccountName(e.target.value)
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel
              mt="25px"
              display="flex"
              ms="30px"
              fontSize="lg"
              fontWeight="500"
              color="black"
              mb="12px"
            >
              Email<Text color="red">*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="lg"
              ml="30px"
              width="95%"
              type="email"
              borderRadius="13px"
              placeholder="Email"
              mb="24px"
              fontWeight="500"
              size="lg"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel
              mt="25px"
              display="flex"
              ms="30px"
              fontSize="lg"
              fontWeight="500"
              color="black"
              mb="12px"
            >
              Password<Text color="red">*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="lg"
              ml="30px"
              width="95%"
              type="text"
              borderRadius="13px"
              placeholder="Password"
              mb="24px"
              fontWeight="500"
              size="lg"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
            />
          </FormControl>

          <Button
            isDisabled={
              accountName.length === 0 ||
              email.length === 0 ||
              password.length === 0
            }
            onClick={async () => {
              handleAccountCreation(email, password, accountName);
              // TODO: Call backend admin account creation script
            }}
            m="25px"
            backgroundColor="black"
            ml="85%"
            height="50px"
            width="150px"
            borderRadius="10px"
            transition="transform 0.2s, box-shadow 0.2s"
            transform="translateY(0)"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
            _hover={{
              transform: "scale(1.05)",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            _focus={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            _active={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Text
              fontWeight="500"
              fontSize="20px"
              textAlign="center"
              color="white"
            >
              {isAddingAccount ? <Spinner color="white" mt="5px" /> : "Create"}
            </Text>
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default AddAdminAccountModal;
