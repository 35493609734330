import React, { useEffect } from "react";
import { Box, FormControl, FormLabel, Select } from "@chakra-ui/react";

interface OfferIntegrationProps {
  inputValues: {
    integration: string;
    integrations: any;
    isPrerequisite?: boolean;
    prerequisiteName?: string;
    prerequisiteQuantity?: number;
    prerequisiteCollectionID?: string;
    isFreeReward?: boolean;
    discountItemID?: string;
    discountItem?: string;
    discountType?: string;
    rewardClientIDs?: any[];
    rewardClientNames?: any[];
  };
  onInputChange: (
    fieldName: keyof OfferIntegrationProps["inputValues"],
    value: any
  ) => void;
  data: {
    clientIntegrations: string[];
    isEditing: boolean;
  };
}

const OfferIntegration: React.FC<OfferIntegrationProps> = ({
  inputValues,
  onInputChange,
  data,
}) => {
  const { clientIntegrations, isEditing } = data;
  useEffect(() => {
    if (isEditing && inputValues?.integration === "bigCommerce") {
      // handle for integration type bigcommerce
      onInputChange("isPrerequisite", false);
      onInputChange("prerequisiteName", "");
      onInputChange("prerequisiteQuantity", "");
      onInputChange("prerequisiteCollectionID", "");
      onInputChange("discountItemID", "");
      onInputChange("discountItem", "");
      onInputChange("isFreeReward", false);
      onInputChange("discountType", "Store Discount");
    }
  }, [isEditing]);

  const handleIntegrationChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event?.target;
    if (value && value !== inputValues?.integration && value === "bigCommerce") {
      // handle for integration type bigcommerce
      onInputChange("isPrerequisite", false);
      onInputChange("prerequisiteName", "");
      onInputChange("prerequisiteQuantity", "");
      onInputChange("prerequisiteCollectionID", "");
      onInputChange("discountItemID", "");
      onInputChange("discountItem", "");
      onInputChange("isFreeReward", false);
      onInputChange("discountType", "Store Discount");
    }
    onInputChange("integration", value);
    // need to test this
  };
  return (
    <Box py="60px">
      <FormControl display="flex" alignItems="center" gap="20px">
        <FormLabel m="0" variant={"chakra_label_default"}>
          Integration
        </FormLabel>
        <Select
          width="206px"
          variant="chakra_select_default"
          size="chakra_md_medium"
          placeholder="None"
          disabled={isEditing}
          textTransform={"capitalize"}
          value={inputValues?.integration}
          onChange={handleIntegrationChange}
        >
          {clientIntegrations?.map((integrationOption, index) => (
            <option key={index} value={integrationOption}>
              {integrationOption?.charAt(0)?.toUpperCase() +
                integrationOption?.slice(1)}
            </option>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default OfferIntegration;
