// Import the functions you need from the SDKs you need
import Environment from "environment";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Import getAuth function
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = Environment.USE_TEST_MODE ? {
    apiKey: Environment.GOOGLE_API_KEY,
    authDomain: "storyitapp-dev.firebaseapp.com",
    databaseURL: "https://storyitapp-dev-default-rtdb.firebaseio.com",
    projectId: "storyitapp-dev",
    storageBucket: "storyitapp-dev.appspot.com",
    messagingSenderId: "530958403648",
    appId: "1:530958403648:web:3dc3932a70ae27571eb7a5",
    measurementId: "G-TVR7Q7K73J"
} : {
    apiKey: Environment.GOOGLE_API_KEY,
    authDomain: "storyitapp.firebaseapp.com",
    databaseURL: "https://storyitapp-default-rtdb.firebaseio.com",
    projectId: "storyitapp",
    storageBucket: "storyitapp.appspot.com",
    messagingSenderId: "481331905031",
    appId: "1:481331905031:web:a8ebf932dbe1fec6a420a2",
    measurementId: "G-6ELFRVT4BQ"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const storage = getStorage(app);
const firestore = getFirestore(app);

export { auth, firestore, storage, app };
