/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import {
  Text,
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  useRadioGroup,
  HStack,
} from "@chakra-ui/react";
import RadioCard from "../../offers/components/Radiocard";
import {
  checkForDisable,
  checkForEmojiInString,
  contentTypeText,
  decimalFraction,
  itemDiscountSummary,
} from "utils";

interface ItemDiscountProps {
  inputValues: {
    discountItem: string;
    discountType: string;
    isCash: boolean;
    discountItemID: string;
    discountCollectionID?: string;
    prerequisiteQuantity?: number;
    percentOrCashOff?: string;
    compensation?: any;
    prerequisiteName?: string;
    isPrerequisite?: boolean;
    minPurchaseAmount: number;
    minimumFollowerCount: number;
    expirationLimit?: number;
    contentType: string;
    specificProduct: string;
    hashtag: string;
    isFreeReward: boolean;
    integration: string;
    ecommerceDiscountCode?: string;
    isEvergreenCode?: boolean;
    isPrivate: boolean;
    isInfluencerOffer: boolean;
    isCustomerOffer: boolean;
  };
  onInputChange: (
    fieldName: keyof ItemDiscountProps["inputValues"],
    value: any
  ) => void;
  data: any;
}

const ItemDiscount: React.FC<ItemDiscountProps> = ({
  inputValues,
  onInputChange,
  data: additionalDetails,
}) => {
  const optionsType = ["Item Discount", "Store Discount"];
  const { accountData, clientIntegrations = [], isEditing, offerDetail } = additionalDetails;
  const optionsDiscount = ["Yes", "No"];
  const [discountItemType, setdiscountItemType] = useState<string>(
    inputValues.discountType
  );
  const [cashDiscount, setCashDiscount] = useState<boolean>(
    inputValues.percentOrCashOff === "Percent" ? false : true
  );

  useEffect(() => {
    if (!isEditing) {
      onInputChange("minPurchaseAmount", 0);
      if (inputValues.contentType === 'ugcVideo' || inputValues.contentType === 'ugcPicture') {
        onInputChange("minimumFollowerCount", 0);
      } else {
        if (inputValues.isCustomerOffer) {
          onInputChange('minimumFollowerCount', 100);
        }
      }
      onInputChange("expirationLimit", 30);
    }
  }, []); // Run once on component mount

  useEffect(() => {
    onInputChange("percentOrCashOff", cashDiscount ? "Cash" : "Percent");
    onInputChange("compensation", inputValues?.compensation);
  }, [cashDiscount]); // Run once on component mount

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: "No",
    onChange: console.log,
  });

  const handleChangeType = (value: string) => {
    if (value === "Store Discount") {
      onInputChange("discountItem", "");
      onInputChange("discountItemID", "");
      onInputChange("discountCollectionID", "");
    }
    setdiscountItemType(value);
    onInputChange("discountType", value);
  };

  const handleChangeDiscount = (value: string) => {
    setCashDiscount(value === "Yes");
    onInputChange("compensation", 0);
  };

  const group = getRootProps();

  const { instagramHandle, offers = [], accountName } = accountData;


  return (
    <Box py="60px">
      <Flex flexFlow={"column"} gap={"60px"}>
        <FormControl display="flex" alignItems="center" gap="20px">
          <FormLabel m="0" variant={"chakra_label_default"}>
            Discount Type
          </FormLabel>
          {inputValues?.integration === "bigCommerce" ? (
            <>
              <Text fontWeight={"bold"}> Store Discount </Text>
            </>
          ) : (
            <>
              <HStack {...group} gap={"24px"}>
                {optionsType.map((value) => {
                  return (
                    <RadioCard
                      key={value}
                      onChange={() => handleChangeType(value)}
                      isChecked={discountItemType === value}
                    >
                      {value}
                    </RadioCard>
                  );
                })}
              </HStack>
            </>
          )}
        </FormControl>
        <FormControl display="flex" alignItems="center" gap="20px">
          <FormLabel m="0" variant={"chakra_label_default"}>
            Cash Discount
          </FormLabel>
          <HStack {...group} gap={"24px"}>
            {optionsDiscount.map((value) => {
              const radio = getRadioProps({ value });
              return (
                <RadioCard
                  key={value}
                  {...radio}
                  onChange={() => handleChangeDiscount(value)}
                  isChecked={cashDiscount === (value === "Yes")}
                  isDisabled={checkForDisable(isEditing, inputValues, offerDetail)}
                >
                  {value}
                </RadioCard>
              );
            })}
          </HStack>
        </FormControl>
        {discountItemType && discountItemType === "Item Discount" && (
          <>
            <FormControl display="flex" alignItems="center" gap="20px">
              <FormLabel m="0" variant={"chakra_label_default"}>
                Name of discounted item/Collection
              </FormLabel>
              <Box w={"100%"}>
                <Input
                  width="100%"
                  variant="chakra_input_default"
                  size="chakra_md_medium"
                  fontSize={"chakra_sm"}
                  fontWeight={"500"}
                  type="text"
                  maxLength={30}
                  value={inputValues?.discountItem}
                  onChange={(e) =>
                    onInputChange("discountItem", e?.target?.value)
                  }
                />
              </Box>
            </FormControl>

            {clientIntegrations?.length > 0 && (
              <>
                <FormControl display="flex" alignItems="center" gap="20px">
                  <FormLabel m="0" variant={"chakra_label_default"}>
                    Product ID
                  </FormLabel>
                  <Box w={"100%"}>
                    <Input
                      width="100%"
                      variant="chakra_input_default"
                      size="chakra_md_medium"
                      fontSize={"chakra_sm"}
                      fontWeight={"500"}
                      type="text"
                      value={inputValues.discountItemID}
                      onChange={(e) => {
                        let val = e?.target?.value?.trim();
                        if (checkForEmojiInString(val)) {
                          onInputChange("discountItemID", val?.trim());
                        }
                      }}
                    />
                  </Box>
                </FormControl>
                <FormControl display="flex" alignItems="center" gap="20px">
                  <FormLabel m="0" variant={"chakra_label_default"}>
                    Collection ID
                  </FormLabel>
                  <Box w={"100%"}>
                    <Input
                      width="100%"
                      variant="chakra_input_default"
                      size="chakra_md_medium"
                      fontSize={"chakra_sm"}
                      fontWeight={"500"}
                      type="text"
                      value={inputValues?.discountCollectionID}
                      onChange={(e) => {
                        let val = e?.target?.value?.trim();
                        if (checkForEmojiInString(val)) {
                          onInputChange("discountCollectionID", val?.trim());
                        }
                      }}
                    />
                  </Box>
                </FormControl>
              </>
            )}
          </>
        )}
        <FormControl display="flex" alignItems="center" gap="20px">
          <FormLabel m="0" variant={"chakra_label_default"}>
            Discount Amount (
            {inputValues.percentOrCashOff === "Percent" ? "%" : "$"} off)
          </FormLabel>
          <Box w={"100%"}>
            <Input
              width="100%"
              variant="chakra_input_default"
              size="chakra_md_medium"
              fontSize={"chakra_sm"}
              fontWeight={"500"}
              type="text"
              value={
                inputValues?.compensation === 0 ? "" : inputValues?.compensation
              }
              readOnly={checkForDisable(isEditing, inputValues, offerDetail)}

              onChange={(e) => {
                let value = e.target.value;
                onInputChange("compensation", decimalFraction(value));
              }}
            />
          </Box>
        </FormControl>
      </Flex>

      {
        <Box
          maxW={"514px"}
          mx="auto"
          mt="60px"
          py={"15px"}
          px={"14px"}
          bg={"#D4FEAE"}
          borderRadius={"8px"}
        >
          <Flex alignItems={"center"} textAlign={"center"}>
            <Text fontSize={"15px"} fontWeight={"600"}>
              {itemDiscountSummary({
                isPrerequisite: inputValues.isPrerequisite,
                contentText: contentTypeText(inputValues.contentType),
                specificProduct: inputValues.specificProduct,
                discountType: inputValues.discountType,
                accountName,
                instagramHandle,
                isFreeReward: inputValues.isFreeReward,
                compensation: inputValues.compensation,
                percentOrCashOff: inputValues.percentOrCashOff,
                discountItem: inputValues.discountItem,
                prerequisiteQuantity: inputValues?.prerequisiteQuantity,
                prerequisiteName: inputValues?.prerequisiteName,
                minimumFollowerCount: inputValues?.minimumFollowerCount,
                expirationLimit: inputValues?.expirationLimit,
                minPurchaseAmount: inputValues?.minPurchaseAmount,
                hashtag: inputValues?.hashtag,
                ecommerceDiscountCode: inputValues?.ecommerceDiscountCode,
                isEvergreenCode: inputValues?.isEvergreenCode,
              })}
            </Text>
          </Flex>
        </Box>
      }
    </Box>
  );
};

export default ItemDiscount;
