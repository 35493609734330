import React from "react";
import { Text, Flex, Select } from "@chakra-ui/react";

interface PrioritySelectionProps {
  setPriority: Function;
  priority: string; // Changed from String to string
  contentType: string; // Changed from String to string
}

function PrioritySelection({
  setPriority,
  priority,
  contentType,
}: PrioritySelectionProps) {
  const priorityLevels = ["low", "medium", "high"];

  return (
    <Flex
      justifyContent="start"
      alignItems="center"
      flexDirection="row"
      mt="5%"
    >
      <Flex alignItems="center" width="25%">
        <Text fontSize="18px" fontWeight="400">
          {contentType === "instagramStory"
            ? "Instagram Story"
            : contentType === "instagramPost"
            ? "Instagram Post"
            : contentType === "instagramReel"
            ? "Instagram Reel" 
            : contentType === "ugc" ? "UGC" : "Tiktok Video"}{" "}
        </Text>
      </Flex>

      <Select
        width="180px"
        value={priority}
        onChange={(event) => {
          const newPriority = event.target.value.toLowerCase();
          console.log("Setting priority to: ", newPriority);
          setPriority(newPriority);
        }}
      >
        {priorityLevels.map(
          (
            level: string // Changed from String to string
          ) => (
            <option key={level} value={level}>
              {level}
            </option>
          )
        )}
      </Select>
    </Flex>
  );
}

export default PrioritySelection;
