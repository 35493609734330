import { Flex, Text, Box, Switch, Button } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";

import React, { useState, useContext } from "react";
import { Icon } from "@chakra-ui/react";
import { BiTime } from "react-icons/bi";
import { MdOutlineLocalOffer, MdModeEditOutline } from "react-icons/md";
import {
  updateOfferActiveStatus,
  removeOfferFromClientDoc,
  removeOfferFromLocationDoc,
} from "services/firebaseService";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import { MdOutlineEmojiPeople } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import DeleteOfferConfirmation from "./confirmDeleteOffer";
import { useToast } from "@chakra-ui/react";
import { AccountsDataContext } from "../../../../../../contexts/accountsDataContext";
import { RiHashtag } from "react-icons/ri";

interface OfferCardProps {
  offer: any; // Preferably, you should replace any with the actual type of offer.
  [key: string]: any; // For the rest of the properties that this component might receive.
}

const OfferCard: React.FC<OfferCardProps> = ({ offer, ...rest }) => {
  const [isActive, setIsActive] = useState(offer.isActive);
  const [isDeletionConfirmationOpen, setIsDeletionConfirmationOpen] =
    useState(false);
  const [isDeletingOffer, setIsDeletingOffer] = useState(false);
  const [isEditingOfferOpen, setIsEditingOfferOpen] = useState(false);
  const [isEditingOffer, setIsEditingOffer] = useState(false);
  const accountData = useContext(AccountsDataContext)[offer.clientID];
  const openDeletionConfirmation = () => {
    setIsDeletionConfirmationOpen(true);
  };

  const closeDeletionConfirmation = () => {
    setIsDeletionConfirmationOpen(false);
  };
  // const openEditOfferPopup = () => {
  //   setIsEditingOfferOpen(true);
  // };

  // const closeEditOfferPopup = () => {
  //   setIsEditingOfferOpen(false);
  // };

  const history = useHistory();
  const toast = useToast();

  const handleDelete = async () => {
    setIsDeletingOffer(true);
    await updateOfferActiveStatus(offer.offerID, false, true); // making the offer inactive and setIsDeleted true
    // check location is there in offer or not.
    if (offer?.locations?.length) {
      await removeOfferFromLocationDoc(offer.offerID, offer?.locations || []); // remove offer from location while deleting
    }
    await removeOfferFromClientDoc(offer.clientID, offer.offerID); // removing pointer of offer from client file
    setIsDeletingOffer(false);
    closeDeletionConfirmation();
    toast({
      title: "Offer deleted",
      status: "success",
      duration: 1250,
      isClosable: false,
    });
    window.location.reload(); // HOT FIX (bad practice): force refresh the entire application to update the offer list
  };

  // const handleEdit = async () => {
  //   setIsEditingOffer(true);
  //   // await updateOfferActiveStatus(offer.offerID, false); // making the offer inactive
  //   // await removeOfferFromClientDoc(offer.clientID, offer.offerID); // removing pointer of offer from client file
  //   setIsEditingOffer(false);
  //   closeEditOfferPopup();
  //   toast({
  //     title: "Offer deleted",
  //     status: "success",
  //     duration: 1250,
  //     isClosable: false,
  //   });
  //   window.location.reload(); // HOT FIX (bad practice): force refresh the entire application to update the offer list
  // };

  const toggleSwitch = async (offerID: string) => {
    try {
      await updateOfferActiveStatus(offer.offerID, !isActive, false);
      setIsActive(!isActive);
    } catch (error) {
      console.log("Unabled to update status of offer at the moment");
    }
  };

  function formatDate(utcTimestamp: number) {
    const date = new Date(utcTimestamp * 1000);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }

  let offerTypeTitle = "";
  // handled condition for cashback offer
  if (offer.isCashBack) {
    offerTypeTitle = "Cashback";
  } else {
    offerTypeTitle = offer.isCash
      ? "Cash"
      : offer.cashOrPercentOff
        ? "Cash Credit"
        : "Discount Credit";
  }
  const offerDescription = `${offer.contentType === "ugcPicture"
    ? "UGC Offer: "
    : offer.contentType === "ugcVideo"
      ? "UGC Video: "
      : offer.contentType === "instagramPost"
        ? "Instagram Post: "
        : offer.contentType === "instagramReel"
          ? "Instagram Reel: "
          : offer.contentType === "instagramStory"
            ? "Instagram Story: "
            : offer.contentType === "tiktokVideo"
              ? "Tiktok Video: "
              : "Instagram Story: "
    }
  ${offerTypeTitle} 
     ${offer.isPrivate ? "" : ""} `;
  const compensationDescription = offer.isCashBack
    ? offer.compensation + "% cashback on total order"
    : `
  ${offer.compensation === 0 ? "Up to " : ""}  ${offer.isCash || offer.percentOrCashOff === "Cash" ? "$" : ""
    }${offer.compensation === 0 ? offer.maxCompensation : offer.compensation
    }${offer.percentOrCashOff === "Cash" ? "" : "%"} 
     ${offer.isCash ? "Cash" : "Discount"}${offer.minPurchaseAmount && offer.minPurchaseAmount > 0
      ? ` on Purchases $${offer.minPurchaseAmount}+`
      : ""
    } `;

  // const compensationDescription = `${
  //   offer.compensation === 0 ? "Up to " : ""
  // }${
  //   offer.isCash || offer.percentOrCashOff === "Cash" ? "$" : ""
  // }${
  //   offer.compensation === 0 ? offer.maxCompensation : offer.compensation
  // }${
  //   offer.percentOrCashOff === "Cash" ? "" : "%"
  // } ${
  //   offer.isCashBack ? "cashback" : (offer.isCash ? "Cash" : "Discount")
  // }${
  //   offer.minPurchaseAmount && offer.minPurchaseAmount > 0
  //     ? ` on Purchases $${offer.minPurchaseAmount}+`
  //     : ""
  // }`;

  return (
    <Box
      borderRadius="10px"
      backgroundColor="#F5F5F5"
      p="3.5%"
      mt="1.5%"
      mr="1.5%"
      ml="1.5%"
      mb="2%"
      {...rest}
      width="100%"
    >
      <Flex justifyContent="space-between">
        {isDeletionConfirmationOpen && (
          <DeleteOfferConfirmation
            offerName={offer.offerName}
            onClose={closeDeletionConfirmation}
            handleDelete={handleDelete}
            isOpen={isDeletionConfirmationOpen}
            isDeletingOffer={isDeletingOffer}
          />
        )}
        {/* {isEditingOfferOpen && (
          <EditOffer
            onClose={closeEditOfferPopup}
            handleEdit={handleEdit}
            isOpen={isEditingOfferOpen}
            isEditingOffer={isEditingOffer}
          />
        )} */}

        <Flex justifyContent="center" alignItems="start">
          <Box
            marginLeft="auto"
            color="white"
            display="inline-block"
            width="30px"
            height="30px"
            borderRadius="50px"
            backgroundImage={accountData?.instagramProfilePicture}
            backgroundSize="cover"
            mr="10px"
          />
          <Text fontSize="20px" fontWeight="600">
            {offer.offerName}
          </Text>
        </Flex>
        <Flex align="center">
          <Switch
            color="#2A85FF"
            size="10px"
            defaultChecked={isActive}
            onChange={() => {
              toggleSwitch(offer.offerID);
            }}
          />
          <Text
            ml={2}
            mr={5}
            fontSize="15px"
            fontWeight="500"
            color={isActive ? "#2A85FF" : "grey"}
          >
            Active
          </Text>
          {/**
           * @info
           * Changes made by @nirmal as we need to allow user to edit all type offers.
           * Changes on date 14/06/2024
           */}
          {/* <IconBox
            mr={5}
            _hover={{
              cursor: "pointer",
              transform: "scale(1.1)",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
            }}
            onClick={() => {
              // added logical and condition to handle existing code and extra condition for the customer offer only
              // if (offer?.isCustomerOffer && !offer?.isCash) {
              history.push(
                `/admin/create-offer/?accountId=${offer.clientID}&isEditing=true&offerID=${offer.offerID}`
              );
              // }
            }}
            w="40px"
            h="40px"
            bg={"#598FE727"}
            icon={<MdModeEditOutline color="black" size="20px" />}
          /> */}
          {/* <Button>
            edit new offer<IconBox
              mr={5}
              _hover={{
                cursor:
                  offer.isCash ||
                    offer.offerName.includes("utopilot") ||
                    offer.isInfluencerOffer ||
                    offer.prerequisiteQuantity > 0
                    ? "not-allowed"
                    : "pointer",
                transform:
                  offer.isCash ||
                    offer.offerName.includes("utopilot") ||
                    offer.isInfluencerOffer ||
                    offer.prerequisiteQuantity > 0
                    ? "none"
                    : "scale(1.1)",
                boxShadow:
                  offer.isCash ||
                    offer.offerName.includes("utopilot") ||
                    offer.isInfluencerOffer ||
                    offer.prerequisiteQuantity > 0
                    ? "none"
                    : "0px 4px 8px rgba(0, 0, 0, 0.05)",
              }}
              onClick={() => {
                if (
                  !offer.isCash &&
                  !offer.offerName.includes("utopilot") &&
                  !offer.isInfluencerOffer
                ) {
                  history.push(
                    `/admin/create-new-offer-flow/?accountId=${offer.clientID}&isEditing=true&offerID=${offer.offerID}`
                  );
                }
              }}
              w="40px"
              h="40px"
              bg={
                offer.isInfluencerOffer ||
                  offer.isCash ||
                  offer.offerName.includes("utopilot")
                  ? "#27272727"
                  : "#598FE727"
              }
              icon={<MdModeEditOutline color="black" size="20px" />}
            />
          </Button> */}
          {/* <Button>
            edit new offer<IconBox
              mr={5}
              _hover={{
                cursor:
                  offer.offerName.includes("utopilot")
                    ? "not-allowed"
                    : "pointer",
                transform:
                  offer.offerName.includes("utopilot")
                    ? "none"
                    : "scale(1.1)",
                boxShadow:
                  offer.offerName.includes("utopilot")
                    ? "none"
                    : "0px 4px 8px rgba(0, 0, 0, 0.05)",
              }}
              onClick={() => {
                if (
                  !offer.offerName.includes("utopilot")
                ) {
                  history.push(
                    `/admin/create-new-offer-flow/?accountId=${offer.clientID}&isEditing=true&offerID=${offer.offerID}`
                  );
                }
              }}
              w="40px"
              h="40px"
              bg={
                offer.offerName.includes("utopilot")
                  ? "#27272727"
                  : "#598FE727"
              }
              icon={<MdModeEditOutline color="black" size="20px" />}
            />
          </Button> */}
          <IconBox
            mr={5}
            _hover={{
              cursor:
                offer.offerName.includes("utopilot") && offer?.isAutopilotOffer
                  ? "not-allowed"
                  : "pointer",
              transform:
                offer.offerName.includes("utopilot") && offer?.isAutopilotOffer
                  ? "none"
                  : "scale(1.1)",
              boxShadow:
                offer.offerName.includes("utopilot") && offer?.isAutopilotOffer
                  ? "none"
                  : "0px 4px 8px rgba(0, 0, 0, 0.05)",
            }}
            onClick={() => {
              // added logical and condition to handle existing code and extra condition for the customer offer only.
              if (
                !offer.offerName.includes("utopilot") && !offer?.isAutopilotOffer
              ) {
                history.push(
                  `/admin/create-offer/?accountId=${offer.clientID}&isEditing=true&offerID=${offer.offerID}`
                );
              }
            }}
            w="40px"
            h="40px"
            bg={"#598FE727"}
            icon={<MdModeEditOutline color="black" size="20px" />}
          />
          <IconBox
            _hover={{
              cursor: "pointer",
              transform: "scale(1.1)",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
            }}
            onClick={openDeletionConfirmation}
            w="40px"
            h="40px"
            bg="#598FE727"
            icon={<MdDeleteOutline color="red" size="20px" />}
          />
        </Flex>
      </Flex>
      <Text fontWeight="500" fontSize="14px" color="grey" mb="2%">
        {offerDescription}
      </Text>

      <Flex>
        <Icon as={BiTime} boxSize={6} color="#6F767E" mr={1.5} />

        <Text color="#6F767E" fontWeight="500" fontSize="16px" mr={5}>
          {formatDate(offer.date)}
        </Text>
        <Icon as={MdOutlineLocalOffer} boxSize={5} color="#6F767E" mr={1} />

        <Text color="#6F767E" fontWeight="500" fontSize="16px" mr={5}>
          {offer.numberOffersLeft + " left"}
        </Text>


        {
          offer?.isCustomerOffer && !offer?.isAutopilotOffer && offer?.contentType && ["ugcPicture", "ugcVideo"].indexOf(offer?.contentType) === -1 &&
          <>
            <Icon as={MdOutlineEmojiPeople} boxSize={5} color="#6F767E" mr={1} />
            <Text color="#6F767E" fontWeight="500" fontSize="16px" mr={5}>
              Min. follower count: {offer.minimumFollowerCount ?? 0}
            </Text>
          </>
        }

        {offer.hashtag !== undefined && offer.hashtag !== "" && (
          <Flex>
            <Icon as={RiHashtag} boxSize={5} color="#6F767E" mr={1} />

            <Text color="#6F767E" fontWeight="500" fontSize="16px">
              {offer.hashtag}
            </Text>
          </Flex>
        )}
      </Flex>
      <Flex alignItems="center">
        <Box borderTop="1.5px solid #EAECF0" mt="15px" mb="15px" width="100%" />
      </Flex>

      <Flex justifyContent="space-between" alignItems="center">
        <Flex justifyContent="start" alignItems="start">
          <Text fontSize="18px" fontWeight="500" color="#2A85FF">
            {compensationDescription}
          </Text>
          {offer.isInfluencerOffer && (
            <Box
              ml="20px"
              backgroundColor="#D2DFFF"
              borderRadius="10px"
              width="120px"
              p="1"
            >
              <Flex width="100%" alignItems="center" justifyContent="center">
                <Text fontSize="13px" color="#2A85FF">
                  Influencer Offer
                </Text>
              </Flex>
            </Box>
          )}
          {offer.isPrivate && (
            <Box
              ml="20px"
              backgroundColor="#D2DFFF"
              borderRadius="10px"
              width="120px"
              p="1"
            >
              <Flex width="100%" alignItems="center" justifyContent="center">
                <Text fontSize="13px" color="#2A85FF">
                  Private Offer
                </Text>
              </Flex>
            </Box>
          )}
          {offer.prerequisiteQuantity > 0 && (
            <Box
              ml="20px"
              backgroundColor="#D2DFFF"
              borderRadius="10px"
              width="120px"
              p="1"
            >
              <Flex width="100%" alignItems="center" justifyContent="center">
                <Text fontSize="13px" color="#2A85FF">
                  Prerequisite Offer
                </Text>
              </Flex>
            </Box>
          )}
        </Flex>
        <IconBox
          _hover={{
            cursor: "pointer",
            transform: "scale(1.1)",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
          }}
          onClick={() => {
            history.push(`/admin/view-offer/?offerId=${offer.offerID}`);
          }}
          w="40px"
          h="40px"
          bg="#598FE727"
          icon={<ChevronRightIcon w="20px" h="20px" color="#1877F2" />}
        />
      </Flex>
    </Box>
  );
};
export default OfferCard;
