import { useContext, useState } from "react";
import { SuperUserDataContext } from "contexts/superUserDataContext";
import { Box, Button, Flex, Grid, IconButton, Spinner, Text } from "@chakra-ui/react";
import AddAdminAccountModal from "../../views/superUser/addAdminAccountModal";
import Environment from "environment";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import CompanyStats from "views/superUser/companyStats";
import ReviewContests from "views/superUser/reviewContests/reviewContests";
import { createDashboardAdminAccount } from "services/flaskService";
import { useToast } from "@chakra-ui/react";
import { ActiveUidContext } from "contexts/activeUidContext";
import { FaHome } from "react-icons/fa";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase"
import ReviewContestsDataProvider from "contexts/reviewContestsDataContext";

export default function SuperUserLayout(props: { [x: string]: any }) {
  const superUserData = useContext(SuperUserDataContext);
  const [isAddAccountModalOpen, setIsAddAccountModalOpen] = useState(false);
  const [isAddingAccount, setIsAddingAccount] = useState(false);

  const history = useHistory();
  const toast = useToast();
  const { uid, setUid } = useContext(ActiveUidContext);
  const [user] = useAuthState(auth);

  async function handleAccountCreation(
    email: string,
    password: string,
    accountName: string
  ) {
    setIsAddingAccount(true);

    try {
      let data = await createDashboardAdminAccount(
        email,
        password,
        accountName
      );
      if (data !== null) {
        setIsAddAccountModalOpen(false);
        console.log(data);

        toast({
          title: "Account Created",
          status: "success",
          duration: 1250,
          isClosable: false,
        });
      }
    } catch (error) {
      setIsAddAccountModalOpen(false);
      console.error("Error creating dashboard account:", error);
      toast({
        title: "FAILED to create account right now",
        status: "error",
        duration: 1250,
        isClosable: false,
      });
    }
    setIsAddingAccount(false);
  }

  // Check if superUserData is available and render the list of admin emails
  return (
    <Switch>
      <Route exact path="/superuser/">
        <>
          <AddAdminAccountModal
            isOpen={isAddAccountModalOpen}
            handleAccountCreation={handleAccountCreation}
            isAddingAccount={isAddingAccount}
            onClose={() => {
              setIsAddAccountModalOpen(false);

            }}
          />
          <Box>
            <Box
              backgroundColor="white"
              borderRadius="10px"
              width="100%"
              height="80%"
              position="relative"
            >
              <Box
                position="absolute"
                mt="40px"
                left={5}
                width="100%"
                height="100%"
              >
                <Flex justifyContent="space-between" alignItems="center">
                  <IconButton aria-label="go-home" icon={<FaHome />} onClick={
                    () => {
                      history.push("/admin/overview/");
                      setUid(user?.uid)
                    }
                  } />
                  <Button
                    onClick={() => {
                      window.open(Environment.MANUAL_VERIFICATION_HOST_URL);
                    }}
                    backgroundColor="black"
                    mr="40px"
                    mt="-17.5px"
                    p="10px 15px"
                    borderRadius="10px"
                    transition="transform 0.2s, box-shadow 0.2s"
                    transform="translateY(0)"
                    boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                    _hover={{
                      transform: "scale(1.05)",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _focus={{
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _active={{
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Text
                      fontWeight="500"
                      fontSize="18px"
                      textAlign="center"
                      color="white"
                    >
                      Open Manual Verification
                    </Text>
                  </Button>
                  <Button
                    onClick={() => {
                      history.push("/superuser/company-stats/");
                    }}
                    backgroundColor="black"
                    mr="40px"
                    mt="-17.5px"
                    p="10px 15px"
                    borderRadius="10px"
                    transition="transform 0.2s, box-shadow 0.2s"
                    transform="translateY(0)"
                    boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                    _hover={{
                      transform: "scale(1.05)",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _focus={{
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _active={{
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Text
                      fontWeight="500"
                      fontSize="18px"
                      textAlign="center"
                      color="white"
                    >
                      View Company-Wide Stats
                    </Text>
                  </Button>
                  <Button
                    onClick={() => {
                      setIsAddAccountModalOpen(true);
                    }}
                    backgroundColor="black"
                    mr="40px"
                    mt="-17.5px"
                    p="10px 15px"
                    borderRadius="10px"
                    transition="transform 0.2s, box-shadow 0.2s"
                    transform="translateY(0)"
                    boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                    _hover={{
                      transform: "scale(1.05)",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _focus={{
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _active={{
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Text
                      fontWeight="500"
                      fontSize="18px"
                      textAlign="center"
                      color="white"
                    >
                      Create New Admin Account +
                    </Text>
                  </Button>
                  <Button
                    onClick={() => {
                      history.push("/superuser/review-contests");
                    }}
                    backgroundColor="black"
                    mr="40px"
                    mt="-17.5px"
                    p="10px 15px"
                    borderRadius="10px"
                    transition="transform 0.2s, box-shadow 0.2s"
                    transform="translateY(0)"
                    boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                    _hover={{
                      transform: "scale(1.05)",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _focus={{
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _active={{
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Text
                      fontWeight="500"
                      fontSize="18px"
                      textAlign="center"
                      color="white"
                    >
                      Review Contests
                    </Text>
                  </Button>
                </Flex>
                <Flex alignItems="center">
                  <Box borderTop="1.3px solid #E2E8F0" mt="15px" width="96%" />
                </Flex>
                <Flex alignItems="center">
                  <Text ml="10px" my="15px" fontWeight="bold" fontSize="25px">
                    Choose an admin account
                  </Text>
                </Flex>
                {superUserData?.length > 0 ? (
                  <Grid
                    templateColumns={{
                      sm: "repeat(1, 1fr)",
                      md: "repeat(2, 1fr)",
                      lg: "repeat(3, 1fr)",
                    }}
                    gap={6}
                  >
                    {superUserData.map((adminDoc: any, index: number) => (
                      <Box key={index} p="5" shadow="md" borderWidth="1px" onClick={() => {
                        history.push("/admin/overview/");
                        setUid(adminDoc.id);
                      }}>
                        <Text fontSize="xl">{adminDoc?.accountName}</Text>
                      </Box>
                    ))}
                  </Grid>
                ) : (
                  <Flex justify="center" align="center" height="100vh">
                    <Spinner />
                  </Flex>
                )}
              </Box>
            </Box>
          </Box>
        </>
      </Route>
      <Route exact path="/superuser/company-stats">
        <CompanyStats />
      </Route>
      <Route exact path="/superuser/review-contests">
        <ReviewContestsDataProvider>
          <ReviewContests />
        </ReviewContestsDataProvider>
      </Route>
    </Switch >
  );
}
