import React, { createContext, useState, useEffect } from "react";
import { doc, collection, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase";

export const ReviewContestsDataContext = createContext();

export const ReviewContestsDataProvider = ({ children }) => {
    const reviewContestsCollectionName = "reviewContests"
    const [reviewContestsData, setReviewContestsData] = useState({});

    const clientsCollectionName = "clients"
    const [clientsData, setClientsData] = useState({});
    useEffect(() => {
        const fetchedReviewContestsData = {};
        const unsubscribes = [];
        
        const reviewContestsCollectionRef = collection(firestore, reviewContestsCollectionName);
        const unsubscribeReviewContests = onSnapshot(reviewContestsCollectionRef, (reviewContestsDocsSnapshot) => {
            reviewContestsDocsSnapshot.forEach((reviewContestsDoc) => {
                const reviewContestsRef = doc(firestore, reviewContestsCollectionName, reviewContestsDoc.id);
                
                const reviewContestsUnsubscribe = onSnapshot(reviewContestsRef, (docSnap) => {
                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        fetchedReviewContestsData[reviewContestsDoc.id] = { id: docSnap.id, ...data };
                        setReviewContestsData((prevData) => ({
                            ...prevData,
                            [reviewContestsDoc.id]: fetchedReviewContestsData[reviewContestsDoc.id],
                        }));
                    }
                });
                unsubscribes.push(reviewContestsUnsubscribe);
            }
            )
        }
        
        )
        unsubscribes.push(unsubscribeReviewContests)
        console.log("fetchedReviewContestsData", fetchedReviewContestsData);

        const fetchedClientsData = {};
        
        const clientsCollectionRef = collection(firestore, clientsCollectionName);
        const unsubscribeClients = onSnapshot(clientsCollectionRef, (clientsDocsSnapshot) => {
            clientsDocsSnapshot.forEach((clientsDoc) => {
                const clientsRef = doc(firestore, clientsCollectionName, clientsDoc.id);
                
                const clientsUnsubscribe = onSnapshot(clientsRef, (docSnap) => {
                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        fetchedClientsData[clientsDoc.id] = { id: docSnap.id, ...data };
                        setClientsData((prevData) => ({
                            ...prevData,
                            [clientsDoc.id]: fetchedClientsData[clientsDoc.id],
                        }));
                    }
                });
                unsubscribes.push(clientsUnsubscribe);
            }
            )
        }
        
        )
        unsubscribes.push(unsubscribeClients)
        console.log("fetchedClientsData", fetchedClientsData);

        // Cleanup the listeners when the component is unmounted
        return () => {
            unsubscribes.forEach((unsubscribe) => unsubscribe());
        };
    }, []);

    return (
        <ReviewContestsDataContext.Provider value={{reviewContestsData, clientsData}}>
            {children}
        </ReviewContestsDataContext.Provider>
    );
};

export default ReviewContestsDataProvider;
