import React, { useState, useEffect } from "react";
import { Box, Input, Flex, Text, Image, Button } from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { UploadIcon } from 'components/icons/Icons';
import { getData, removeData, setData } from "utils/localStorage.service";

interface ImageUploaderProps {
  setExampleUGC: Function;
  setIsImageValid: Function;
  isButton: boolean;
  exampleUgcUrl: any;
  fieldName?: string;
  offerName: string;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  setExampleUGC,
  setIsImageValid,
  isButton,
  exampleUgcUrl,
  fieldName,
  offerName
}) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [inputId, setInputId] = useState<string>("");
  const [imageEdited, setImageEdited] = useState(false);
  const [validationError, setImageValidationError] = useState<any>("");
  useEffect(() => {
    // Generate a unique ID for the input element
    setInputId(`image-input-${Math.random().toString(36).substr(2, 9)}`);
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      if (fieldName === "offerImage") {
        const localStorageData = getData(`${offerName}-offer-image`);
        if (localStorageData && exampleUgcUrl) {
          const uintArray: any = new Uint8Array(exampleUgcUrl);
          setExampleUGC("");
          const { title, data, imageDetail, fileType } = localStorageData;
          if (title === offerName && imageDetail) {
            const blob = new Blob([uintArray], { type: fileType });
            const b64encoded = await new Promise((resolve) => {
              const reader: any = new FileReader();
              reader.onloadend = () => resolve(reader.result.split(',')[1]);
              reader.readAsDataURL(blob);
            });
            setSelectedImage(`${imageDetail},${b64encoded}`);
            setImageEdited(true);
            setExampleUGC(uintArray);
          }
        } else {
          setSelectedImage(exampleUgcUrl);
        }
      } else {
        const localStorageData = getData(`${offerName}-image`);
        setExampleUGC("");
        if (localStorageData && exampleUgcUrl) {
          const { title, data, imageDetail, fileType } = localStorageData;
          if (title === offerName && imageDetail) {
            const uintArray = new Uint8Array(exampleUgcUrl);
            setExampleUGC("");
            const blob = new Blob([uintArray], { type: fileType });
            const b64encoded = await new Promise((resolve) => {
              const reader: any = new FileReader();
              reader.onloadend = () => resolve(reader.result.split(',')[1]);
              reader.readAsDataURL(blob);
            });
            setSelectedImage(`${imageDetail},${b64encoded}`);
            setImageEdited(true);
            setExampleUGC(uintArray);
          }
        } else {
          setSelectedImage(exampleUgcUrl);
        }
      }
    };

    fetchData();
  }, []);





  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    const maxSize = 15 * 1024 * 1024; // 15 MB in bytes

    // Check if the file size is less than the maximum size

    if (file && file.size <= maxSize) {
      const reader = new FileReader();

      reader.onload = () => {
        const dataURL = reader?.result as string;
        setSelectedImage(dataURL);
        const [imageDetail, base64String] = dataURL.split(",");

        if (base64String) {
          const binaryString = atob(base64String);
          const arrayBuffer = new ArrayBuffer(binaryString?.length);
          const uint8Array = new Uint8Array(arrayBuffer);

          for (let i = 0; i < binaryString?.length; i++) {
            uint8Array.set([binaryString?.charCodeAt(i)], i);
          }
          // setSelectedImage(`data:${file.type};base64,${b64encoded}`);
          setExampleUGC(uint8Array);
          const localStorageClone = {
            title: offerName,
            // data: uint8Array,
            imageDetail,
            fileType: file?.type ?? ""
          }
          // set image in localstorage
          if (fieldName === "offerImage") {
            removeData(`${offerName}-offer-image`);
            setData(`${offerName}-offer-image`, localStorageClone);
          } else {
            removeData(`${offerName}-offer-image`);
            setData(`${offerName}-image`, localStorageClone);
          }
          setImageEdited(true);
        } else {
          console.error("Invalid base64 string");
        }
      };
      setIsImageValid(true);
      reader.readAsDataURL(file);
      setImageValidationError("")

    } else {
      setImageValidationError("Invalid file size max file size will be only 15mb.")
    }
  };

  if (isButton) {
    return (
      <Flex direction="column" align="center" justify="center" >
        {!selectedImage ? (
          <Button
            backgroundColor="black"
            width="100R"
            p="10px 15px"
            borderRadius="10px"
            transition="transform 0.2s, box-shadow 0.2s"
            transform="translateY(0)"
            _hover={{
              transform: "scale(1.05)",
            }}
            _active={{}}
            onClick={() => {
              document?.getElementById(inputId)?.click();
            }}
          >
            <Text
              fontWeight="500"
              fontSize="15px"
              textAlign="center"
              color="white"
            >
              Upload Example
            </Text>
          </Button>
        ) : (
          // <Text>Image successfully uploaded!</Text>
          <Flex direction="column" align="center" justify="flex-start">
            <Box
              maxWidth="100%"
              maxHeight="300px"
              minHeight="200px"
              borderRadius="10px"
              overflow="hidden"
              boxShadow="lg"
              mb={4}
            >
              <Image src={selectedImage} alt="Uploaded" />
            </Box>
            <Button
              ml="2"
              onClick={() => {
                document.getElementById(inputId)?.click();
              }}
              size="30px"
              backgroundColor="transparent"
            >
              <EditIcon color="black" />
            </Button>
          </Flex>
        )}
        <Input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          id={inputId}
          display="none"
        />
      </Flex>
    );
  }

  return (
    <Flex direction="column" align="start" justify="flex-start">
      {!selectedImage && exampleUgcUrl === "" ? (
        <Box
          backgroundColor="#F4F4F4"
          width="514px"
          borderStyle="dashed"
          borderRadius="lg"
          textAlign="center"
          cursor="pointer"
          onClick={() => {
            const input = document?.getElementById(inputId);
            input?.click();
          }}
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            height="100%"
            justifyContent="center"
            py="30px"
            px="24px"
          >
            <UploadIcon h='40px' w='40px' mb='12px' />
            <Text fontSize="chakra_md" fontWeight="600" mb="0px" color="black">
              Click to Upload
            </Text>
            <Text fontSize="chakra_md" fontWeight="400" color="#667085">
              Accepted formats include SVG, PNG, JPG or GIF
            </Text>
            {
              validationError && (
                <Text fontSize="chakra_md" fontWeight="400" color="red">
                  {validationError}
                </Text>
              )
            }
          </Flex>
        </Box>
      ) : (
        <Flex bg="#F4F4F4" direction="column" align="center" justify="center" ml="0" w="514px" h="191px" position="relative" overflow="hidden" borderRadius="10px">
          <Flex
            align="center" justify="center"
            minWidth="100%"
            maxWidth="100%"
            height="100%"
            borderRadius="10px"
            //   maxHeight="300px"
            overflow="hidden"
            boxShadow="lg"
          >
            {exampleUgcUrl !== "" && !imageEdited && (
              <Image
                src={exampleUgcUrl}
                alt="Unable to load image"
                maxHeight={'100%'}
                maxWidth={'100%'}
                objectFit='contain'
                onError={(e) => {
                  (e.target as HTMLImageElement).src =
                    "https://firebasestorage.googleapis.com/v0/b/storyitapp-dev.appspot.com/o/UGC%20placeholder.jpg?alt=media&token=1908d380-979a-4671-a66d-699ea13b3622";
                }}
              />
            )}

            {(exampleUgcUrl === "" || imageEdited) && (
              <Image src={selectedImage} alt="Uploaded" maxHeight={'100%'}
                maxWidth={'100%'}
                objectFit='contain' />
            )}
          </Flex>
          <Button
            position="absolute"
            top="0"
            left="0"
            variant="chakra_btn_theme"
            borderRadius="10px 10px 10px 10px"
            onClick={() => {
              document.getElementById(inputId)?.click();

              // document.getElementById(inputId)?.click();
            }}
            width="40px"
            height="40px"
          >
            <EditIcon color="white" />
          </Button>
          {
            fieldName && fieldName === "offerImage" && (
              <Button
                position="absolute"
                variant="chakra_btn_theme"
                borderRadius="10px 10px 10px 10px"
                onClick={() => {
                  setExampleUGC("");
                  setImageEdited(false);
                  setSelectedImage(null);
                  const input = document?.getElementById(inputId) as HTMLInputElement;
                  if (input) {
                    input.value = ""; // Reset input value
                  }
                }}
                top="0"
                left="12"
                width="40px"
                height="40px"
              >
                <DeleteIcon color="white" />
              </Button>
            )
          }

        </Flex>
      )
      }

      <Input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        id={inputId}
        display="none"
      />
    </Flex >
  );
};

export default ImageUploader;
