import { useState, useEffect } from 'react';
import { Box, Flex, Text, Button, Input, Table, Tr, Thead, Tbody, Td, Th, Link, IconButton, Textarea, Spinner } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/DatePicker.css';
import ConfirmEndContest from './confirmEndContest'
import Select from 'react-select';
import { IoTrash } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { Timestamp } from 'firebase/firestore';
import { isEqual } from "lodash";

import { SingleValue } from 'react-select';

interface ClientOption {
    value: Client;
    label: string;
}
type ReviewContest = {
    id?: string;
    name?: string
    compensation?: number;
    contestants?: Record<string, any>;
    endDate?: Timestamp;
    isActive?: boolean;
};

interface Client {
    id: string;
    accountName: string;
    googleReviewLink?: string;
    reviewContestID?: string

}

type ReviewContestInformationProps = {
    clients: Client[];
    availableClients: Client[];
    contest: ReviewContest,
    handleSaveContest: (contest: ReviewContest, clients: Client[]) => Promise<void>;
    handleEndContest: (contestID: string) => Promise<void>;
    handleClientSelected: (clientID: string) => void;
    handleClientDeselected: (clientID: string) => void;
    selectedClients: String[]
    isNewContest: boolean
}
export default function ReviewContestInformation({
    clients = [],availableClients =[], contest, handleSaveContest, handleClientSelected, handleClientDeselected,handleEndContest,selectedClients, isNewContest
}: ReviewContestInformationProps) {

    const [newClients, setNewClients] = useState<Client[]>([])
    const [maxLength, setMaxLength] = useState<number>(0)
    const [selectedClient, setSelectedClient] = useState<SingleValue<ClientOption> | null>(null);

    const [editingClient, setEditingClient] = useState<string | null>(null);
    const [editLink, setEditLink] = useState<string>('');

    const [newContest, setNewContest] = useState<ReviewContest>(contest);
    const [clientOptions, setClientOptions] = useState<{ value: Client; label: string; }[]>([]);
    
    const [isEndContestConfirmationOpen, setIsEndContestConfirmationOpen] = useState(false);
    const [isEnding, setIsEnding] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {

        const options = availableClients
            .filter(client => !selectedClients.includes(client.id))
            .map(client => ({
                value: client,
                label: client.accountName,
            }));
        setClientOptions(options);

    }, [availableClients, selectedClients]);

    useEffect(() => {
        if(clients.length > maxLength){
            setMaxLength(clients.length);
            setNewClients(clients);
        }
    }, [clients]);

    useEffect(() => {
        if(clients.length > maxLength){
            setMaxLength(clients.length);
            setNewClients(clients);
        }
    }, [isEnding]);
    

    const handleSelectClient= async (client: Client) => {
        if(client != null){
            setSelectedClient(null);
            client.reviewContestID = newContest.id;
            setNewClients(prevClients =>  [...prevClients, client]);
            handleClientSelected(client.id)
        }
    }

    const handleEditClick = (clientId: string, currentLink: string) => {
        setEditingClient(clientId);
        setEditLink(currentLink);
    };

    const handleSaveClick = (clientId: string) => {
        setNewClients(prevClients => {
            return prevClients.map(client => 
                client.id === clientId
                    ? { ...client, googleReviewLink: editLink }
                    : client
            );
        });
        
        setEditingClient(null);

    };

    const handleDeleteClient = (client: Client) => {
        setNewClients(prev => prev.filter(prevClient => prevClient.id !== client.id));
        handleClientDeselected(client.id)
    };

    const firestoreTimestampToDate = (timestamp: Timestamp | null): Date | null => {
        return timestamp ? timestamp.toDate() : null;
    };
    const dateToFirestoreTimestamp = (date: Date | null): Timestamp | null => {
        return date ? Timestamp.fromDate(date) : null;
    };

    const isSaveButtonEnabled = (): boolean => {
        const omitContestants = (contest: ReviewContest): Partial<ReviewContest> => {
            const { contestants, ...contestWithoutContestants } = contest;
            return contestWithoutContestants;
        };

        return isEqual(omitContestants(newContest), omitContestants(contest)) && isEqual(clients, newClients)
    }

    const isContestOver = (): boolean => {
        const today = new Date();
        const contestEndDate = firestoreTimestampToDate(contest.endDate);
        
        const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        const contestEndDateStart = new Date(contestEndDate.getFullYear(), contestEndDate.getMonth(), contestEndDate.getDate());
        
        const num_contestants = Object.keys(contest.contestants).filter(
            contestantId => contest.contestants[contestantId].approved === true
          ).length;

        return  todayStart > contestEndDateStart && num_contestants > 0;
    }
 
    const handleSaveContestPressed = async (): Promise<void> => {
        setIsLoading(true)
        await handleSaveContest(newContest, newClients)
        setIsLoading(false)
       if(isNewContest){
            setNewContest(contest);
            setNewClients(clients);
       }
    }

   const handleEndContestPressed = (): void =>{
    setIsEndContestConfirmationOpen(true);

   }

   const handleEndContestConfirmed = async (): Promise<void> => {
        setIsEnding(true);
        await handleEndContest(contest.id);
        setIsEnding(false);
        closeEndContestConfirmation();

   }

   const closeEndContestConfirmation = () => {
        setIsEndContestConfirmationOpen(false);
  };
    return (
        <Flex direction="column" gap="5">
            {isEndContestConfirmationOpen && (
            <ConfirmEndContest  
            isOpen={isEndContestConfirmationOpen}
            onClose={closeEndContestConfirmation}
            handleEndContest={handleEndContestConfirmed}
            contestName ={contest.name}
            isEnding={isEnding}
            />

            )
        
            }
        <Flex>
            <Flex direction="column" gap="5" w="25%">
                <Text>Contest Name:</Text>
                <Input
                    placeholder="Contest Name"
                    value={newContest.name}
                    onChange={(e) => setNewContest({ ...newContest, name: e.target.value })}
                />
                <Text>Compensation:</Text>
                <Input
                    placeholder="Compensation"
                    value={newContest.compensation}
                    type="number"
                    onChange={(e) => setNewContest({ ...newContest, compensation: parseInt(e.target.value) })}
                />
                <Flex alignItems="center" gap="3">
                    <Text>End Date:</Text>
                    <DatePicker
                        selected={firestoreTimestampToDate(newContest.endDate)} // Convert Firestore Timestamp to Date
                        onChange={(date: Date) => setNewContest({ ...newContest, endDate: dateToFirestoreTimestamp(date)})}
                        dateFormat="MMMM d, yyyy"
                        className="custom-date-picker"
                    />
                </Flex>
                <Text>Contestants: {Object.keys(contest.contestants).filter(
      contestantId => contest.contestants[contestantId].approved === true
    ).length}</Text>

            </Flex>
            <Box p="5">
                <Text fontSize="xl" fontWeight="bold" mb="4">Contest Clients</Text>
                <Table variant="simple" size="md">
                    <Thead>
                        <Tr>
                            <Th>Name</Th>
                            <Th>Google Review Link</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {newClients.map(client => {
                                return (
                                    <Tr key={client.id}>
                                        <Td>{client.accountName}</Td>
                                        <Td>
                                            {editingClient === client.id ? (
                                                <Input
                                                    value={editLink}
                                                    onChange={(e) => setEditLink(e.target.value)}
                                                    onBlur={() => handleSaveClick(client.id)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleSaveClick(client.id);
                                                        }
                                                    }}
                                                    size="sm"
                                                />
                                            ) : (
                                                <>
                                                    {client.googleReviewLink ? (
                                                        <Link href={client.googleReviewLink} isExternal color="teal.500"  sx={{
                                                            display: 'inline-block',
                                                            maxWidth: '500px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            verticalAlign: 'middle'
                                                          }}>
                                                            {client.googleReviewLink}
                                                        </Link>
                                                    ) : (
                                                        'No Review Link'
                                                    )}
                                                    <IconButton
                                                        aria-label="Edit link"
                                                        icon={<MdEdit />}
                                                        size="sm"
                                                        ml="2"
                                                        onClick={() => handleEditClick(client.id, client.googleReviewLink)}
                                                    />
                                                </>
                                            )}
                                        </Td>
                                        <Td border="none">
                                            <IconButton
                                                aria-label="Delete client"
                                                icon={<IoTrash />}
                                                size="sm"
                                                onClick={() => handleDeleteClient(client)}
                                            />

                                        </Td>
                                    </Tr>
                                );
                        })}
                        <Tr>
                            <Td> 
                                <Select
                                options={clientOptions}
                                value={selectedClient}
                                onChange={(option) => handleSelectClient(option?.value)}
                                placeholder="Select client"
                                isClearable
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        width: 300, // set the desired width here
                                    }),
                                    control: (provided) => ({
                                        ...provided,
                                        minWidth: 300, // ensure the control also respects the width
                                    }),
                                }} />
                            </Td>
                            <Td> </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </Box>
        </Flex>
        {isLoading?
                (
                <Box>
                    <Flex justifyContent="center" alignItems="center">
                        <Spinner size="xl" />
                    </Flex>
                </Box>
                ):(
                <Flex direction="row" justify="space-between" align="center">
                    {isNewContest?(<></>):(
                      <Box>
                      </Box>
                      )
                      }
          
                      <Button style={{ backgroundColor: "#1a1a1a", color: "white", width: "50%",  }} 
                      onClick={() => handleSaveContestPressed()}
                      disabled={isSaveButtonEnabled()}>
                          {isNewContest?("Create Contest"): ("Save Contest")}
                      </Button>
                      {!isNewContest?(
                      <Button style={{ backgroundColor: "#1a1a1a", color: "white", width: "10%" }} 
                      onClick={handleEndContestPressed}
                      disabled={!isContestOver()}>
                          End Contest
                      </Button>):<></>
                      }
                  </Flex>
                )
            }
       
    </Flex>

    )

}