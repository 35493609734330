import { Box, Flex, Text, IconButton } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import React, { useState, useContext, Dispatch, SetStateAction, useRef, useEffect } from "react";
import { AccountsDataContext } from "contexts/accountsDataContext";
import { useHistory } from "react-router-dom";

interface DropdownMenuProps {
  clientIDs: string[]; // Define the type of clientIDs here
  setfilteredClientID: Dispatch<SetStateAction<string>>;
  isUgc: boolean;
  isCommunity: boolean;
  filteredClientID: string;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  clientIDs,
  setfilteredClientID,
  isUgc,
  isCommunity,
  filteredClientID,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const accountsData = useContext(AccountsDataContext);

  const [filteredName, setFilteredName] = useState(
    !isUgc && !isCommunity
      ? accountsData[filteredClientID]["accountName"] ?? "Unavailable"
      : "All accounts"
  );

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleFilteredName = (filteredName: string) => {
    setFilteredName(filteredName);
  };

  const history = useHistory();

  function handleUgcClick(clientID: string) {
    if (clientID !== "allAccountsID") {
      history.push("/admin/ugc-library/?accountId=" + clientID);
    } else {
      history.push("/admin/ugc-library/");
    }
  }

  function handleCommunityClick(clientID: string) {
    if (clientID !== "allAccountsID") {
      history.push("/admin/community/?accountId=" + clientID);
    } else {
      history.push("/admin/community/");
    }
  }

  function handleAutopilotClick(clientID: string) {
    return clientID;
  }

  const clientIDsModified =
    isUgc || isCommunity ? [...clientIDs, "allAccountsID"] : clientIDs;

  const dropdownRef = useRef<HTMLDivElement>(null);
  const closeDropdown = (e) => {
    if (!dropdownRef.current || dropdownRef.current !== e.target && isOpen) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => document.removeEventListener("click", closeDropdown);
  }, [isOpen]);


  return (
    <Box position="relative" mr="20px">
      <Flex
        align="center"
        p="3"
        bg="#F4F4F4"
        borderRadius="10px"
        cursor="pointer"
        //added this closedropdown when click outside
        ref={dropdownRef}
        onClick={toggleDropdown}
      >
        <Text fontSize="15px" textAlign="center" color="black" fontWeight="400">
          {filteredName}
        </Text>
        {isOpen ? (
          <ChevronDownIcon ml={10} w="5" h="5" color="grey" />
        ) : (
          <ChevronUpIcon ml={10} w="5" h="5" color="grey" />
        )}
      </Flex>

      {isOpen && (
        <Box
          position="absolute"
          top="100%"
          bg="#F4F4F4"
          boxShadow="sm"
          borderRadius="10px"
          mt="2"
          zIndex="1"
        >
          <Flex direction="column" p="2">
            {clientIDsModified.map((clientID, index) => (
              <Box
                cursor="pointer"
                key={index}
                p="2"
                borderRadius="10px"
                _hover={{ bg: "gray.200" }}
                onClick={() => {
                  if (isUgc || isCommunity) {
                    if (clientID !== "allAccountsID") {
                      if (isUgc) {
                        handleUgcClick(clientID);
                      } else {
                        handleCommunityClick(clientID);
                      }
                      setfilteredClientID(accountsData[clientID]["clientID"]);
                      toggleDropdown();
                      toggleFilteredName(accountsData[clientID]["accountName"]);
                    } else {
                      if (isUgc) {
                        handleUgcClick(clientID);
                      } else {
                        handleCommunityClick(clientID);
                      }
                      setfilteredClientID("");
                      toggleDropdown();
                      toggleFilteredName("All accounts");
                    }
                  } else {
                    handleAutopilotClick(clientID);
                    setfilteredClientID(accountsData[clientID]["clientID"]);
                    toggleDropdown();
                    toggleFilteredName(accountsData[clientID]["accountName"]);
                  }
                }}
              >
                <Text>
                  {clientID !== "allAccountsID"
                    ? accountsData[clientID]["accountName"]
                    : "All accounts"}
                </Text>
              </Box>
            ))}
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default DropdownMenu;
