import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Text,
  Image,
} from "@chakra-ui/react";

import saveAs from "file-saver";

interface UGCPopupProps {
  link: string;
  idx: number;
  isOpen: boolean;
  onClose: () => void;
  linkData: any;
}

const UGCPopup: React.FC<UGCPopupProps> = ({
  link,
  idx,
  isOpen,
  onClose,
  linkData,
}) => {
  function handleDownload(link: string, idx: number) {
    const fileExtension = link.split('.').pop();
    const fileName = `UGC_${idx}.${fileExtension}`;
    saveAs(link, fileName);
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Download Image</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {link?.includes(".mp4") ? (
            <video controls width="100%">
              <source src={link} type="video/mp4" />
              An error has occurred loading the video.
            </video>
          ) : (
            <Image src={link} alt={`Image ${idx + 1}`} />
          )}
          {(linkData?.likes ||
            linkData?.comments ||
            linkData?.views ||
            linkData?.caption) && (
            <Text fontSize={linkData?.caption && "12px"}>
              <br />
              {linkData?.likes ? (
                <>
                  Likes: {linkData.likes}
                  <br />
                </>
              ) : (
                ""
              )}
              {linkData?.comments ? (
                <>
                  Comments: {linkData.comments}
                  <br />
                </>
              ) : (
                ""
              )}
              {linkData?.views ? (
                <>
                  Views: {linkData.views}
                  <br />
                </>
              ) : (
                ""
              )}
              {linkData?.caption ? (
                <>
                  {linkData.caption}
                  <br />
                </>
              ) : (
                ""
              )}
            </Text>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            backgroundColor="black"
            borderRadius="10px"
            transition="transform 0.2s, box-shadow 0.2s"
            transform="translateY(0)"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
            _hover={{
              transform: "scale(1.05)",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            _focus={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            _active={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
            onClick={() => {
              handleDownload(link, idx);
              /* Add download logic here */
            }}
          >
            <Text color="white" fontSize="15px">
              Download
            </Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UGCPopup;
