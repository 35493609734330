import React from "react";
import { Box, Icon, Flex } from "@chakra-ui/react";
import { BsGraphUp } from "react-icons/bs";
import IconBox from "components/icons/IconBox";
import { MdSend } from "react-icons/md";
// import { StatsRow } from 'components/stats/StatsRow';
import MiniStatistics from "components/card/MiniStatistics";
interface LocationStatTilesProps {
  stats: any;
  offersCount: number;
}

const LocationStatTiles: React.FC<LocationStatTilesProps> = ({
  stats,
  offersCount,
}) => {


  console.log(stats);
  return (
    <Flex width="100%" alignItems="center" justifyContent="start">
      <MiniStatistics
        name={"Estimated Reach"}
        value={String(Math.floor((stats?.reach || 0) / 3))}
        startContent={
          <IconBox
            w="56px"
            h="56px"
            bg="#598FE744"
            icon={
              <Icon
                as={BsGraphUp}
                width="30px"
                height="22.5px"
                color="#1877F2"
              />
            }
          />
        }
      />
      <Box width={5} />

      <MiniStatistics
        name={"Number of Posts"}
        value={stats?.posts ?? 0}
        startContent={
          <IconBox
            w="56px"
            h="56px"
            bg="#598FE744"
            icon={
              <Icon as={MdSend} width="30px" height="22.5px" color="#1877F2" />
            }
          />
        }
      />
      <Box width={5} />

      <MiniStatistics
        name={"Number of Offers"}
        value={offersCount ?? 0}
        startContent={
          <IconBox
            w="56px"
            h="56px"
            bg="#598FE744"
            icon={
              <Icon as={MdSend} width="30px" height="22.5px" color="#1877F2" />
            }
          />
        }
      />
    </Flex>
  );
};

export default LocationStatTiles;
