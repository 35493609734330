import React from 'react';
import { Route, RouteProps, Redirect, useLocation } from 'react-router-dom';

interface AuthenticationRouteProps extends RouteProps {
    // TypeScript way to say children is a React component, but could be more specific if needed
    children: React.ReactNode;
    user: any;
}

const AuthenticationRoute: React.FC<AuthenticationRouteProps> = ({ children, user, ...rest }) => {

    const location = useLocation();

    return (
        <Route
            {...rest}
            render={() =>
                !user || (user && !user.emailVerified) ? children : (
                    <Redirect to={{
                        pathname: "/admin",
                        state: { from: location }
                    }} />
                )
            }
        />
    );
}

export default AuthenticationRoute;
