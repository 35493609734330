import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Center,
  Link,
  Flex,
  Box
} from "@chakra-ui/react";

interface OfferConfirmModel {
  isOpen: boolean;
  onClose: () => void;
  handleOfferSubmit?: () => void;
  modelTitle: string;
  modelContent: string;
  setIsSendingText: Function; 
}

const OfferConfirmPopup: React.FC<OfferConfirmModel> = ({ isOpen, onClose, handleOfferSubmit, modelTitle, modelContent, setIsSendingText }) => {

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent p={'28px 40px 16px 44px'} borderRadius='30px' maxW="868px">
        <ModalHeader p={'0px'}>
          <Flex justifyContent={'space-between'} alignItems={'center'}>
            <Text fontSize={'chakra_2xl'} fontWeight={'500'}>{modelTitle}</Text>
            <Link fontSize='chakra_sm' color='#000' fontWeight="400" onClick={handleClose}>Close</Link>
          </Flex>
        </ModalHeader>
        {/* <ModalCloseButton /> */}
        <ModalBody maxWidth={'705px'} p={'0'} mx={'auto'} my="105px">
          <Center><Text fontSize='chakra_xl' lineHeight={'45px'} color='#667085' fontWeight="400" textAlign={'center'}>{modelContent}</Text></Center>
        </ModalBody>

        <ModalFooter pt={'0px'} px={'0'} pb={'0'} >
          <Flex w={'100%'} justifyContent={'space-around'} gap='24px'>
            <Box>
              <Button
                variant="chakra_btn_gray_light"
                size='chakra_md'
                w="248px"
                borderRadius="10px"
                transition="transform 0.2s, box-shadow 0.2s"
                transform="translateY(0)"
                boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
                tabIndex={-1}
                autoFocus={false}
                onClick={() => {

                  setIsSendingText(false);
                  
                  handleOfferSubmit();
                
                }}
              >
                Don’t send text and post offer
              </Button>
            </Box>
            <Box textAlign={'center'}>
              <Button
                variant="chakra_btn_secondary"
                size='chakra_md'
                w="248px"
                borderRadius="10px"
                transition="transform 0.2s, box-shadow 0.2s"
                transform="translateY(0)"
                boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
                onClick={() => {

                  setIsSendingText(true);
                  
                  handleOfferSubmit();
                
                }}
              >
                Send text and post offer
              </Button>
              <Text mt="10px" color={'#000000'} fontSize={'chakra_xs'} fontWeight={'400'}>(Recommended)</Text>
            </Box>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OfferConfirmPopup;
