import React, { useState, useEffect } from "react";
import { Box, Input, Flex, Text, Image, Button } from "@chakra-ui/react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useRef } from "react";
import { EditIcon } from "@chakra-ui/icons";

interface ImageUploaderProps {
  setExampleUGC: Function;
  setIsImageValid: Function;
  isButton: boolean;
  exampleUgcUrl: string;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  setExampleUGC,
  setIsImageValid,
  isButton,
  exampleUgcUrl,
}) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [inputId, setInputId] = useState<string>("");
  const [imageEdited, setImageEdited] = useState(false);

  useEffect(() => {
    // Generate a unique ID for the input element
    setInputId(`image-input-${Math.random().toString(36).substr(2, 9)}`);
  }, []);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const dataURL = reader.result as string;
        setSelectedImage(dataURL);

        const base64String = dataURL.split(",")[1];

        if (base64String) {
          const binaryString = atob(base64String);
          const arrayBuffer = new ArrayBuffer(binaryString.length);
          const uint8Array = new Uint8Array(arrayBuffer);

          for (let i = 0; i < binaryString.length; i++) {
            uint8Array.set([binaryString.charCodeAt(i)], i);
          }

          setExampleUGC(uint8Array);
          setImageEdited(true);
        } else {
          console.error("Invalid base64 string");
        }
      };

      setIsImageValid(true);
      reader.readAsDataURL(file);
    }
  };


  if (isButton) {
    return (
      <Flex direction="column" align="center" justify="center" >
        {!selectedImage ? (
          <Button
            backgroundColor="black"
            width="150px"
            p="10px 15px"
            borderRadius="10px"
            transition="transform 0.2s, box-shadow 0.2s"
            transform="translateY(0)"
            _hover={{
              transform: "scale(1.05)",
            }}
            _active={{}}
            onClick={() => {
              document.getElementById(inputId)?.click();
            }}
          >
            <Text
              fontWeight="500"
              fontSize="15px"
              textAlign="center"
              color="white"
            >
              Upload Example
            </Text>
          </Button>
        ) : (
          // <Text>Image successfully uploaded!</Text>
          <Flex direction="column" align="center" justify="center">
            <Box
              maxWidth="150px"
              maxHeight="300px"
              borderRadius="10px"
              overflow="hidden"
              boxShadow="lg"
              mb={4}
            >
              <Image src={selectedImage} alt="Uploaded" />
            </Box>
            <Button
              ml="2"
              onClick={() => {
                document.getElementById(inputId)?.click();
              }}
              size="30px"
              backgroundColor="transparent"
            >
              <EditIcon color="black" />
            </Button>
          </Flex>
        )}
        <Input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          id={inputId}
          display="none"
        />
      </Flex>
    );
  }

  return (
    <Flex direction="column" align="center" justify="center">
      {!selectedImage && exampleUgcUrl === "" ? (
        <Box
          backgroundColor="#F4F4F4"
          width="200%"
          borderWidth="2px"
          borderStyle="dashed"
          borderRadius="lg"
          textAlign="center"
          cursor="pointer"
          onClick={() => {
            const input = document.getElementById(inputId);
            input?.click();
          }}
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            height="100%"
            justifyContent="center"
            pt="5%"
            mb="5%"
          >
            <AiOutlineCloudUpload size={35} />
            <Text fontSize="lg" fontWeight="500" mt="2%" color="black">
              Click to Upload
            </Text>
            <Text fontSize="md" fontWeight="400" mt="2%" color="grey">
              Accepted formats include SVG, PNG, JPG or GIF
            </Text>
          </Flex>
        </Box>
      ) : (
        <Flex direction="column" align="center" justify="center" ml="-100%">
          <Box
            minWidth="150px"
            maxWidth="250px"
            borderRadius="10px"
            //   maxHeight="300px"
            overflow="hidden"
            boxShadow="lg"
            mb={4}
          >
            {exampleUgcUrl !== "" && !imageEdited && (
              <Image
                src={exampleUgcUrl}
                alt="Unable to load image"
                onError={(e) => {
                  (e.target as HTMLImageElement).src =
                    "https://firebasestorage.googleapis.com/v0/b/storyitapp-dev.appspot.com/o/UGC%20placeholder.jpg?alt=media&token=1908d380-979a-4671-a66d-699ea13b3622";
                }}
              />
            )}

            {(exampleUgcUrl === "" || imageEdited) && (
              <Image src={selectedImage} alt="Uploaded" />
            )}
          </Box>
          <Button
            ml="2"
            onClick={() => {
              document.getElementById(inputId)?.click();

              // document.getElementById(inputId)?.click();
            }}
            size="30px"
            backgroundColor="transparent"
          >
            <EditIcon color="black" />
          </Button>
        </Flex>
      )}

      <Input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        id={inputId}
        display="none"
      />
    </Flex>
  );
};

export default ImageUploader;
