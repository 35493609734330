import React, { useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Switch,
  Button,
  Input,
  Select,
  VStack,
  Box,
  Flex,
  useDisclosure
} from '@chakra-ui/react';
import { useHistory, useLocation } from "react-router-dom";
import QrCodeButton from '../../accounts/pages/locations/components/QrCodeButton';
import UpdateTouchpointModal from './UpdateTouchpointModal'; 
import { MdEdit } from "react-icons/md";
import { IoTrash } from "react-icons/io5";
import FileUploader from './FileUploader';
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { FaArrowTrendUp, FaArrowTrendDown } from "react-icons/fa6";
import { updateTouchpointForClient } from 'services/firebaseService';
import { subDays } from 'date-fns';


interface Touchpoint {
  id: number;
  type: string;
  design: string;
  impressions: Array<any>;
  enabled: boolean;
  name: string;
}

type TouchPointsTableProps = {
    clientID: string;
    touchpoints: any;
};

const TouchpointsTable = ({
    clientID,
    touchpoints
}: TouchPointsTableProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [editedTouchpoint, setEditedTouchpoint] = useState<Touchpoint>(null);
  const [editedTouchpointID, setEditedTouchpointID] = useState<string>("");

  const [sortedColumn, setSortedColumn] = useState<string>("Name");
  const [isAscendingOrder, setIsAscendingOrder] = useState<boolean>(true);


  const headers = 
  ["Name","Type", "Clicks", "This Week", "Design", "Enabled", "QR Code"]
  

  const sortedTouchpoints = Object.keys(touchpoints)
    .filter(id => id !== 'impressions' && id !== 'id' && id !== "weekly_impressions")
    .sort((a, b) => {
      let columnName = sortedColumn.toLowerCase()
      if(columnName == "this week"){
        columnName = "weekly_impressions"
      }
      else if(columnName == "clicks"){
        columnName = "impressions"
      }
      let valueA = touchpoints[a][columnName];
      let valueB = touchpoints[b][columnName];

      if (typeof valueA == "string") {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
     
      }
      else if (columnName == "impressions"){
        valueA = valueA?.length || 0
        valueB = valueB?.length || 0
      }
      if (valueA < valueB) return isAscendingOrder ? -1 : 1;
      if (valueA > valueB) return isAscendingOrder ? 1 : -1;
      
      //sort by touchpoint id for equal values
      if (a < b) return isAscendingOrder ? -1 : 1;
      if (a > b) return isAscendingOrder ? 1 : -1;
      return 0;
    });

  const handleEdit = (touchpointID: string, touchpoint: Touchpoint) => {
    setEditedTouchpointID(touchpointID)
    setEditedTouchpoint(touchpoint)
    onOpen()
  };

  const sortColumn = (columnName: string, sortOrder: boolean) => {
    if(sortedColumn == columnName && isAscendingOrder ==  sortOrder){
      setSortedColumn("")
    }
    else{
        setSortedColumn(columnName)
        setIsAscendingOrder(sortOrder)
    }
  

  }

  const handleToggleEnabled = (touchpointID: string, touchpointData: Touchpoint) => {
    const new_touchpoint_data = touchpointData;
    new_touchpoint_data.enabled = !new_touchpoint_data.enabled;
    updateTouchpointForClient(clientID, touchpointID, new_touchpoint_data)
  }

  const calculateWeeklyImpressions = (impressions_list: Array<any>) => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    const fourteenDaysAgo = new Date();
    fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);
  
    let this_week = 0
    if(impressions_list != null){
        this_week = impressions_list?.filter(impression => {
          const impressionDate = impression.toDate();
          return impressionDate >= sevenDaysAgo;
        }).length;
    }
    let last_week = 0
    if(impressions_list != null){
      last_week = impressions_list?.filter(impression => {
        const impressionDate = impression.toDate();
        return impressionDate >= fourteenDaysAgo && impressionDate < sevenDaysAgo;
      }).length;
  }
  let percentChange = 0
    if(last_week == 0){
      if(this_week > 0){
        percentChange = 100
      }
    }
    else{

    const change = this_week - last_week;
    
     percentChange = (change / last_week) * 100;
     percentChange = parseFloat(percentChange.toFixed(2));
    }
    return {this_week, percentChange}

  }
  const WeeklyImpressionsTouchpoint = ({id, impressions_list}) => {
    const result = calculateWeeklyImpressions(impressions_list);
    console.log(touchpoints)
    if(id){
      touchpoints[id].weekly_impressions = result.this_week
    }
    return (

      <Td>
         
         {result.this_week}, {result.percentChange>=0?"+":""}{result.percentChange}% 
      
         {/* {result.percentChange>=0?<FaArrowTrendUp />:<FaArrowTrendDown/>} */}
      </Td>
    ) 
  }


  return ( 
    <Box>
    
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr _hover={{ backgroundColor: 'gray.200' }}>
            {headers.map(header => (
                <Th>
                <Flex align="center" gap="5">
                  <Box>{header}</Box>
                  {["Name", "Type", "Clicks","This Week"].includes(header) ? (
                  <Flex flexDirection={"column"}> 
                  <Button  variant="ghost" p={0} m={0} minW="auto" h="auto" onClick={()=>sortColumn(header, false)}>
                      <TiArrowSortedUp size="20px" color={sortedColumn==header&&isAscendingOrder==false?"green":"black"}/>
                  </Button>
                  <Button variant="ghost" p={0} m={0} minW="auto" h="auto" onClick={()=>sortColumn(header, true)}>
                    <TiArrowSortedDown size="20px" color={sortedColumn==header&&isAscendingOrder==true?"green":"black"}/>
                  </Button>
                  </Flex>):(
                    <></>
                  )}
                </Flex>
              </Th>
            ))}
            <Th/>
           
          </Tr>
        </Thead>
        <Tbody>
        <Tr _hover={{ backgroundColor: 'gray.200' }}>
              <Td>Total</Td>
              <Td>N/A</Td>
              <Td>{touchpoints.impressions?touchpoints.impressions.length:0}</Td>
              <WeeklyImpressionsTouchpoint id={null} impressions_list={touchpoints.impressions}/>
            <Td>
                N/A
            </Td>
              <Td>
              Yes
              </Td>
               <Td>
              <QrCodeButton clientID={clientID} locationID={""} touchpointID={""}/>
            </Td>
            <Td />
            </Tr>
          {sortedTouchpoints.map(id => (
            <Tr key={id} _hover={{ backgroundColor: 'gray.200' }}>
              <Td>{touchpoints[id].name}</Td>
              <Td>{touchpoints[id].type}</Td>
              <Td>{touchpoints[id].impressions?touchpoints[id].impressions.length:0}</Td>
              <WeeklyImpressionsTouchpoint id={id} impressions_list={touchpoints[id].impressions}/>
            <Td>
                <FileUploader clientID={clientID} touchpointID={id} touchpointDesign={touchpoints[id].design}/>
            </Td>
              <Td>
                <Switch 
                  isChecked={touchpoints[id].enabled} 
                  onChange={() => handleToggleEnabled(id, touchpoints[id])} 
                  variant='main'
							      colorScheme='green'

                />
              </Td>
              <Td>
              <QrCodeButton clientID={clientID} locationID={""} touchpointID={id}/>
                  {/* {touchpoint.qrCode} */}
            </Td>
              <Td  alignSelf={"flex-end"}>
              <Button onClick={() => handleEdit(id, touchpoints[id])} alignSelf={"flex-end"} variant="ghost">
                 <MdEdit/>
               </Button>
              </Td>
             
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>              
    <Button onClick={()=>handleEdit("",null)} style={{backgroundColor:"#1a1a1a", color:"white", marginTop: "20px",}}>Add Touchpoint</Button>
    <UpdateTouchpointModal isOpen={isOpen} onClose={onClose} clientID={clientID} touchpointID={editedTouchpointID} touchpoint={editedTouchpoint}/>

    </Box>
  );
};

export default TouchpointsTable;
