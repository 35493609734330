import { Box, UseRadioProps, useRadio } from "@chakra-ui/react";
interface RadioCardProps extends UseRadioProps {
  children: React.ReactNode;
}
const RadioCard: React.FC<RadioCardProps> = ({ children, ...props }) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const inputProps = getInputProps();
  const checkboxProps = getCheckboxProps();

  return (
    <Box as="label">
      <input {...inputProps} />
      <Box
        {...checkboxProps}
        cursor="pointer"
        borderWidth="0px"
        borderRadius="12px"
        boxShadow="none"
        fontSize={'chakra_md'}
        fontWeight={'500'}
        _checked={{
          bg: "#24282C",
          color: "white",
          borderColor: "#24282C",
        }}
        _focus={{
          boxShadow: "none",
        }}
        px={'40px'}
        py={'12px'}
      >
        {children}
      </Box>
    </Box>
  );
}
export default RadioCard;