import React, { useContext, useEffect, useState } from 'react';
import { SuperUserDataContext } from 'contexts/superUserDataContext';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import BackButton from 'components/buttons/BackButton';


interface CompanyStatsProps {
    [x: string]: any;
}

interface AdminAccount {
    adminStats?: AdminStats;
}

interface AdminStats {
    reach?: number;
    storyReach?: number;
    clientQty?: number;
    signups?: number;
    activeOfferQty?: number;
    acceptances?: number;
    posts?: number;
    redemptions?: number;
    storyreach?: number;
}


export default function CompanyStats(props: CompanyStatsProps) {
    const superUserData = useContext(SuperUserDataContext);

    const [totalStats, setTotalStats] = useState<any>({
        reach: 0, // total combined follower count (for all users)
        storyReach: 0, // combined follower count for users who have posted content
        totalUsers: 0,
        signups: 0,
        activeOfferQty: 0,
        acceptanceToPostConversion: 0,
        storyreach: 0,
    });

    useEffect(() => {
        if (superUserData && superUserData.length > 0) {
            let newStats = {
                reach: 0,
                totalUsers: 0,
                signups: 0,
                activeOfferQty: 0,
                acceptances: 0,
                posts: 0,
                redemptions: 0,
                storyReach: 0,
            };

            for (const adminAccount of superUserData as AdminAccount[]) { // compile all admin stats into overall company stats
                newStats.reach += adminAccount.adminStats?.reach || 0;
                newStats.totalUsers += adminAccount.adminStats?.clientQty || 0;
                newStats.signups += adminAccount.adminStats.signups || 0;
                newStats.activeOfferQty += adminAccount.adminStats?.activeOfferQty || 0;
                newStats.acceptances += adminAccount.adminStats?.acceptances || 0;
                newStats.posts += adminAccount.adminStats?.posts || 0;
                newStats.redemptions += adminAccount.adminStats?.redemptions || 0;
                newStats.storyReach += adminAccount.adminStats?.storyReach || 0;
            }

            setTotalStats(newStats);
        }
    }, [superUserData]);

    const boxTexts = [
        "Total Community Following: " + (totalStats?.reach ?? 0).toLocaleString(),
        "Estimated Real Reach: " + ((totalStats?.storyReach / 3) ?? 0).toLocaleString(undefined, { maximumFractionDigits: 0 }),
        "Total Sign-Ups: " + (totalStats?.signups ?? 0).toLocaleString(),
        "Total Users: " + (totalStats.totalUsers ?? 0).toLocaleString(),
        "Active Offers: " + (totalStats.activeOfferQty ?? 0).toLocaleString(),
        "Acceptances: " + (totalStats.acceptances ?? 0).toLocaleString(),
        "Posts: " + (totalStats.posts ?? 0).toLocaleString(),
        "Redemptions: " + (totalStats.redemptions ?? 0).toLocaleString(),
        "Acceptance to Post Conversion: " + ((totalStats.posts / totalStats.acceptances)*100)?.toFixed(2) + "%", // total posts / total acceptances
        "Acceptance to Redemption Conversion: " + ((totalStats.redemptions / totalStats.acceptances)*100)?.toFixed(2) + "%", // total redemptions / total acceptances
    ];


    const [isBackButtonHovered, setIsBackButtonHovered] = useState(false);
    return (
        <>
            <Box>
                <Box
                    backgroundColor="white"
                    borderRadius="10px"
                    width="100%"
                    height="80%"
                    position="relative"
                >
                    <Flex alignItems="center">
                        <BackButton
                            isHovered={isBackButtonHovered}
                            setIsHovered={setIsBackButtonHovered}
                        />
                        <Text ml="10px" my="15px" fontWeight="bold" fontSize="25px">
                            Company Stats Overview
                        </Text>
                    </Flex>
                    <Box position="absolute" mt="40px" left={5} width="100%" height="100%" >
                        <Flex justifyContent="center" alignItems="center" pt="10">
                            <Grid templateColumns="repeat(3, 1fr)" gap={8} width="90%">
                                {boxTexts.map((text, index) => (
                                    <Box w="100%" h="40" bg="#DDEAF5" borderRadius="17px" shadow="md" borderWidth="1px" key={index} display="flex" alignItems="center" justifyContent="center">
                                        <Text style={{ fontWeight: 'bold', fontSize: '15px' }}>{text}</Text>
                                    </Box>
                                ))}
                            </Grid>
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </>
    );
}
