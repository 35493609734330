import React, { createContext, useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase";

export const AccountsDataContext = createContext();

export const AccountsDataProvider = ({ children, clientIds }) => {
  const [accountsData, setAccountsData] = useState({});

  useEffect(() => {
    if (!clientIds || clientIds.length === 0) return;

    const fetchedAccountsData = {};

    const unsubscribes = [];

    // Listen for real-time updates for each client in clientIds
    clientIds.forEach((clientId) => {
      const clientDocRef = doc(firestore, "clients", clientId);
      let whiteLabelDomain = null
      const clientUnsubscribe = onSnapshot(clientDocRef, (docSnap) => {
        
        if (docSnap.exists()) {
          const account = docSnap.data();
          fetchedAccountsData[clientId] = { id: docSnap.id, ...account };
          console.log(account.whiteLabelDomain)
          whiteLabelDomain = account.whiteLabelDomain
          setAccountsData((prevData) => ({
            ...prevData,
            [clientId]: fetchedAccountsData[clientId],
          }));
          if (whiteLabelDomain != null) {
            const whitelistDocRef = doc(firestore, "whiteLabeledKits",whiteLabelDomain)
            const whitelistUnsubscribe = onSnapshot(whitelistDocRef, (docSnap) => {
              
              if (docSnap.exists()) {
                const whiteLabeledKit = docSnap.data();
                
                  fetchedAccountsData[clientId] = {  ...fetchedAccountsData[clientId], whiteLabeledKit: whiteLabeledKit };
                  setAccountsData((prevData) => ({
                    ...prevData,
                    [clientId]: fetchedAccountsData[clientId],
                  }));
              }
            });
            unsubscribes.push(whitelistUnsubscribe);

        };
     
          }
        }
      )
    
    unsubscribes.push(clientUnsubscribe);
      
      const statsDocRef = doc(firestore, "clientStats", clientId);
      const statsUnsubscribe = onSnapshot(statsDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          // Convert keys to numbers and sort them to ensure the latest timestamp is retrieved
          const sortedTimestamps = Object.keys(data)
            .map(Number)
            .sort((a, b) => b - a);
          const latestTimestamp = sortedTimestamps[0]; // Latest timestamp is now the first element
          const latestData = data[latestTimestamp];
          if (fetchedAccountsData[clientId]) {
            fetchedAccountsData[clientId] = {
              ...fetchedAccountsData[clientId],
              stats: { ...latestData, timestamps: data },
            };
            setAccountsData((prevData) => ({
              ...prevData,
              [clientId]: fetchedAccountsData[clientId],
            }));
          }
        }
      });
      unsubscribes.push(statsUnsubscribe);
    });

    // Cleanup the listeners when the component is unmounted
    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [clientIds]);

  return (
    <AccountsDataContext.Provider value={accountsData}>
      {children}
    </AccountsDataContext.Provider>
  );
};

export default AccountsDataProvider;
