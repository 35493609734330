import { Flex, Text, Box, Switch, IconButton, Spacer } from "@chakra-ui/react";
import { useContext } from "react";
import { Icon } from "@chakra-ui/react";
import { BiMap } from "react-icons/bi"; // Importing a different icon for location
import { AccountsDataContext } from "contexts/accountsDataContext";
import IconBox from "components/icons/IconBox";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import RemoveLocationButton from "../../locations/components/RemoveLocationButton";
import QrCodeButton from "../../locations/components/QrCodeButton";

export default function LocationCard(props: {
  location: any;
  clientID: string;
}) {
  const { location, clientID } = props;

  const history = useHistory();

  const accountsData = useContext(AccountsDataContext);

  function extractCityState(address: string) {
    const parts = address.split(",").map((part) => part.trim());
    let city, state;

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      if (/\b[A-Z]{2}\b/.test(part) && !state) {
        state = part;
      } else if (part && !city) {
        city = part;
      }
    }

    return `${city || ""}${city && state ? ", " : ""}${state || ""}`;
  }

  return (
    <Box
      borderRadius="10px"
      backgroundColor="#F5F5F5"
      width="100%"
      p="3.5%"
      m="1.5%"
    >
      <Flex alignItems="center">
        <Box
          color="red"
          mt="5px"
          mb="5px"
          display="inline-block"
          width="80px" // Set a specific width
          height="80px" // Set a specific height
          borderRadius="50px"
          backgroundImage={accountsData[clientID]["instagramProfilePicture"]}
          backgroundSize="cover"
        />
        <Flex flexDirection="column">
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex flexDirection="column" mr="20px">
              <Text fontSize="22px" fontWeight="600" ml={3}>
                {accountsData[clientID]["accountName"]}
              </Text>

              <Flex flexDirection="row" ml={2}>
                {/* <Icon as={BiMap} boxSize={6} color="#6F767E" mr={0.2} /> */}
                <Text color="#6F767E" fontWeight="500" fontSize="16px" ml={2}>
                  {extractCityState(location.address)}
                </Text>
              </Flex>
            </Flex>

            <QrCodeButton
              clientID={clientID}
              locationID={location?.locationID}
            />
            {/* <RemoveLocationButton clientID={clientID} locationID={location?.locationID} address={location?.address} /> */}
          </Flex>
        </Flex>
      </Flex>

      <Text fontWeight="500" fontSize="14px" color="grey" mb="2%">
        {location.locationDescription}
      </Text>

      <Flex alignItems="center">
        <Box borderTop="1.5px solid #EAECF0" mt="15px" mb="15px" width="100%" />
      </Flex>

      <Flex width="100%" justifyContent="space-between">
        <Flex flexDirection="row" ml={2} alignItems="center">
          {/* <Box borderRadius="50%" backgroundColor="#598FE71E" p="5px"> */}
          <Icon as={BiMap} boxSize={6} color="#1877F2" />
          {/* </Box> */}
          <Text color="#6F767E" fontWeight="500" fontSize="17px" ml={2}>
            {location.address}
          </Text>
        </Flex>
        <IconBox
          _hover={{
            cursor: "pointer",
            transform: "scale(1.1)",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
          }}
          _focus={
            {
              // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }
          }
          _active={
            {
              // boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }
          }
          onClick={() => {
            history.push("/admin/view-location/?locationId=" + location.id + "&accountId=" +
              clientID);
          }}
          w="40px"
          h="40px"
          bg="#598FE727"
          icon={<ChevronRightIcon w="20px" h="20px" color="#1877F2" />}
        />
      </Flex>
    </Box>
  );
}
