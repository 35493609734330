import React, { createContext, useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase";

export const TouchpointsDataContext = createContext();

export const TouchpointsDataProvider = ({ children, clientIds }) => {
    const [touchpointsData, setTouchpointsData] = useState({});

    useEffect(() => {
        if (!clientIds || clientIds.length === 0) return;

        const fetchedTouchpointData = {};

        const unsubscribes = [];

        // Listen for real-time updates for each autopilot id in clientIds
        clientIds.forEach((clientId) => {
            const touchpointDocRef = doc(firestore, "touchpoints", clientId);
            const touchpointUnsubscribe = onSnapshot(touchpointDocRef, (docSnap) => {
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    fetchedTouchpointData[clientId] = { id: docSnap.id, ...data };
                    setTouchpointsData((prevData) => ({
                        ...prevData,
                        [clientId]: fetchedTouchpointData[clientId],
                    }));
                }
            });
            unsubscribes.push(touchpointUnsubscribe);

        });

        console.log("fetchedTouchpointData", fetchedTouchpointData);

        // Cleanup the listeners when the component is unmounted
        return () => {
            unsubscribes.forEach((unsubscribe) => unsubscribe());
        };
    }, [clientIds]);

    return (
        <TouchpointsDataContext.Provider value={touchpointsData}>
            {children}
        </TouchpointsDataContext.Provider>
    );
};

export default TouchpointsDataProvider;
