import { mode } from '@chakra-ui/theme-tools';
export const buttonStyles = {


	components: {
		Button: {
			baseStyle: {
				borderRadius: '16px',
				boxShadow: '45px 76px 113px 7px rgba(112, 144, 176, 0.08)',
				transition: '.25s all ease',
				boxSizing: 'border-box',
				_focus: {
					boxShadow: 'none'
				},
				_active: {
					boxShadow: 'none'
				}
			},
			variants: {
				chakra_btn_outline_light: (props: any) => ({
					bg: mode('transparent', 'brand.400')(props),
					color: '#6F767E',
					_focus: {
						bg: mode('#EFEFEF', 'brand.400')(props),
						color: '#1A1D1F',
					},
					_active: {
						bg: mode('#EFEFEF', 'brand.400')(props),
						color: '#1A1D1F',
					},
					_hover: {
						bg: mode('#EFEFEF', 'brand.400')(props),
						color: '#1A1D1F',
					}
				}),
				chakra_btn_light: (props: any) => ({
					bg: mode('#EFEFEF', 'brand.400')(props),
					color: '#1A1D1F',
					_focus: {
						bg: mode('#EFEFEF', 'brand.400')(props),
						color: '#1A1D1F',
					},
					_active: {
						bg: mode('#EFEFEF', 'brand.400')(props),
						color: '#1A1D1F',
					},
					_hover: {
						bg: mode('#EFEFEF', 'brand.400')(props),
						color: '#1A1D1F',
					},
				}),
				chakra_btn_secondary: (props: any) => ({
					bg: mode('#24282C', 'brand.400')(props),
					color: '#FFFFFF',
					borderColor: 'transparent',
					_focus: {
						bg: mode('#000000', 'brand.400')(props),
					},
					_active: {
						bg: mode('#000000', 'brand.400')(props),
					},
					_hover: {
						bg: mode('#000000', 'brand.400')(props),
					},
					_disabled: {
						bg: mode('#EFEFEF', 'brand.400')(props),
						color: '#787878',
						borderColor: '#EFEFEF',
						opacity: 1,
						pointerEvents: 'none'
					}
				}),
				chakra_btn_gray: (props: any) => ({
					bg: mode('#E8EBF2', 'brand.400')(props),
					color: '#0D121C',
					borderColor: 'transparent',
					_focus: {
						bg: mode('#0D121C', 'brand.400')(props),
						color: '#FFFFFF',
					},
					_active: {
						bg: mode('#0D121C', 'brand.400')(props),
						color: '#FFFFFF',
					},
					_hover: {
						bg: mode('#0D121C', 'brand.400')(props),
						color: '#FFFFFF',
					},
				}),
				chakra_btn_gray_light: (props: any) => ({
					bg: mode('#E2E2E2', 'brand.400')(props),
					color: mode('#000', 'brand.400')(props),
					borderColor: 'transparent',
					_focus: {
						bg: mode('#000', 'brand.400')(props),
						color: '#FFFFFF',
					},
					_active: {
						bg: mode('#000', 'brand.400')(props),
						color: '#FFFFFF',
					},
					_hover: {
						bg: mode('#000', 'brand.400')(props),
						color: '#FFFFFF',
					},
				}),
				chakra_btn_outline_secondary: (props: any) => ({
					bg: mode('transparent', 'brand.400')(props),
					color: '#1A1D1F',
					borderColor: '#EFEFEF',
					_focus: {
						bg: mode('#24282C', 'brand.400')(props),
						color: '#FFFFFF',
						borderColor: '#24282C',
					},
					_active: {
						bg: mode('#24282C', 'brand.400')(props),
						color: '#FFFFFF',
						borderColor: '#24282C',
					},
					_hover: {
						bg: mode('#24282C', 'brand.400')(props),
						color: '#FFFFFF',
						borderColor: '#24282C',
					},
					_disabled: {
						bg: mode('transparent', 'brand.400')(props),
						color: '#1A1D1F',
						borderColor: '#EFEFEF',
						opacity: 1,
						pointerEvents: 'none'
					}
				}),
				chakra_btn_outline_dark: (props: any) => ({
					bg: mode('transparent', 'brand.400')(props),
					color: '#4F4F4F',
					borderColor: '#4F4F4F',
					_focus: {
						bg: mode('#4F4F4F', 'brand.400')(props),
						color: '#FFFFFF',
						borderColor: '#4F4F4F',
					},
					_active: {
						bg: mode('#4F4F4F', 'brand.400')(props),
						color: '#FFFFFF',
						borderColor: '#4F4F4F',
					},
					_hover: {
						bg: mode('#4F4F4F', 'brand.400')(props),
						color: '#FFFFFF',
						borderColor: '#4F4F4F',
					},
					_disabled: {
						bg: mode('transparent', 'brand.400')(props),
						color: '#1A1D1F',
						borderColor: '#EFEFEF',
						opacity: 1,
						pointerEvents: 'none'
					}
				}),
				chakra_btn_white: (props: any) => ({
					bg: mode('#FCFCFC', '#FCFCFC')(props),
					color: '#1A1D1F',
					borderColor: mode('#EFEFEF', '#EFEFEF')(props),
					_focus: {
						bg: mode('#EFEFEF', '#EFEFEF')(props),
					},
					_active: {
						bg: mode('#EFEFEF', '#EFEFEF')(props),
					},
					_hover: {
						bg: mode('#EFEFEF', '#EFEFEF')(props),
					}
				}),
				chakra_btn_theme: (props: any) => ({
					bg: mode('#24282C', '#24282C')(props),
					color: '#FFFFFF',
					borderColor: mode('#24282C', '#24282C')(props),
					_focus: {
						bg: mode('#000000', 'brand.400')(props),
					},
					_active: {
						bg: mode('#000000', 'brand.400')(props),
					},
					_hover: {
						bg: mode('#000000', 'brand.400')(props),
					},
				}),
				chakra_btn_dark: (props: any) => ({
					bg: mode('#000000', '#000000')(props),
					color: '#fff',
					borderColor: mode('#000000', '#000000')(props),
					_focus: {
						bg: mode('#3C4257', '#3C4257')(props),
					},
					_active: {
						bg: mode('#3C4257', '#3C4257')(props),
					},
					_hover: {
						bg: mode('#3C4257', '#3C4257')(props),
					}
				}),
				chakra_btn_payment: (props: any) => ({
					bg: mode('#3C4257', '#3C4257')(props),
					color: '#8792A2',
					borderColor: mode('#3C4257', '#3C4257')(props),
					_focus: {
						bg: mode('#000', '#000')(props),
					},
					_active: {
						bg: mode('#000', '#000')(props),
					},
					_hover: {
						bg: mode('#000', '#000')(props),
					}
				}),
				outline: () => ({
					borderRadius: '16px'
				}),
				brand: (props: any) => ({
					bg: mode('brand.500', 'brand.400')(props),
					color: 'white',
					_focus: {
						bg: mode('brand.500', 'brand.400')(props)
					},
					_active: {
						bg: mode('brand.500', 'brand.400')(props)
					},
					_hover: {
						bg: mode('brand.600', 'brand.400')(props)
					}
				}),
				darkBrand: (props: any) => ({
					bg: mode('brand.900', 'brand.400')(props),
					color: 'white',
					_focus: {
						bg: mode('brand.900', 'brand.400')(props)
					},
					_active: {
						bg: mode('brand.900', 'brand.400')(props)
					},
					_hover: {
						bg: mode('brand.800', 'brand.400')(props)
					}
				}),
				lightBrand: (props: any) => ({
					bg: mode('#F2EFFF', 'whiteAlpha.100')(props),
					color: mode('brand.500', 'white')(props),
					_focus: {
						bg: mode('#F2EFFF', 'whiteAlpha.100')(props)
					},
					_active: {
						bg: mode('secondaryGray.300', 'whiteAlpha.100')(props)
					},
					_hover: {
						bg: mode('secondaryGray.400', 'whiteAlpha.200')(props)
					}
				}),
				light: (props: any) => ({
					bg: mode('secondaryGray.300', 'whiteAlpha.100')(props),
					color: mode('black', 'white')(props),
					_focus: {
						bg: mode('secondaryGray.300', 'whiteAlpha.100')(props)
					},
					_active: {
						bg: mode('secondaryGray.300', 'whiteAlpha.100')(props)
					},
					_hover: {
						bg: mode('secondaryGray.400', 'whiteAlpha.200')(props)
					}
				}),
				action: (props: any) => ({
					fontWeight: '500',
					borderRadius: '50px',
					bg: mode('secondaryGray.300', 'brand.400')(props),
					color: mode('brand.500', 'white')(props),
					_focus: {
						bg: mode('secondaryGray.300', 'brand.400')(props)
					},
					_active: { bg: mode('secondaryGray.300', 'brand.400')(props) },
					_hover: {
						bg: mode('secondaryGray.200', 'brand.400')(props)
					}
				}),
				setup: (props: any) => ({
					fontWeight: '500',
					borderRadius: '50px',
					bg: mode('transparent', 'brand.400')(props),
					border: mode('1px solid', '0px solid')(props),
					borderColor: mode('secondaryGray.400', 'transparent')(props),
					color: mode('black', 'white')(props),
					_focus: {
						bg: mode('transparent', 'brand.400')(props)
					},
					_active: { bg: mode('transparent', 'brand.400')(props) },
					_hover: {
						bg: mode('secondaryGray.100', 'brand.400')(props)
					}
				})
			},
			sizes: {
				'chakra_xs': (props: any) => ({
					boxShadow: 'none',
					h: 'auto',
					fontSize: '14px',
					lineHeight: '20px',
					p: '10px 40px',
					borderRadius: '8px',
				}),
				'chakra_sm': (props: any) => ({
					boxShadow: 'none',
					h: 'auto',
					fontSize: '15px',
					lineHeight: '24px',
					p: '6px 16px',
					borderRadius: '12px',
				}),
				'chakra_md': (props: any) => ({
					boxShadow: 'none',
					h: 'auto',
					fontSize: '15px',
					lineHeight: '24px',
					p: '8px 16px',
					borderRadius: '8px',
				}),
				'chakra_lg': (props: any) => ({
					boxShadow: 'none',
					h: 'auto',
					fontSize: '15px',
					lineHeight: '24px',
					p: '10px 20px',
					borderRadius: '12px',
					border: '1px solid'
				})
			}
		}
	}
};
