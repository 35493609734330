import React, { useContext, useState, useEffect } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Text,
  Spinner,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import CustomerPreview from "views/admin/community/components/CustomerPreview";
import { AdminDataContext } from "contexts/adminDataContext";
import Environment from "environment";
import { MdImage, MdOutlineBarChart, MdPeople } from "react-icons/md";
import StatsRow from "components/stats/StatsRow";
import { useHistory } from "react-router-dom";
import CustomerGroupsPreview from "./components/CustomerGroupsPreview";
import { AccountsDataContext } from "contexts/accountsDataContext";
import InfluencerSwitch from "./components/InfluencerSwitch";
import OffersPreview from "../accounts/pages/accountOverview/components/OffersPreview";
import { TbDiscount2 } from "react-icons/tb";
import { CustomersDataContext } from "contexts/customersDataContext";
import useOffersData from "services/offerDataService";
import CommunityDropdownMenu from "./CommunityDropdownMenu";
import CommunityMetricChartCard from "components/charts/CommunityMetricChartCard";

export default function Community() {

  const { adminData } = useContext(AdminDataContext);
  const history = useHistory();

  const adminStats = adminData?.adminStats;

  const accountsData = useContext(AccountsDataContext);

  const [filteredClientID, setfilteredClientID] = useState("");

  const [isInfluencerMode, setIsInfluencerMode] = useState(false);

  const toast = useToast();

  let [influencerFollowing, setInfluencerFollowing] = useState(0);

  const [influencers, setInfluencers] = useState<Set<string>>(new Set());

  const { data: customersData, isLoading: customerLoading } = useContext(CustomersDataContext);
  /**
   * @info changes made by nirmal on date :- 16/05/2024
   * Added custome hook to get offerData
   * @todo need to add userId related condition
   */

  const {
    isLoading: offerLoading = false,
    offersData,
    totalOffers,
  } = useOffersData({
    clientID: filteredClientID ?? "",
    perPage: 5,
    isInfluencerOffer: true,
  });




  useEffect(() => {
  }, [offersData]);

  useEffect(() => {

    const countInfluencers = (
      clientCustomerData: any,
      allAccounts: boolean
    ) => {
      // Reset local influencer count for each client
      if (!allAccounts) {
        setInfluencers(new Set());
        setInfluencerFollowing(0);
      }

      if (!clientCustomerData) {
        setInfluencers(new Set());
        setInfluencerFollowing(0);
        return;
      }

      Object.keys(clientCustomerData)
        .filter((key) => key !== "id")
        .forEach((phoneNumber) => {
          const customer = clientCustomerData[phoneNumber];
          if (customer?.isInfluencer) {
            setInfluencers((prevInfluencers) => {
              const updatedInfluencers = new Set(prevInfluencers);
              if (!updatedInfluencers.has(phoneNumber)) {
                setInfluencerFollowing((prevFollowing) => {
                  let updatedFollowing = prevFollowing;
                  return (updatedFollowing +=
                    customer.instagramFollowerCount || 0);
                });
              }
              updatedInfluencers.add(phoneNumber);

              return updatedInfluencers;
            });
          }
        });
    };
    if (filteredClientID === "" || filteredClientID === "allAccountsID") {
      Object.keys(customersData).forEach((clientID) => {
        const clientCustomerData = customersData[clientID];
        countInfluencers(clientCustomerData, true);
      });
    } else {
      const clientCustomerData = customersData[filteredClientID];

      countInfluencers(clientCustomerData, false);
    }
  }, [customersData, filteredClientID]); // Dependency array

  if (!adminData || !adminData.clientIDs || offerLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="200px">
        <Spinner size="xl" />
      </Flex>
    );
  }

  function toggleSwitch(isInfluencerMode: boolean) {
    setIsInfluencerMode(isInfluencerMode);
  }



  const accountData =
    filteredClientID === "" || filteredClientID === "allAccountsID"
      ? adminData
      : accountsData[filteredClientID];

  const timestampedData =
    filteredClientID === "" || filteredClientID === "allAccountsID"
      ? adminStats && adminStats.timestamps
        ? adminStats.timestamps
        : []
      : accountData && accountData.stats && accountData.stats.timestamps
        ? accountData.stats.timestamps
        : [];

  const chartData = [
    {
      name: isInfluencerMode ? "Influencer Following" : "Community Following",
      // Map the keys to data points
      data:
        timestampedData == null || timestampedData.length > 0
          ? []
          : Object.keys(timestampedData)
            .map((timestamp) => ({
              // Convert timestamp to a number and create a Date object
              x: new Date(parseInt(timestamp)).getTime(),
              y: timestampedData[timestamp].reach || 0, // Use 0 if reach is undefined
            }))
            // Sort the data points by the 'x' property, which is the timestamp in milliseconds
            .sort((a, b) => a.x - b.x),
    },
  ];

  const influencerOffers = offersData
    ? Object.values(offersData).filter((offer: any) => {
      if (filteredClientID === "" || filteredClientID === "allAccountsID") {
        return offer.isInfluencerOffer;
      } else {
        return offer.isInfluencerOffer && offer.clientID === filteredClientID;
      }
    })
    : [];

  const statsList = [
    {
      title: isInfluencerMode ? "Influencers" : "Signups",
      value: String(
        isInfluencerMode
          ? influencers.size
          : filteredClientID === "" || filteredClientID === "allAccountsID"
            ? adminData?.adminStats?.signups ?? 0
            : accountData?.stats?.signups ?? 0
      ),
      icon: MdPeople,
    },
    {
      title: "Number of Posts",
      value: String(
        filteredClientID === "" || filteredClientID === "allAccountsID"
          ? adminData?.adminStats?.posts ?? 0
          : accountData?.stats?.posts ?? 0
      ),
      icon: MdImage,
    },
    {
      title: isInfluencerMode ? "Offers" : "Rewards Redeemed",
      value: String(
        isInfluencerMode
          ? totalOffers
          : // filteredClientID === "" || filteredClientID === "allAccountsID"
          //   ? 0
          //   : influencerOffers
          filteredClientID === "" || filteredClientID === "allAccountsID"
            ? adminData?.adminStats?.redemptions ?? 0
            : accountData?.stats?.redemptions ?? 0
      ),
      icon: TbDiscount2,
    },
    {
      title: isInfluencerMode ? "Influencer Following" : "Community Following",
      value: String(
        isInfluencerMode
          ? influencerFollowing
          : filteredClientID === "" || filteredClientID === "allAccountsID"
            ? adminData?.adminStats?.reach ?? 0
            : accountData?.stats?.reach ?? 0
      ),
      icon: MdOutlineBarChart,
    },
  ];

  const handleCopy = async (url: string) => {
    navigator.clipboard.writeText(url);

    toast({
      title: "Invite link copied to clipboard",
      status: "success",
      duration: 1250,
      isClosable: false,
    });
  };

  const inviteLink = accountData?.isWhiteLabeled
    ? `${accountData?.whiteLabelDomain}/invite/clients?clientID=${accountData?.clientID}`
    : `https://${Environment.USE_TEST_MODE
      ? "storyitapp-dev.web.app"
      : "offers.storyit.app"
    }/invite/clients?clientID=${accountData?.clientID}`;

  return (
    <Box
      overflow="auto"
      backgroundColor="white"
      borderRadius="10px"
      height="calc(100vh - 135px)" //'750px'
      position="relative" // Make this box a reference point for the pseudo-element
    >
      <Box
        position="absolute" // Position the grid absolutely within the container
        left={5}
        width="calc(100% - var(--chakra-space-5))"
        minHeight="100%"
        paddingBottom={5}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          pr="2%"
          mt="1.5%"
        >
          <Flex justifyContent="start" alignItems="center">
            {filteredClientID !== "" &&
              filteredClientID !== "allAccountsID" && (
                <Box
                  marginLeft="auto"
                  color="white"
                  shadow="md"
                  display="inline-block"
                  width="45px"
                  height="45px"
                  borderRadius="50px"
                  backgroundImage={accountData?.instagramProfilePicture}
                  backgroundSize="cover"
                  mr="0px"
                />
              )}
            <Text ml="20px" fontWeight="bold" fontSize="25px">
              {isInfluencerMode ? "Influencer" : ""} Community
            </Text>
          </Flex>

          <Flex
            alignItems="center"
            justifyContent="end"
            width="35%"
            height="100%"
          >
            <InfluencerSwitch
              isInfluencerMode={isInfluencerMode}
              toggleSwitch={toggleSwitch}
            />
            <Box w="5px"></Box>
            {accountsData && Object.keys(accountsData)?.length > 0 && (
              <CommunityDropdownMenu
                filteredClientID={filteredClientID}
                isCommunity={true}
                clientIDs={adminData.clientIDs}
                setfilteredClientID={setfilteredClientID}
                allAccounts={accountsData}
              />
            )}
          </Flex>
        </Flex>

        <Flex alignItems="center">
          <Box borderTop="1.3px solid #E2E8F0" my="15px" width="97%" />
        </Flex>

        <Box mr="3%">
          <StatsRow stats={statsList} />
        </Box>

        <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mt="35px">
          <Flex
            gridColumn={{ md: "span 2" }}
            flexDirection="column"
            w="100%"
            mb="0px"
            justifyContent={"start"}
          >
            {/**
             * Changes made by nirmal on 21/05/2024
             * added this new CommunityMetricChartCard instead of old one because I have changed its logic and old is using on other place as well
             */}
            <CommunityMetricChartCard chartData={chartData} />

            {!isInfluencerMode && (
              <CustomerGroupsPreview clientID={filteredClientID} />
            )}
            {isInfluencerMode && (
              <OffersPreview
                allAccounts={
                  filteredClientID === "" ||
                  filteredClientID === "allAccountsID"
                }
                numOffers={1}
                clientID={filteredClientID}
                isInfluencerMode={true}
              />
            )}
          </Flex>
          <Box
            width="90%"
            // backgroundColor="transparent"
            height="100%"
            boxShadow="md"
            borderRadius="10px"
          >
            <Flex
              justifyContent="space-between"
              alignItems="center"
              w="100%"
              p="4%"
            >
              <Text fontWeight="bold" fontSize="20px">
                {isInfluencerMode ? "Influencers" : "Top Customers"}
              </Text>

              <Flex justifyContent="end" alignItems="center" width="50%">
                {isInfluencerMode && filteredClientID !== "" && (
                  <Button
                    backgroundColor="black"
                    borderRadius="10px"
                    transition="transform 0.2s, box-shadow 0.2s"
                    transform="translateY(0)"
                    boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                    _hover={{
                      transform: "scale(1.05)",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _focus={{
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    _active={{
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    onClick={() => {
                      handleCopy(inviteLink);
                    }}
                  >
                    <Text color="white" fontSize="12.5px" fontWeight="500">
                      Invite
                    </Text>
                  </Button>
                )}

                <Button
                  ml="5%"
                  backgroundColor="black"
                  borderRadius="10px"
                  transition="transform 0.2s, box-shadow 0.2s"
                  transform="translateY(0)"
                  boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                  _hover={{
                    transform: "scale(1.05)",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  }}
                  _focus={{
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  }}
                  _active={{
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                  onClick={() => {
                    history.push(
                      "/admin/view-all-customers?accountId=" + filteredClientID
                    );
                  }}
                >
                  <Text color="white" fontSize="12.5px" fontWeight="500">
                    View All
                  </Text>
                </Button>
              </Flex>
            </Flex>
            <Box>
              <CustomerPreview
                clientID={filteredClientID}
                isInfluencerMode={isInfluencerMode}
                inviteLink={inviteLink}
              />
            </Box>
          </Box>
        </SimpleGrid>
      </Box>
    </Box>
  );
}
