import React, { useState } from "react";
import { Text, Flex, Box, Grid, Button, Spacer } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AccountsDataContext } from "contexts/accountsDataContext";
import TitleAndDescription from "../components/TitleAndDescription";
import { IoMdInformationCircle } from "react-icons/io";
import AccountsGrid from "../../accounts/AccountsGrid";
import AccountTile from "../../accounts/AccountTile";
import { MouseEventHandler } from "react";
import ContentTypeCheckbox from "../components/ContentTypeCheckbox";
import ContentParameters from "./ContentParameters";
import { useHistory } from "react-router-dom";
import { AutopilotDataContext } from "../../../../contexts/autopilotDataContext";
import { updateAutopilotDoc } from "../../../../services/firebaseService";

interface ContentTypeProps {
  setStep: Function;
  setAutopilotDataMap: Function;
  autopilotDataMap: Map<string, any>;
  editingContentType: string;
}

function ContentType({
  setStep,
  setAutopilotDataMap,
  autopilotDataMap,
  editingContentType,
}: ContentTypeProps) {
  const autopilotDataContext = useContext(AutopilotDataContext);

  const clientAutopilotData =
    autopilotDataContext[autopilotDataMap.get("clientID")] ?? {};


  const instagramStoryTemp = clientAutopilotData.instagramStory
    ? new Map(Object.entries(clientAutopilotData.instagramStory))
    : new Map<string, any>();
  const instagramPostTemp = clientAutopilotData.instagramPost
    ? new Map(Object.entries(clientAutopilotData.instagramPost))
    : new Map<string, any>();
  const instagramReelTemp = clientAutopilotData.instagramReel
    ? new Map(Object.entries(clientAutopilotData.instagramReel))
    : new Map<string, any>();
  const ugcTemp = clientAutopilotData.ugc
    ? new Map(Object.entries(clientAutopilotData.ugc))
    : new Map<string, any>();
  const tiktokVideoTemp = clientAutopilotData.tiktokVideo
    ? new Map(Object.entries(clientAutopilotData.tiktokVideo))
    : new Map<string, any>();

  const [instagramStoryData, setInstagramStoryData] = useState(
    instagramStoryTemp.size === 0
      ? autopilotDataMap.get("instagramStory") ?? new Map<string, any>()
      : instagramStoryTemp
  );

  const [instagramPostData, setInstagramPostData] = useState(
    instagramPostTemp.size === 0
      ? autopilotDataMap.get("instagramPost") ?? new Map<string, any>()
      : instagramPostTemp
  );
  const [instagramReelData, setInstagramReelData] = useState(
    instagramReelTemp.size === 0
      ? autopilotDataMap.get("instagramReel") ?? new Map<string, any>()
      : instagramReelTemp
  );
  const [ugcData, setUgcData] = useState(
    ugcTemp.size === 0
      ? autopilotDataMap.get("ugc") ?? new Map<string, any>()
      : ugcTemp
  );
  const [tiktokVideoData, setTiktokVideoData] = useState(
    tiktokVideoTemp.size === 0
      ? autopilotDataMap.get("tiktokVideo") ?? new Map<string, any>()
      : tiktokVideoTemp
  );

  const [contentTypesSelected, setContentTypesSelected] = useState(
    autopilotDataMap.get("contentTypes") ?? []
  );

  const [contentTypesPreSelected, setContentTypesPreSelected] = useState(
    autopilotDataMap.get("contentTypesPreSelected") ?? []
  );

  const history = useHistory();

  useEffect(() => {
    // Function to identify non-empty content types and mark them as pre-selected.
    const identifyAndMarkPreSelectedContentTypes = () => {
      const tempContentTypesPreSelected = [];

      if (instagramStoryData.size > 1)
        tempContentTypesPreSelected.push("instagramStory");
      if (instagramPostData.size > 1)
        tempContentTypesPreSelected.push("instagramPost");
      if (instagramReelData.size > 1)
        tempContentTypesPreSelected.push("instagramReel");
      if (ugcData.size > 1) tempContentTypesPreSelected.push("ugc");
      if (tiktokVideoData.size > 1)
        tempContentTypesPreSelected.push("tiktokVideo");

      setContentTypesPreSelected(tempContentTypesPreSelected);
      // Also update contentTypesSelected with pre-selected content types
      setContentTypesSelected(tempContentTypesPreSelected);
    };

    identifyAndMarkPreSelectedContentTypes();
  }, []); // Run only once to set initial pre-selected types

  if (instagramStoryData.size === 0) {
    setInstagramStoryData(() => {
      const tempMap = new Map(instagramStoryData);
      tempMap.set("contentType", "instagramStory");
      return tempMap;
    });
  }

  if (instagramPostData.size === 0) {
    setInstagramPostData(() => {
      const tempMap = new Map(instagramPostData);
      tempMap.set("contentType", "instagramPost");
      return tempMap;
    });
  }

  if (instagramReelData.size === 0) {
    setInstagramReelData(() => {
      const tempMap = new Map(instagramReelData);
      tempMap.set("contentType", "instagramReel");
      return tempMap;
    });
  }

  if (ugcData.size === 0) {
    setUgcData(() => {
      const tempMap = new Map(ugcData);
      tempMap.set("contentType", "ugc");
      return tempMap;
    });
  }

  if (tiktokVideoData.size === 0) {
    setTiktokVideoData(() => {
      const tempMap = new Map(tiktokVideoData);
      tempMap.set("contentType", "tiktokVideo");
      return tempMap;
    });
  }

  const [isInstagramStoryPopupOpen, setIsInstagramStoryPopupOpen] = useState(
    editingContentType === "instagramStory" ? true : false
  );
  const [isInstagramPostPopupOpen, setIsInstagramPostPopupOpen] = useState(
    editingContentType === "instagramPost" ? true : false
  );
  const [isInstagramReelPopupOpen, setIsInstagramReelPopupOpen] = useState(
    editingContentType === "instagramReel" ? true : false
  );
  const [isUgcPopupOpen, setIsUgcPopupOpen] = useState(
    editingContentType === "ugc" ? true : false
  );

  const [isTiktokVideoPopupOpen, setIsTiktokVideoPopupOpen] = useState(
    editingContentType === "tiktokVideo" ? true : false
  );

  const handleCheckboxChange = (contentType: string, isChecked: boolean) => {
    if (isChecked) {
      setContentTypesSelected((prevList: string[]) => [
        ...prevList,
        contentType,
      ]);
    } else {
      const updatedList = contentTypesSelected.filter(
        (item: string) => item !== contentType
      );
      setContentTypesSelected(updatedList);
    }
  };

  const isButtonDisabled = () => {
    if (contentTypesSelected.length === 0) {
      return true;
    }
    for (let i = 0; i < contentTypesSelected.length; i++) {
      switch (contentTypesSelected[i]) {
        case "instagramStory":
          if (Array.from(instagramStoryData.keys()).length <= 1) {
            return true;
          }
          break;

        case "instagramPost":
          if (Array.from(instagramPostData.keys()).length <= 1) {
            return true;
          }
          break;

        case "instagramReel":
          if (Array.from(instagramReelData.keys()).length <= 1) {
            return true;
          }
          break;

        case "ugc":
          if (Array.from(ugcData.keys()).length <= 1) {
            return true;
          }
          break;

        case "tiktokVideo":
          if (Array.from(tiktokVideoData.keys()).length <= 1) {
            return true;
          }
          break;

        default:
          break;
      }
    }

    return false;
  };

  async function handleClick() {
    setAutopilotDataMap(() => {
      const tempMap = new Map(autopilotDataMap);
      for (let i = 0; i < contentTypesSelected.length; i++) {
        contentTypesSelected[i] === "instagramStory"
          ? tempMap.set("instagramStory", instagramStoryData)
          : contentTypesSelected[i] === "instagramPost"
            ? tempMap.set("instagramPost", instagramPostData)
            : contentTypesSelected[i] === "instagramReel"
              ? tempMap.set("instagramReel", instagramReelData)
              : contentTypesSelected[i] === "ugc"
                ? tempMap.set("ugc", ugcData)
                : tempMap.set("tiktokVideo", tiktokVideoData);
      }
      tempMap.set("contentTypes", contentTypesSelected);
      tempMap.set("contentTypesPreSelected", contentTypesPreSelected);
      tempMap.set("editingContentType", editingContentType);

      async function updateData() {
        if (editingContentType !== "") {
          console.log("tempMap===", tempMap);
          await updateAutopilotDoc(tempMap);
        }
      }

      updateData();

      return tempMap;
    });

    if (editingContentType === "") {
      setStep(2);
    } else {
      backFunction();
    }
  }

  function backFunction() {
    history.goBack();
  }

  return (
    <Flex flexDirection="column" alignItems="start">
      <Flex justifyContent="start" alignItems="center" width="100%">
        <TitleAndDescription
          hideBackButton={false}
          backFunction={backFunction}
          title={"Content Type"}
          description={
            "Choose the type of content you want in your auto campaign"
          }
        />
        <Box
          marginLeft="auto"
          color="white"
          display="inline-block"
          width="50px"
          height="50px"
          borderRadius="50px"
          backgroundImage={autopilotDataMap.get("clientProfilePic")}
          backgroundSize="cover"
        />
      </Flex>

      <Box borderTop="1.3px solid #E2E8F0" width="100%" mt="1.5%" />

      <Box
        height="750px"
        borderRadius="lg"
        boxShadow="lg"
        overflowY="auto"
        width="100%"
        mt="2.5%"
        p={7}
      >
        <Flex justifyContent="start" alignItems="center">
          <Text fontSize="20px" fontWeight="400">
            Content Types
          </Text>
          <Box
            ml=".5%"
            as="div"
            cursor="pointer"
            onClick={() => { }}
            display="inline-block" // Ensures the icon doesn't take full width
          >
            <IoMdInformationCircle color="#6F767E" size="20px" />
          </Box>
        </Flex>

        {((editingContentType !== "" &&
          editingContentType === "instagramStory") ||
          editingContentType === "") && (
            <Flex
              width="100%"
              mt="3%"
              alignItems="center"
              justifyContent="space-between"
            >
              <ContentTypeCheckbox
                isComplete={
                  Array.from(instagramStoryData.keys()).length > 1 &&
                  contentTypesSelected.includes("instagramStory")
                }
                header={"Instagram Story"}
                description={"24 hours instagram story, photo or video format"}
                isChecked={contentTypesSelected.includes("instagramStory")}
                handleCheckboxChange={handleCheckboxChange}
                contentType="instagramStory"
              />

              {contentTypesSelected.includes("instagramStory") && (
                <Button
                  ml="auto"
                  onClick={() => {
                    setIsInstagramStoryPopupOpen(true);
                  }}
                  backgroundColor="black"
                  width="100px"
                  p="10px 15px"
                  borderRadius="10px"
                  transition="transform 0.2s, box-shadow 0.2s"
                  transform="translateY(0)"
                  _hover={{
                    transform: "scale(1.05)",
                  }}
                  _active={{}}
                >
                  <Text
                    fontWeight="500"
                    fontSize="15px"
                    textAlign="center"
                    color="white"
                  >
                    Set up
                  </Text>
                </Button>
              )}
            </Flex>
          )}
        <Box height="7%" />

        {((editingContentType !== "" &&
          editingContentType === "instagramPost") ||
          editingContentType === "") && (
            <Flex
              width="100%"
              mt="3%"
              alignItems="center"
              justifyContent="space-between"
            >
              <ContentTypeCheckbox
                isComplete={
                  Array.from(instagramPostData.keys()).length > 1 &&
                  contentTypesSelected.includes("instagramPost")
                }
                header={"Instagram Post"}
                description={"instagram post, picture format"}
                isChecked={contentTypesSelected.includes("instagramPost")}
                handleCheckboxChange={handleCheckboxChange}
                contentType="instagramPost"
              />

              {contentTypesSelected.includes("instagramPost") && (
                <Button
                  ml="auto"
                  onClick={() => {
                    setIsInstagramPostPopupOpen(true);
                  }}
                  backgroundColor="black"
                  width="100px"
                  p="10px 15px"
                  borderRadius="10px"
                  transition="transform 0.2s, box-shadow 0.2s"
                  transform="translateY(0)"
                  _hover={{
                    transform: "scale(1.05)",
                  }}
                  _active={{}}
                >
                  <Text
                    fontWeight="500"
                    fontSize="15px"
                    textAlign="center"
                    color="white"
                  >
                    Set up
                  </Text>
                </Button>
              )}
            </Flex>
          )}
        <Box height="7%" />

        {((editingContentType !== "" &&
          editingContentType === "instagramReel") ||
          editingContentType === "") && (
            <Flex
              width="100%"
              mt="3%"
              alignItems="center"
              justifyContent="space-between"
            >
              <ContentTypeCheckbox
                isComplete={
                  Array.from(instagramReelData.keys()).length > 1 &&
                  contentTypesSelected.includes("instagramReel")
                }
                header={"Instagram Reel"}
                description={"Instagram reel, video format"}
                isChecked={contentTypesSelected.includes("instagramReel")}
                handleCheckboxChange={handleCheckboxChange}
                contentType="instagramReel"
              />

              {contentTypesSelected.includes("instagramReel") && (
                <Button
                  ml="auto"
                  onClick={() => {
                    setIsInstagramReelPopupOpen(true);
                  }}
                  backgroundColor="black"
                  width="100px"
                  p="10px 15px"
                  borderRadius="10px"
                  transition="transform 0.2s, box-shadow 0.2s"
                  transform="translateY(0)"
                  _hover={{
                    transform: "scale(1.05)",
                  }}
                  _active={{}}
                >
                  <Text
                    fontWeight="500"
                    fontSize="15px"
                    textAlign="center"
                    color="white"
                  >
                    Set up
                  </Text>
                </Button>
              )}
            </Flex>
          )}
        <Box height="7%" />

        {((editingContentType !== "" && editingContentType === "ugc") ||
          editingContentType === "") && (
            <Flex
              width="100%"
              mt="3%"
              alignItems="center"
              justifyContent="space-between"
            >
              <ContentTypeCheckbox
                isComplete={
                  Array.from(ugcData.keys()).length > 1 &&
                  contentTypesSelected.includes("ugc")
                }
                header={"UGC"}
                description={
                  "User generated content sent directly to you, picture or video format"
                }
                isChecked={contentTypesSelected.includes("ugc")}
                handleCheckboxChange={handleCheckboxChange}
                contentType="ugc"
              />

              {contentTypesSelected.includes("ugc") && (
                <Button
                  ml="auto"
                  onClick={() => {
                    setIsUgcPopupOpen(true);
                  }}
                  backgroundColor="black"
                  width="100px"
                  p="10px 15px"
                  borderRadius="10px"
                  transition="transform 0.2s, box-shadow 0.2s"
                  transform="translateY(0)"
                  _hover={{
                    transform: "scale(1.05)",
                  }}
                  _active={{}}
                >
                  <Text
                    fontWeight="500"
                    fontSize="15px"
                    textAlign="center"
                    color="white"
                  >
                    Set up
                  </Text>
                </Button>
              )}
            </Flex>
          )}
        <Box height="7%" />

        {((editingContentType !== "" && editingContentType === "tiktokVideo") ||
          editingContentType === "") && (
            <Flex
              width="100%"
              mt="3%"
              alignItems="center"
              justifyContent="space-between"
            >
              <ContentTypeCheckbox
                isComplete={
                  Array.from(tiktokVideoData.keys()).length > 1 &&
                  contentTypesSelected.includes("tiktokVideo")
                }
                header={"Tiktok Video"}
                description={"Tiktok post, video format"}
                isChecked={contentTypesSelected.includes("tiktokVideo")}
                handleCheckboxChange={handleCheckboxChange}
                contentType="tiktokVideo"
              />

              {contentTypesSelected.includes("tiktokVideo") && (
                <Button
                  ml="auto"
                  onClick={() => {
                    setIsTiktokVideoPopupOpen(true);
                  }}
                  backgroundColor="black"
                  width="100px"
                  p="10px 15px"
                  borderRadius="10px"
                  transition="transform 0.2s, box-shadow 0.2s"
                  transform="translateY(0)"
                  _hover={{
                    transform: "scale(1.05)",
                  }}
                  _active={{}}
                >
                  <Text
                    fontWeight="500"
                    fontSize="15px"
                    textAlign="center"
                    color="white"
                  >
                    Set up
                  </Text>
                </Button>
              )}
            </Flex>
          )}
      </Box>

      <Box height="7%" />

      {isInstagramStoryPopupOpen && (
        <ContentParameters
          setContentDataMap={setInstagramStoryData}
          contentDataMap={instagramStoryData}
          isOpen={isInstagramStoryPopupOpen}
          integrations={autopilotDataMap.get("integrations")}
          handleClose={() => {
            setIsInstagramStoryPopupOpen(false);
          }}
        />
      )}

      {isInstagramPostPopupOpen && (
        <ContentParameters
          setContentDataMap={setInstagramPostData}
          contentDataMap={instagramPostData}
          isOpen={isInstagramPostPopupOpen}
          integrations={autopilotDataMap.get("integrations")}
          handleClose={() => {
            setIsInstagramPostPopupOpen(false);
          }}
        />
      )}

      {isInstagramReelPopupOpen && (
        <ContentParameters
          setContentDataMap={setInstagramReelData}
          contentDataMap={instagramReelData}
          isOpen={isInstagramReelPopupOpen}
          integrations={autopilotDataMap.get("integrations")}
          handleClose={() => {
            setIsInstagramReelPopupOpen(false);
          }}
        />
      )}
      {isUgcPopupOpen && (
        <ContentParameters
          setContentDataMap={setUgcData}
          contentDataMap={ugcData}
          isOpen={isUgcPopupOpen}
          integrations={autopilotDataMap.get("integrations")}
          handleClose={() => {
            setIsUgcPopupOpen(false);
          }}
        />
      )}
      {isTiktokVideoPopupOpen && (
        <ContentParameters
          setContentDataMap={setTiktokVideoData}
          contentDataMap={tiktokVideoData}
          isOpen={isTiktokVideoPopupOpen}
          integrations={autopilotDataMap.get("integrations")}
          handleClose={() => {
            setIsTiktokVideoPopupOpen(false);
          }}
        />
      )}

      <Box borderTop="1.3px solid #E2E8F0" width="100%" mt="7.5%" />

      <Flex justifyContent="flex-end" width="100%" p={4} mt="1.5%">
        <Button
          isDisabled={isButtonDisabled()}
          onClick={handleClick}
          backgroundColor="black"
          width="150px"
          p="10px 15px"
          borderRadius="10px"
          transition="transform 0.2s, box-shadow 0.2s"
          transform="translateY(0)"
          _hover={{
            transform: "scale(1.05)",
          }}
          _active={{}}
        >
          <Text
            fontWeight="500"
            fontSize="15px"
            textAlign="center"
            color="white"
          >
            Next
          </Text>
        </Button>
      </Flex>
    </Flex>
  );
}

export default ContentType;
