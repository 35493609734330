import { Flex, Spinner, Text, Button } from "@chakra-ui/react";
import Card from "components/card/Card";
import { LocationsDataContext } from "contexts/locationsDataContext"; // Assuming you have a similar context for locations
import { useContext, useEffect, useState } from "react";
import LocationCard from "./LocationCard"; // Importing LocationCard instead of OfferCard
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useHistory } from 'react-router-dom';
import AddLocationButton from "../../locations/components/AddLocationButton";

export default function LocationsPreview(props: {
  numLocations: number;
  [x: string]: any;
  clientID: string
}) {
  const history = useHistory();
  const { numLocations, clientID, ...rest } = props;
  const locationsData = useContext(LocationsDataContext);


  if (locationsData?.isLoading) {
    return (
      <Card flexDirection="column" mt="25px">
        <Flex justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
        </Flex>
      </Card>
    );
  }


  // filter out locationData that doesn't match the clientID
  const filteredLocationsData = Object.values(locationsData?.data).filter((location: any) => location.clientID === clientID);

  const limitedLocationsData = Object.values(filteredLocationsData).slice(0, numLocations);

  return (
    <Card
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="column"
      w="100%"
      h="100%"
      mb="0px"
      mt={"20px"}
      {...rest}
    >
      <Flex justifyContent="space-between" alignItems="center" w="100%" pe="20px" pt="5px" mb="15px">
        <Text
          color={"black"}
          fontSize="24px"
          textAlign="start"
          fontWeight="700"
          lineHeight="100%"
        >
          Locations
        </Text>
        <Button
          backgroundColor="black"
          borderRadius="10px"
          transition="transform 0.2s, box-shadow 0.2s"
          transform="translateY(0)"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          _hover={{
            transform: "scale(1.05)",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          _focus={{
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          _active={{
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
          onClick={() => {
            history.push(
              "/admin/view-all-locations/?accountId=" + clientID
            );

          }}
          rightIcon={<ChevronRightIcon color="white" fontSize="20px" />}
        >
          <Text color="white" fontSize="15px" fontWeight="500">
            All locations
          </Text>
        </Button>
      </Flex>
      {
        filteredLocationsData.length === 0 ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            w="100%"
            h="100%" // or use a specific value depending on your needs
            pe="20px"
            pt="5px"
            mb="15px"
          >
            <Text pb="3%">Add your first location!</Text>
            <AddLocationButton clientID={clientID} />
          </Flex>
        ) : (
          limitedLocationsData.map((location: any, index: number) => {
            return <LocationCard key={index} location={location} clientID={clientID} />;
          })
        )
      }
    </Card>
  );
}
