import { useContext } from "react";
import {
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Button,
  Flex,
  Th,
  Td,
  Text,
  Box,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { CustomerGroupsContext } from "contexts/customerGroupsDataContext";
import { AccountsDataContext } from "../../../../contexts/accountsDataContext";
import { FaEdit } from "react-icons/fa";

interface CustomerGroupsTableProps {
  isPreview: boolean;
  clientID: string;
}

function CustomerGroupsTable({
  isPreview,
  clientID,
}: CustomerGroupsTableProps) {

  const history = useHistory();

  const customerGroups = useContext(CustomerGroupsContext);

  const customerGroupsData = customerGroups?.data;

  const filteredCustomerGroups: Record<string, any> = {};

  // Loop through the customer groups and add the matching ones to the new object
  Object.keys(customerGroupsData).forEach((key: string) => {
    const group = customerGroupsData[key];
    if (group.clientID === clientID || clientID === "" || clientID === "allAcountsID") {
      filteredCustomerGroups[key] = group;
    }
  });


  const customerGroupsDataList = isPreview
    ? Object.values(filteredCustomerGroups).slice(0, 3)
    : Object.values(filteredCustomerGroups);


  const accountData = useContext(AccountsDataContext);

  return (
    <TableContainer w="100%" >
      <Table variant="simple" colorScheme="green">
        <TableCaption>Active Customer Groups are Listed Above</TableCaption>
        <Thead>
          <Tr>
            <Th>Account</Th>
            <Th>Group Name</Th>
            <Th># Customers</Th>
            <Th>Reach</Th>
            <Th>Posts</Th>
            {!isPreview && <Th>Edit</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {customerGroupsDataList.map((group: any, idx: number) => (
            <Tr key={idx}>
              <Td>{accountData[group?.clientID].accountName ?? "N/A"}</Td>
              <Td>{group?.customerGroupName ?? "N/A"}</Td>
              <Td>{group?.users.length ?? "N/A"}</Td>
              <Td>{group?.stats?.reach ?? "N/A"}</Td>
              <Td>{group?.stats?.posts ?? "N/A"}</Td>
              {!isPreview && (
                <Td>
                  <Box
                    as="div"
                    cursor="pointer"
                    onClick={() => {
                      history.push(
                        "/admin/community/create-customer-group?accountId" +
                        clientID +
                        "&customerGroupId=" +
                        group?.id
                      );
                    }}
                    display="inline-block" // Ensures the icon doesn't take full width
                  >
                    <FaEdit color="black" size="20px" />
                  </Box>
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default CustomerGroupsTable;
