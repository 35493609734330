/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useState } from "react";
import { Text, Flex, Box, Checkbox, Image, Link, Button, Spinner } from "@chakra-ui/react";
import { AccountsDataContext } from "contexts/accountsDataContext";
import { CustomersDataContext } from "contexts/customersDataContext";

interface influencerGroupsProps {
    inputValues: {
        privateOfferPhoneList: any[];
        influencerPhoneNumbers: any[];
        quantity: number
        minimumFollowerCount: number
    };
    onInputChange: (fieldName: keyof influencerGroupsProps["inputValues"], value: any) => void;
    data: any
}

const InfluencersGroups: React.FC<influencerGroupsProps> = ({ inputValues, onInputChange, data: additionalData }) => {

    let allInfluencers: any[] = [];
    const { clientID, isEditing, offerDetail } = additionalData;
    const [allInfluencerSelected, setAllInfluencerSelected] = useState(false);


    const accountsData = useContext(AccountsDataContext);
    const { data: customersData, isLoading: isCustomerLoading } = useContext(CustomersDataContext);
    const filteredCustomersData = customersData[clientID] ?? [];

    const { isLoading, data: accountData } = accountsData[clientID];

    // Effect to check if influencer selected
    useEffect(() => {
        if (inputValues.influencerPhoneNumbers?.length) {
            onInputChange("quantity", inputValues?.influencerPhoneNumbers.length);
        }
    }, [inputValues?.influencerPhoneNumbers]);

    // Effect to check if all locations are selected
    useEffect(() => {
        if (allInfluencers && inputValues?.influencerPhoneNumbers?.length === allInfluencers?.length) {
            setAllInfluencerSelected(true);
        } else {
            setAllInfluencerSelected(false);
        }
    }, [allInfluencers, inputValues?.influencerPhoneNumbers]);
    const handleInfluencerCheckboxChange = (
        phoneNumber: string,
        isChecked: boolean
    ) => {
        let updatedInfluencerPhoneNumbers;
        if (isChecked) {
            // Add the phone number if checked
            updatedInfluencerPhoneNumbers = [...inputValues?.influencerPhoneNumbers, phoneNumber];
        } else {
            // Remove the phone number if unchecked
            updatedInfluencerPhoneNumbers = inputValues?.influencerPhoneNumbers?.filter(
                (item: string) => item !== phoneNumber
            );
        }
        onInputChange("influencerPhoneNumbers", updatedInfluencerPhoneNumbers);
        onInputChange("privateOfferPhoneList", updatedInfluencerPhoneNumbers)
    };

    const customerMap = new Map();

    Object?.keys(filteredCustomersData)?.filter((key) => key !== "id")?.forEach((phoneNumber) => {
        const customer = filteredCustomersData[phoneNumber];
        if (!customer.isInfluencer) {
            return; // Skip this customer if not an influencer when in influencer mode
        }
        if (customerMap?.has(phoneNumber)) {
            const existingCustomer = customerMap?.get(phoneNumber);
            existingCustomer.acceptedOffers = Array?.from(
                new Set([
                    ...(existingCustomer?.acceptedOffers ?? []),
                    ...(customer?.acceptedOffers ?? []),
                ])
            );
            existingCustomer.postedOffers = Array.from(
                new Set([
                    ...(existingCustomer?.postedOffers ?? []),
                    ...(customer?.postedOffers ?? []),
                ])
            );
            existingCustomer.redeemedOffers = Array.from(
                new Set([
                    ...(existingCustomer?.redeemedOffers ?? []),
                    ...(customer?.redeemedOffers ?? []),
                ])
            );
        } else {
            customerMap?.set(phoneNumber, { ...customer, "phoneNumber": phoneNumber });
            allInfluencers.push(phoneNumber);
        }
    });

    // Sort by number of posts first, then by number of followers
    const customers = Array?.from(customerMap?.values())
        .sort((a, b) => {
            const redeemedOffersDiff =
                b?.redeemedOffers.length - a?.redeemedOffers.length;
            if (redeemedOffersDiff !== 0) return redeemedOffersDiff;

            const postedOffersDiff: any = b?.postedOffers.length - a?.postedOffers.length;
            if (postedOffersDiff !== 0) return postedOffersDiff;

            const acceptedOffersDiff =
                b?.acceptedOffers.length;
            if (acceptedOffersDiff !== 0) return acceptedOffersDiff;

            return b?.instagramFollowerCount - a?.instagramFollowerCount;
        });


    // check if data is loading or not
    if (isLoading || isCustomerLoading) {
        return (
            <Box>
                <Flex justifyContent="center" alignItems="center" height="200px">
                    <Spinner size="xl" />
                </Flex>
            </Box>
        );
    }

    const toggleSelectAll = () => {
        if (allInfluencerSelected) {
            onInputChange("influencerPhoneNumbers", []);
            onInputChange("privateOfferPhoneList", [])
            onInputChange("quantity", 0);
        } else {
            const allInfluencerPhoneNumbers = customers.map(customer => customer.phoneNumber);
            // onInputChange("locations", influencerPhoneNumbers);
            onInputChange("influencerPhoneNumbers", allInfluencerPhoneNumbers);
            onInputChange("privateOfferPhoneList", allInfluencerPhoneNumbers)
            onInputChange("quantity", allInfluencerPhoneNumbers?.length);
        }
    };

    return (
        <Box bg="#FCFCFC" py="60px">
            <Box w='700px' pt="25px" pb="61px" pl="20px" pr="39px" bg="white" borderRadius="16px">
                <Flex mb="6px" justifyContent={'flex-end'}>
                    {
                        !isEditing &&
                        <Button
                            variant="chakra_btn_white"
                            size="chakra_md"
                            border={'1px solid #EFEFEF'}
                            onClick={toggleSelectAll}
                        >
                            {inputValues?.influencerPhoneNumbers.length === allInfluencers?.length
                                ? "Deselect All"
                                : "Select All"}
                        </Button>
                    }
                </Flex>
                {customers?.map((customer, index) => (
                    <Box key={index} w="100%" borderBottom='1px' borderColor={'#EFEFEF'} py="24px" pl="36px" pr="0">
                        <Flex justifyContent={'space-between'} alignItems={'center'} gap={'20px'}>
                            <Flex gap="14px" w="100%" alignItems={'center'}>
                                <Image
                                    boxSize='72px'
                                    borderRadius='full'
                                    objectFit='cover'
                                    // name={customer?.firstName}
                                    src={customer?.profilePicURL}
                                    alt='Dan Abramov'
                                />
                                <Box display={'flex'} flexDirection={'column'} gap={'5px'} alignItems={'center'}>
                                    <Text fontSize="chakra_xl" fontWeight="700" color={'#1A1D1F'}> {customer.firstName === ""
                                        ? customer.phoneNumber
                                        : customer.firstName}</Text>
                                    <Box display={'flex'} alignItems={'flex-start'} gap="11px" w="100%">
                                        <Link>
                                            <Image
                                                width={'18px'}
                                                height={'18px'}
                                                objectFit='cover'
                                                cursor={"default"}
                                                src='/instagram.png'
                                                alt='instagarm'
                                            />
                                        </Link>
                                        <Link>
                                            <Image
                                                width={'16px'}
                                                height={'18px'}
                                                objectFit='cover'
                                                cursor={"default"}

                                                src='/tiktok.png'
                                                alt='tiktok'
                                            />
                                        </Link>
                                    </Box>
                                </Box>
                            </Flex>
                            <Checkbox variant="chakra_checkbox_dark_sm" size="checkbox_sm"
                                isChecked={inputValues?.influencerPhoneNumbers?.includes(customer.phoneNumber)}
                                onChange={() => {
                                    handleInfluencerCheckboxChange(customer?.phoneNumber, !(inputValues?.influencerPhoneNumbers?.includes(customer?.phoneNumber)));
                                }}
                                isDisabled={isEditing && offerDetail?.influencerPhoneNumbers?.includes(customer?.phoneNumber)}
                            ></Checkbox>
                        </Flex>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export default InfluencersGroups;
