import { Box, Flex, Text, IconButton } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import React, { useState, useContext, Dispatch, SetStateAction, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";

interface DropdownMenuProps {
  clientIDs: string[]; // Define the type of clientIDs here
  setfilteredClientID: Dispatch<SetStateAction<string>>;
  isCommunity: boolean;
  filteredClientID: string;
  allAccounts: any;
}

const CommunityDropdownMenu: React.FC<DropdownMenuProps> = ({
  clientIDs,
  setfilteredClientID,
  isCommunity,
  filteredClientID,
  allAccounts
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const defaultAccountKey = Object.keys(allAccounts)?.[0]
  const [filteredName, setFilteredName] = useState(
    !filteredClientID ? allAccounts[defaultAccountKey]?.["accountName"] : allAccounts[filteredClientID]?.["accountName"]
  );

  const history = useHistory();


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  useEffect(() => {
    if (!filteredClientID) {
      console.log("defaultAccountKey==", defaultAccountKey);
      setfilteredClientID(defaultAccountKey);
      history.push("/admin/community/?accountId=" + defaultAccountKey)
    } else {
      setfilteredClientID(filteredClientID);
      history.push("/admin/community/?accountId=" + filteredClientID)
    }
  }, [filteredClientID]);

  const toggleFilteredName = (filteredName: string) => {
    setFilteredName(filteredName);
  };




  function handleCommunityClick(clientID: string) {
    if (clientID !== "allAccountsID") {
      history.push("/admin/community/?accountId=" + clientID);
    } else {
      history.push("/admin/community/");
    }
  }

  const clientIDsModified =
    isCommunity ? [...clientIDs, "allAccountsID"] : [];

  const dropdownRef = useRef<HTMLDivElement>(null);

  const closeDropdown = (e) => {
    if (!dropdownRef.current || dropdownRef.current !== e.target && isOpen) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => document.removeEventListener("click", closeDropdown);
  }, [isOpen]);


  return (
    <Box position="relative" mr="18px" pl={"2"}>
      <Flex
        align="center"
        p="3"
        bg="#F4F4F4"
        borderRadius="10px"
        cursor="pointer"
        onClick={toggleDropdown}
        ref={dropdownRef}
      >
        <Text fontSize="15px" color="black" fontWeight="400" width={200}>
          {filteredName}
        </Text>
        {isOpen ? (
          <ChevronDownIcon ml={10} w="5" h="5" color="grey" />
        ) : (
          <ChevronUpIcon ml={10} w="5" h="5" color="grey" />
        )}
      </Flex>

      {isOpen && (
        <Box
          position="absolute"
          top="100%"
          bg="#F4F4F4"
          boxShadow="sm"
          borderRadius="10px"
          mt="2"
          zIndex="2"
          maxHeight={190}
          overflow={"auto"}
          width={284}
        >
          <Flex direction="column" p="2">
            {clientIDsModified.map((clientID, index) => (
              <Box
                cursor="pointer"
                background={filteredClientID === clientID ? "#a9ff5a" : ""}
                key={index}
                p="2"
                borderRadius="10px"
                _hover={{ bg: filteredClientID === clientID ? "#90F831" : "gray.200" }}
                onClick={() => {
                  if (isCommunity) {
                    if (clientID !== "allAccountsID") {
                      handleCommunityClick(clientID);
                      setfilteredClientID(allAccounts[clientID]["clientID"]);
                      toggleDropdown();
                      toggleFilteredName(allAccounts[clientID]["accountName"]);
                    }
                  }
                }}
              >
                <Text>
                  {
                    allAccounts[clientID]?.["accountName"]
                  }
                </Text>
              </Box>
            ))}
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default CommunityDropdownMenu;
