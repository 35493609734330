export const linkStyles = {
	components: {
		Link: {
			baseStyle: {
				textDecoration: 'none',
				boxShadow: 'none',
				_focus: {
					boxShadow: 'none'
				},
				_active: {
					boxShadow: 'none',
					color: '#1A1D1F',
				},
				_hover: {
					textDecoration: 'none',
					border: 'none',
					color: '#1A1D1F',
				}
			},
			_hover: {
				textDecoration: 'none',
				border: 'none',
				color: '#1A1D1F',
			}
		}
	}
};
