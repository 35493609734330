import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import ImageGallery from "views/admin/ugcLibrary/components/UGCGallery";
import { ChevronRightIcon } from "@chakra-ui/icons";

export default function UgcPreviewCard(props: {
  clientIDs: string[];
  filteredClientID?: string;
  filteredLocationID?: string;
  maxRows: number;
  [x: string]: any;
}) {
  const { clientIDs, filteredClientID, filteredLocationID, maxRows } = props;

  const textColor = useColorModeValue("black", "white");

  const history = useHistory();

  return (
    <Card
      mt="20px"
      justifyContent="start"
      alignItems="center"
      flexDirection="column"
      w="100%"
      mb="0px"
      h="100%"
      p={4} // Add some padding to the Card
      overflow="auto" // Add overflow property to the Card
    >
      <Flex justifyContent="space-between" w="100%" pr="20px" pt="5px" mb="2%">
        <Text
          color={textColor}
          fontSize="24px"
          textAlign="start"
          fontWeight="700"
          lineHeight="100%"
          p={"8px"}
        >
          UGC
        </Text>

        <Button
          bg="white"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          borderRadius="7px"
          p={"8px"}
          onClick={() => {
            history.push("/admin/ugc-library");
          }}
        >
          <Text>View All</Text>
        </Button>
      </Flex>
      <Box w="100%">
        {" "}
        {/* Wrapping ImageGallery with a Box to control its width */}
        <ImageGallery
          clientIDs={clientIDs}
          columns={3}
          maxRows={1}
          filteredClientID={filteredClientID}
          filteredLocationID={filteredLocationID}
          p="10px"
        />
      </Box>
    </Card>
  );
}
