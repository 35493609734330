/* eslint-disable */

import { NavLink, useLocation } from 'react-router-dom';
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from '@chakra-ui/react';

export function SidebarLinks(props: {
	routes: RoutesType[];
}) {
	//   Chakra color mode
	let location = useLocation();
	let activeColor = useColorModeValue('black', 'white');
	let inactiveColor = useColorModeValue('#8C8E95', 'secondaryGray.600');
	let activeIcon = useColorModeValue('black', 'white');
	let textColor = useColorModeValue('#8C8E95', 'white');

	const { routes } = props;

	// verifies if routeName is the one active (in browser input)
	const activeRoute = (routeName: string) => {
		return location.pathname.includes(routeName);
	};

	// this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
	const createLinks = (
		routes: RoutesType[], 
	) => {
		return routes.map(
			(
				route: RoutesType,
				index: number
			) => {
				if (route.layout === '/admin' || route.layout === '/auth') {

					const isActiveRoute = activeRoute(route.path.toLowerCase());
					const paddingRight = isActiveRoute ? '20px' : '0px'; // Add padding on the right side

		  
					return (
						<NavLink key={index} to={route.layout + route.path}>
							{route.icon ? (
								<Box
								backgroundColor = {isActiveRoute ? '#90F831' : 'transparent'}
								borderRadius={'12px'}
								mr={paddingRight} 
								>
									<HStack
										spacing={isActiveRoute ? '22px' : '26px'}
										py='15px'
										ps='10px'>
										<Flex w='100%' alignItems='center' justifyContent='center'>
											<Box
												color={isActiveRoute ? activeIcon : textColor}
												me='18px'>
												
												{route.icon}
											</Box>
											<Text
												me='auto'
												color={isActiveRoute ? activeColor : textColor}
												fontWeight={isActiveRoute? 'bold' : 'normal'}
												>
												{route.name}
											</Text>
										</Flex>
										{/* <Box
											h='36px'
											w='4px'
											bg={isActiveRoute ? '#90F831' : 'transparent'}
											borderRadius='5px'
										/> */}
									</HStack>
								</Box>
							) : (
								<Box>
									<HStack
										spacing={isActiveRoute ? '22px' : '26px'}
										py='5px'
										ps='10px'>
										<Text
											me='auto'
											color={isActiveRoute ? activeColor : inactiveColor}
											fontWeight={isActiveRoute ? 'bold' : 'normal'}>
											{route.name}
										</Text>
										<Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
									</HStack>
								</Box>
							)}
						</NavLink>
					);
				}
			}
		);
	};
	return <>{createLinks(routes)}</>
}

export default SidebarLinks;
