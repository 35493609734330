import React, { createContext, useState, useEffect } from 'react';
import { collection, onSnapshot, getDoc, doc } from 'firebase/firestore'; // Assuming you're using Firestore
import { firestore } from '../firebase';

export const LocationsDataContext = createContext();

export const LocationsDataProvider = ({ children, locationIds }) => {
    const [locationsData, setLocationsData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let unsubscribeLocations; // Declare unsubscribe function outside

        if (locationIds && locationIds.length > 0) {
            unsubscribeLocations = onSnapshot(
                collection(firestore, 'locations'), // Listen to the entire 'locations' collection
                (querySnapshot) => {
                    const fetchedLocationsData = {};
                    querySnapshot.forEach((docSnap) => {
                        const locationId = docSnap.id;
                        if (locationIds.includes(locationId)) { // Filter based on locationIds
                            const location = docSnap.data();
                            fetchedLocationsData[locationId] = { id: docSnap.id, ...location };
                        }
                    });
                    setLocationsData(fetchedLocationsData);
                    setIsLoading(false);
                },
                (error) => {
                    console.error("Error fetching locations data:", error);
                    setIsLoading(false); // Set loading to false even on error
                }
            );
        } else {
            setIsLoading(false); // Set loading to false if no locationIds are provided
        }

        return () => {
            if (unsubscribeLocations) {
                unsubscribeLocations(); // Unsubscribe on unmount
            }
        };
    }, [locationIds, firestore]); // Re-run useEffect when locationIds or firestore changes

    // Function to handle location data changes specifically for a single location
    const handleLocationChange = async (locationId) => {
        try {
            const docRef = doc(firestore, 'locations', locationId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const location = docSnap.data();
                setLocationsData((prevData) => ({
                    ...prevData,
                    [locationId]: { id: docSnap.id, ...location },
                }));
            } else {
                // Handle case where location is deleted (optional)
                // You can remove the location from fetchedLocationsData here
                console.warn(`Location with ID ${locationId} not found in Firestore.`);
            }
        } catch (error) {
            console.error("Error fetching location data:", error);
        }
    };

    // Optional function to allow manual fetching of location data
    const fetchLocationData = async (locationId) => {
        if (!locationId) {
            return;
        }
        setIsLoading(true); // Set loading state for manual fetch
        try {
            await handleLocationChange(locationId);
        } finally {
            setIsLoading(false); // Reset loading state after fetch
        }
    };

    return (
        <LocationsDataContext.Provider
            value={{ isLoading, data: locationsData, fetchLocationData }}
        >
            {children}
        </LocationsDataContext.Provider>
    );
};

export default LocationsDataProvider;
