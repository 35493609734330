import React, { useContext, useState } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Progress,
  Flex,
} from '@chakra-ui/react';
import { CollaborationsDataContext } from 'contexts/collaborationsDataContext';


interface InfluencerCampaign {
  id: string
  clientID: string,
  name: string,
  description: string,
  deliverables: any,
  rewardType: string,
  influencers: string[]
}

interface ActiveCollaborationsTableProps {
  influencerCampaigns: InfluencerCampaign[];
  onCampaignClick: (campaignId: string) => void;
}

interface CollaborationsData {
  id: string;
  influencerID: string;
  influencerCampaignID: string;
  compensation: number;
  rewardType: string;
  deliverableCounts: {
    instagramPosts: number;
    instagramReels: number;
    instagramStories: number;
    tiktoks: number;
  };
  deliverableLinks: any;
  offerRedeemedDate: Date;
  logs: any;
  status: string;
}

interface SelectedCampaign extends InfluencerCampaign {
  collaborations: CollaborationsData[];
}

export const getTotalActiveCreators = (collaborations: Record<string, CollaborationsData>, influencerCampaigns: InfluencerCampaign[]) => {
  const activeCreators = new Set<string>();

  influencerCampaigns.forEach(campaign => {
    Object.values(collaborations).forEach(collab => {
      if (collab.influencerCampaignID === campaign.id) {
        activeCreators.add(collab.influencerID);
      }
    });
  });

  return activeCreators.size;
};

const ActiveCollaborationsTable: React.FC<ActiveCollaborationsTableProps> = ({influencerCampaigns, onCampaignClick}) => {
  const collaborations = useContext(CollaborationsDataContext) as Record<string, CollaborationsData>;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<SelectedCampaign | null>(null);

  console.log('Collaborations data:', collaborations);

  const getActiveCreators = (campaignId: string) => {
    if (!collaborations) return 0;
    const activeInfluencers = new Set(
      Object.values(collaborations)
        .filter(collab => collab.influencerCampaignID === campaignId)
        .map(collab => collab.influencerID)
    );
    return activeInfluencers.size;
  };

  const calculateProgress = (campaignId: string) => {
    if (!collaborations) return 0;
    const campaignCollaborations = Object.values(collaborations).filter(
      collab => collab.influencerCampaignID === campaignId
    );
    const completedCollaborations = campaignCollaborations.filter(
      collab => ['collabCompleted'].includes(collab.status)
    );
    return campaignCollaborations.length > 0
      ? Math.round((completedCollaborations.length / campaignCollaborations.length) * 100)
      : 0;
  };

  const getTotalPosts = (campaignId: string) => {
    if (!collaborations) return 0;
    const relevantCollaborations = Object.values(collaborations).filter(
      collab => collab.influencerCampaignID === campaignId &&
      ['collabCompleted'].includes(collab.status)
    );
    return relevantCollaborations.reduce((total, collab) => {
      const { instagramPosts, instagramReels, instagramStories, tiktoks } = collab.deliverableCounts;
      return total + instagramPosts + instagramReels + instagramStories + tiktoks;
    }, 0);
  };

  const handleRowClick = (campaign: InfluencerCampaign) => {
    onCampaignClick(campaign.id);
  };

  return (
    <Box bg="white" pt={3} borderRadius="md" boxShadow="md" maxWidth="90%" mx="auto" ml={4}>
      <Text p={3} fontSize="xl" fontWeight="bold">
        Active Campaigns
      </Text>
      <TableContainer maxHeight="200px" overflowY="auto">
        <Table variant="simple">
          <Thead bg="gray.300" position="sticky" top={0} zIndex={1}>
            <Tr>
              <Th>Campaign Name</Th>
              <Th>Total Posts</Th>
              <Th>Creators</Th>
              <Th>Progress</Th>
            </Tr>
          </Thead>
          <Tbody>
          {influencerCampaigns.length > 0 ? influencerCampaigns.map((influencerCampaign) => (
            <Tr 
              key={influencerCampaign.id} 
              onClick={() => handleRowClick(influencerCampaign)}
              cursor="pointer"
              _hover={{ bg: "gray.100" }}
            >
              <Td>{influencerCampaign.name}</Td>
              <Td>{getTotalPosts(influencerCampaign.id)}</Td>
              <Td>{getActiveCreators(influencerCampaign.id)}</Td>
              <Td>
                <Flex alignItems="center">
                  <Text mr={2}>{calculateProgress(influencerCampaign.id)}%</Text>
                  <Progress 
                    value={calculateProgress(influencerCampaign.id)} 
                    size="sm" 
                    colorScheme="green" 
                    width="100px"
                    bg="gray.300"  // Slightly lighter grey background
                  />
                </Flex>
              </Td>
            </Tr>
          )) : (
            <>
              <Tr>
                <Td colSpan={4} textAlign="center">Active campaigns will show up here</Td>
              </Tr>
              <Tr>
                <Td colSpan={4} textAlign="center"></Td>
              </Tr>
            </>
          )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ActiveCollaborationsTable;