import React from "react";
import { Text, Flex, Box } from "@chakra-ui/react";
import OfferSummary from "../../accounts/pages/offers/components/OfferSummary";

interface ContentSummaryProps {
  contentData: Map<string, any>;
  clientInstagramHandle: string;
}

function ContentSummary({
  contentData,
  clientInstagramHandle,
}: ContentSummaryProps) {
  return (
    <Flex justifyContent="start" alignItems="start" flexDirection="row" mt="5%">
      <Flex alignItems="center" width="25%">
        <Text fontSize="18px" fontWeight="400">
          {contentData.get("contentType") === "instagramStory"
            ? "Instagram Story"
            : contentData.get("contentType") === "instagramPost"
            ? "Instagram Post"
            : contentData.get("contentType") === "instagramReel"
            ? "Instagram Reel"
            : contentData.get("contentType") === "ugc"
            ? "UGC"
            : "Tiktok Video"}{" "}
        </Text>
      </Flex>

      <Box backgroundColor="#D4FEAE" borderRadius="lg" p="3%" width="60%">
        <Text
          align="center"
          fontWeight="500"
          fontSize="17px"
          lineHeight="taller"
        >
          Customers will be re-engaged to post an{" "}
          {contentData.get("contentType") === "instagramStory"
            ? "Instagram Story"
            : contentData.get("contentType") === "instagramPost"
            ? "Instagram Post"
            : contentData.get("contentType") === "instagramReel"
            ? "Instagram Reel"
            : contentData.get("contentType") === "ugc"
            ? "UGC"
            : "Tiktok Video"}{" "}
          and tag @{clientInstagramHandle} to earn between $
          {contentData.get("min$")}-${contentData.get("max$")} or{" "}
          {contentData.get("min%")}%-{contentData.get("max%")}% off their total
          order.
        </Text>
      </Box>
    </Flex>
  );
}

export default ContentSummary;
