import React, { useContext, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  useBreakpointValue
} from "@chakra-ui/react";
import { useHistory, Route, Switch, useRouteMatch, useParams } from 'react-router-dom';
import WelcomeBanner from "./components/WelcomeBanner";
import ActiveCollaborationsTable from './components/ActiveCollaborationsTable';
import FeaturedInfluencers from "./components/FeaturedInfluencers";
import CreateCampaignModal from './components/CreateCampaignModal';
import Search from './pages/search';
import ActiveCampaign from './components/ActiveCampaign';
import { InfluencerCampaignsDataContext } from "contexts/influencerCampaignsDataContext";
import { InfluencersDataContext } from "contexts/influencersDataContext";
import AccountsDropdown from "./components/accountsDropDown";
import { AccountsDataContext } from "contexts/accountsDataContext";
import { CollaborationsDataProvider, CollaborationsDataContext } from 'contexts/collaborationsDataContext';

interface Influencer {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  email_address: string;
  instagramHandle: string;
  tiktokHandle: string;
  state: string;
  city: string;
  profilePic: any;
  profile_pic_url: string;
  follower_count: number;
  featured: boolean;
}

interface InfluencerCampaign {
  id: string
  clientID: string,
  name: string,
  description: string,
  deliverables: any,
  rewardType: string,
  influencers: string[]
}

interface CollaborationsData {
  id: string;
  influencerID: string;
  influencerCampaignID: string;
  compensation: number;
  rewardType: string;
  deliverableCounts: any;
  deliverableLinks: any;
  offerRedeemedDate: Date;
  logs: any;
  status: any;
}

export default function FoodFluence() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const influencerData: Record<string, Influencer> = useContext(InfluencersDataContext);
  const [filteredClientID, setFilteredClientID] = useState<string>("");

  const influencerCampaigns: Record<string, InfluencerCampaign> = useContext(InfluencerCampaignsDataContext);
  const filteredCampaigns = Object.values(influencerCampaigns ?? {}).filter(campaign => campaign.clientID === filteredClientID) ?? [];

  const [selectedAccountName, setSelectedAccountName] = useState<string>("");

  const accountsData = useContext(AccountsDataContext);
  const filteredAccountsData = accountsData[filteredClientID] ?? {};

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSearchClick = () => {
    history.push(`${path}/search?clientID=${filteredClientID}`);
  };

  const handleCampaignClick = (campaignId: string) => {
    history.push(`${path}/campaign/${campaignId}`);
  };

  let clientAccounts = [];
  for (let account in accountsData) {
    if (account && accountsData[account]) {
      clientAccounts.push({
        ...accountsData[account],
      });
    }
  }
  const handleAccountNameChange = (name: string) => {
    setSelectedAccountName(name);
  };

  const buttonSize = useBreakpointValue({ base: "md", md: "lg" });

  const FoodFluenceMain = () => (
    <CollaborationsDataProvider>
      <Box>
        <Box
          backgroundColor="white"
          borderRadius="10px"
          minHeight="calc(100vh - 135px)"
          position="relative"
          display="flex"
          flexDirection="column"
          p={{ base: 2, md: 3 }}
        >
          <Flex justifyContent="space-between" alignItems="flex-start" mb={4}>
            <Box flex="1" ml = {-0} width="auto">
              <WelcomeBanner onStartNewCollab={handleOpenModal} clientID={filteredClientID} accountName={selectedAccountName} />
            </Box>
            {accountsData && Object.keys(accountsData)?.length > 0 && (
              <Box width={{ base: "100%", md: "auto" }} mt={{ base: 1, md: 0 }}>
                <AccountsDropdown
                  filteredClientId={filteredClientID}
                  clientAccounts={clientAccounts}
                  setFilteredClientId={setFilteredClientID}
                  setSelectedAccountName={handleAccountNameChange}
                  selectedAccountName={selectedAccountName}
                />
              </Box>
            )}
          </Flex>
          
          <Box mt={-1} pl="5%" width="100%">
            <ActiveCollaborationsTable 
              influencerCampaigns={filteredCampaigns}
              onCampaignClick={handleCampaignClick}
            />
          </Box>
          <Flex justifyContent="flex-start" alignItems="center" mt={4}>
            <Text fontSize="2xl" fontWeight={"bold"} ml={20}>
              Featured Influencers:
            </Text>
            <Button
              bg="#4CAF50"
              color="white"
              borderRadius="full"
              fontWeight="bold"
              onClick={() => handleSearchClick()}
              size={buttonSize}
              py={2}
              px={6}
              height="auto"
              width="auto"
              _hover={{ bg: "#45a049", transform: "translateY(-2px)" }}
              _active={{ bg: "#3d8b40" }}
              transition="all 0.2s"
              boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
              ml="6"
            >
              Search All
            </Button>
          </Flex>
          <Box pl={8}>
            <FeaturedInfluencers influencers={influencerData} />
          </Box>
        </Box>
        <CreateCampaignModal clientID={filteredClientID} client={filteredAccountsData} isOpen={isModalOpen} onClose={handleCloseModal} />
      </Box>
    </CollaborationsDataProvider>
  );

  const ActiveCampaignWrapper = () => {
    const { campaignId } = useParams<{ campaignId: string }>();
    const campaign = influencerCampaigns[campaignId];
    const collaborationsData: Record<string, CollaborationsData> = useContext(CollaborationsDataContext);
    
    if (!campaign) {
      return <div>Campaign not found</div>;
    }

    const campaignCollaborations = Object.values(collaborationsData).filter(
      collab => collab.influencerCampaignID === campaignId
    );
    const clientID = accountsData[filteredClientID]?.clientID;

    return (
      <ActiveCampaign
        campaignName={campaign.name}
        campaignId={campaignId}
        collaborations={campaignCollaborations}
        influencers={influencerData}
        clientID = {clientID}
      />
    );
  };

  return (
    <Switch>
      <Route exact path={path}>
        <FoodFluenceMain />
      </Route>
      <Route path={`${path}/search`}>
        <CollaborationsDataProvider>
          <Search />
        </CollaborationsDataProvider>
      </Route>
      <Route path={`${path}/campaign/:campaignId`}>
        <CollaborationsDataProvider>
          <ActiveCampaignWrapper />
        </CollaborationsDataProvider>
      </Route>
    </Switch>
  );
}