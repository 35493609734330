/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { Text, Flex, Box, Stack, Radio, RadioGroup } from "@chakra-ui/react";

interface OfferCompensationTypeProps {
  inputValues: {
    isCash: boolean;
    isCashBack: boolean;
    typeOfOffer: string;
    integration?: string;
    compensation: number;
    percentOrCashOff: string;
    expirationLimit: number;
    isUploadCodes: boolean;
    isEvergreenCode: boolean;
  };
  onInputChange: (
    fieldName: keyof OfferCompensationTypeProps["inputValues"],
    value: any
  ) => void;
  data: any;
}

// function OfferCompensationType() {
const OfferCompensationType: React.FC<OfferCompensationTypeProps> = ({
  inputValues,
  onInputChange,
  data: additionalData,
}) => {
  const { isEditing } = additionalData;
  const mapDefaultValue = (values: any): string => {
    return values.typeOfOffer;
  };
  const [defaultSelectRadio, setDefaultSelectedRadio] = useState<string>(
    mapDefaultValue(inputValues)
  );
  const handleOfferTypeComponsationChange = (offerType: string) => {
    if (offerType === "cash") {
      onInputChange("isCash", true);
      onInputChange("isCashBack", false);
      onInputChange("percentOrCashOff", "Cash");
      onInputChange("expirationLimit", 30);
    } else if (offerType === "cashback") {
      onInputChange("isCash", false);
      onInputChange("isCashBack", true);
      onInputChange("percentOrCashOff", "Percent");
      onInputChange("expirationLimit", 30);
    } else {
      onInputChange("isCash", false);
      onInputChange("isCashBack", false);
      onInputChange("percentOrCashOff", "");
      onInputChange("expirationLimit", 30);
    }
    // set compensation 0 when the offertype change
    onInputChange("compensation", 0);
    onInputChange("typeOfOffer", offerType);
    setDefaultSelectedRadio(offerType);
  };

  return (
    <Box py="60px">
      <RadioGroup
        value={defaultSelectRadio}
        defaultValue=""
        onChange={handleOfferTypeComponsationChange}
      >
        <Stack spacing={"60px"} direction="column">
          {!inputValues?.isUploadCodes && !inputValues?.isEvergreenCode && (
            <Box>
              <Radio
                variant="chakra_radio_light"
                size="radio_lg"
                value="cash"
              >
                <Flex flexFlow="column" ml="14px">
                  <Text fontSize="chakra_lg" fontWeight="500" color="#101828">
                    Cash
                  </Text>
                  <Text fontSize="chakra_sm" fontWeight="400" color="#667085">
                    Straight, raw, hard cash straight from you to someone who
                    completes an offer
                  </Text>
                </Flex>
              </Radio>
            </Box>
          )}
          <Box>
            <Radio
              variant="chakra_radio_light"
              size="radio_lg"
              value="credit"
            >
              <Flex flexFlow="column" ml="14px">
                <Text fontSize="chakra_lg" fontWeight="500" color="#101828">
                  Credit
                </Text>
                <Text fontSize="chakra_sm" fontWeight="400" color="#667085">
                  When completed, give a free product, cash discount, percent
                  discount, BOGO, possibilities are endless!
                </Text>
              </Flex>
            </Radio>
          </Box>
          {inputValues?.integration &&
            ["shopify"].includes(inputValues?.integration) && (
              <Box>
                <Radio
                  variant="chakra_radio_light"
                  size="radio_lg"
                  value="cashback"
                >
                  <Flex flexFlow="column" ml="14px">
                    <Text fontSize="chakra_lg" fontWeight="500" color="#101828">
                      Cashback
                    </Text>
                    <Text fontSize="chakra_sm" fontWeight="400" color="#667085">
                      Only for Shopify clients, this allows you to reward
                      customers with a cashback incentive{" "}
                    </Text>
                  </Flex>
                </Radio>
              </Box>
            )}
        </Stack>
      </RadioGroup>
    </Box>
  );
};

export default OfferCompensationType;
