import React, { useState, useEffect } from "react";
import { Box, Input, Flex, Text, Button } from "@chakra-ui/react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { EditIcon } from "@chakra-ui/icons";


interface VideoUploaderProps {
  setExampleUGC: Function;
  setIsVideoValid: Function;
  isButton: boolean;
}

const VideoUploader: React.FC<VideoUploaderProps> = ({ setExampleUGC, setIsVideoValid, isButton }) => {
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const [inputId, setInputId] = useState<string>("");


  useEffect(() => {
    // Generate a unique ID for the input element
    setInputId(`video-input-${Math.random().toString(36).substr(2, 9)}`);
  }, []);

  const handleVideoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
  
      reader.onload = () => {
        const dataURL = reader.result as string;
        setSelectedVideo(dataURL);
  
        const base64String = dataURL.split(",")[1];
  
        if (base64String) {
          const binaryString = atob(base64String);
          const arrayBuffer = new ArrayBuffer(binaryString.length);
          const uint8Array = new Uint8Array(arrayBuffer);
  
          for (let i = 0; i < binaryString.length; i++) {
            uint8Array.set([binaryString.charCodeAt(i)], i);
          }
  
          setExampleUGC(uint8Array);
        } else {
          console.error('Invalid base64 string');
        }
      };
      
      setIsVideoValid(true);
      reader.readAsDataURL(file);
    }
  };

  if (isButton) {
    return (
      <Flex direction="column" align="center" justify="center" >
        {!selectedVideo ? (
          <Button
            backgroundColor="black"
            width="150px"
            p="10px 15px"
            borderRadius="10px"
            transition="transform 0.2s, box-shadow 0.2s"
            transform="translateY(0)"
            _hover={{
              transform: "scale(1.05)",
            }}
            _active={{}}
            onClick={() => {
              document.getElementById(inputId)?.click();
            }}
          >
            <Text
              fontWeight="500"
              fontSize="15px"
              textAlign="center"
              color="white"
            >
              Upload Example
            </Text>
          </Button>
        ) : (
          // <Text>Image successfully uploaded!</Text>
          <Flex direction="column" align="center" justify="center">
                    <Box
          maxWidth="150px"
          maxHeight="300px"
          borderRadius="10px"
          //   maxHeight="300px"
          overflow="hidden"
          boxShadow="lg"
          mb={4}
        >
          {/* Display a video player here */}
          <video width="100%" height="100%" controls>
            <source src={selectedVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>

            {/* <Box
              maxWidth="150px"
              maxHeight="300px"
              borderRadius="10px"
              overflow="hidden"
              boxShadow="lg"
              mb={4}
            >
              <Image src={selectedImage} alt="Uploaded" />
            </Box> */}
            <Button
              ml="2"
              onClick={() => {
              document.getElementById("video-input")?.click();
                  }}
              size="30px"
              backgroundColor="transparent"
            >
              <EditIcon color="black" />
            </Button>
          </Flex>
        )}
      <Input
        type="file"
        accept="video/*"
        onChange={handleVideoUpload}
        id={inputId}
        display="none"
      />
      </Flex>
    );
  }


  return (
    <Flex direction="column" align="center" justify="center" ml="20%">
      {!selectedVideo ? (
        <Box
          backgroundColor="#F4F4F4"
          width="200%"
          borderWidth="2px"
          borderStyle="dashed"
          borderRadius="lg"
          textAlign="center"
          cursor="pointer"
          onClick={() => {
            const input = document.getElementById("video-input");
            input?.click();
          }}
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            height="100%"
            justifyContent="center"
            pt="5%"
            mb="5%"
          >
            <AiOutlineCloudUpload size={35} />
            <Text fontSize="lg" fontWeight="500" mt="2%" color="black">
              Click to Upload
            </Text>
            <Text fontSize="md" fontWeight="400" mt="2%" color="grey">
              Accepted formats include MP4, AVI, or MOV
            </Text>
          </Flex>
        </Box>
      ) : (
        <Box
          ml="-100%"
          minWidth="150px"
          maxWidth="250px"
          borderRadius="10px"
          //   maxHeight="300px"
          overflow="hidden"
          boxShadow="lg"
          mb={4}
        >
          {/* Display a video player here */}
          <video width="100%" height="100%" controls>
            <source src={selectedVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      )}

      <Input
        type="file"
        accept="video/*"
        onChange={handleVideoUpload}
        id="video-input"
        display="none"
      />
    </Flex>
  );
};

export default VideoUploader;
