/* eslint-disable */
import React from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  IconButton,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";

// Custom components
import { HSeparator } from "components/separator/Separator";
import AuthDecoration from "layouts/auth/decoration";
import { useHistory } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  getAuth,
} from "firebase/auth";
import { auth } from "../../../firebase";

function LoginScreen() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.500";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [email, setEmail] = React.useState("");
  const [isEmailValid, setIsEmailValid] = React.useState(true);
  const [password, setPassword] = React.useState("");
  const [isPasswordValid, setIsPasswordValid] = React.useState(true);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [resetEmail, setResetEmail] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const history = useHistory();

  const firebaseSignIn = async (email: string, password: string) => {
    var user = null;

    user = await signInWithEmailAndPassword(auth, email, password);
    return user;
  };

  const handleSignIn = async () => {
    try {
      setIsLoading(true);
      const user = await firebaseSignIn(email, password);
      console.log(user);
      if (user && user.user.emailVerified) {
        setIsLoading(false);
        history.push("/admin");
      } else {
        // setEmail("");
        // setPassword("");
        setIsLoading(false);
        auth.signOut();
        alert(
          "Failed to signin. Your email is not verified. Please check your email to verify."
        );
      }
      // console.log("is email verified----", user.user.emailVerified);
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred:", error);
      alert(
        "Failed to sign in. Please try again with a valid email and password."
      );
    } finally {
      // setIsLoading(false);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputEmail = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmail(inputEmail);
    setIsEmailValid(emailRegex.test(inputEmail));
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputPassword = e.target.value;
    setPassword(inputPassword);
    setIsPasswordValid(inputPassword.length > 7);
  };
  const handlePasswordReset = async (email: string) => {
    if (!email) {
      alert("Please enter your email address.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email sent!");
      setResetEmail("");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      alert("Failed to send password reset email.");
    }
  };

  return (
    <AuthDecoration>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
        onKeyDown={async (e) => {
          if (e.key === "Enter") {
            handleSignIn();
            e.preventDefault(); // Prevent default form submission if Enter key is pressed
          }
        }}
      >
        <Box me="auto">
          <Flex alignItems="center">
            <Heading color={textColor} fontSize="36px" mb="10px" mt="85px">
              Welcome back! 😁
            </Heading>
          </Flex>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Sign in to manage your offers
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              // borderColor="" // Change to the desired color
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              style={{ borderColor: isEmailValid ? "" : "red" }}
              type="email"
              borderRadius="13px"
              placeholder="you@email.com"
              mb="5px"
              value={email}
              fontWeight="500"
              size="lg"
              onChange={handleEmailChange}
            />
            {!isEmailValid && (
              <Text color="red" fontSize="12px" ml="10px">
                Invalid email format
              </Text>
            )}
            <FormLabel
              ms="4px"
              mt="25px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md" mb="5px">
              <Input
                style={{ borderColor: isPasswordValid ? "" : "red" }}
                isRequired={true}
                borderRadius="13px"
                fontSize="sm"
                placeholder="Min. 8 characters"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                value={password}
                onChange={handlePasswordChange}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            {!isPasswordValid && (
              <Text color="red" fontSize="12px" ml="10px">
                Min. 8 characters
              </Text>
            )}
            <Flex justifyContent={"end"} mb="30px" mt="10px">
              <Text
                color={textColor}
                fontSize="sm"
                w="124px"
                fontWeight="500"
                cursor="pointer"
                onClick={() => setIsModalOpen(true)}
              >
                Forgot password?
              </Text>
            </Flex>{" "}
            <Button
              fontSize="sm"
              bg="black"
              color="white"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              transition="transform 0.2s" // Add transition property
              isLoading={isLoading}
              _hover={
                isEmailValid && isPasswordValid
                  ? {
                      transform: "scale(1.05)",
                      cursor: "pointer", // Add cursor pointer on hover
                    }
                  : {}
              }
              isDisabled={email === "" || password.length < 8} // Use logical OR here
              onClick={handleSignIn}
            >
              Sign In
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <NavLink to="/waitlist">
              <Text color={textColorDetails} fontWeight="400" fontSize="14px">
                Don't have an account?
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Join the waitlist
                </Text>
                {/* {currentPage === 'wait-list' && history.push()} */}
              </Text>
            </NavLink>
          </Flex>
        </Flex>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Password Reset</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                placeholder="Enter your email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />
            </ModalBody>

            <ModalFooter>
              <Button
                bg="black"
                color="white"
                _hover={{ bg: "black" }} // This line ensures the background stays black on hover
                onClick={() => {
                  handlePasswordReset(resetEmail);
                  setIsModalOpen(false);
                }}
              >
                Send Reset Link
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </AuthDecoration>
  );
}
export default LoginScreen;
