import { color } from '@chakra-ui/system';
import { mode } from '@chakra-ui/theme-tools';

export const checkboxStyles = {
  components: {
    Checkbox: {
      baseStyle: {
        control: {
        },
      },
      variants: {
        chakra_checkbox_dark: {
          control: {
            bg: "#fff",
            borderColor: "rgba(111, 118, 126, 0.4)",
            borderRadius: "9px",
            _hover: {
              bg: "#fff",
              borderColor: '#000',
            },
            _checked: {
              bg: "#fff",
              borderColor: '#000',
              color: '#000',
              _hover: {
                bg: "#fff",
              },
            },
          },
        },
        chakra_checkbox_dark_sm: {
          control: {
            bg: "#fff",
            borderColor: "rgba(111, 118, 126, 0.4)",
            borderRadius: "4px",
            _hover: {
              bg: "#fff",
              borderColor: '#000',
            },
            _checked: {
              bg: "#fff",
              borderColor: '#000',
              color: '#000',
              _hover: {
                bg: "#fff",
              },
            },
          },
        },
      },
      sizes: {
        checkbox_lg: {
          control: {
            width: '33px',
            height: '33px',
          },
        },
        checkbox_sm: {
          control: {
            width: '20px',
            height: '20px',
          },
          icon:{
            width: '10px',
            height: '10px',
          },
        },
      },
    },
  },
};
