import React, { useContext } from 'react';
import { Box, Text, Flex, VStack, HStack, Icon, SimpleGrid } from '@chakra-ui/react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { InfluencerCampaignsDataContext } from 'contexts/influencerCampaignsDataContext';
import { CollaborationsDataContext } from 'contexts/collaborationsDataContext';
import { InfluencersDataContext } from 'contexts/influencersDataContext'; // Import the influencerData context

interface AnalyticsProps {
  clientID: string; // Add this prop to receive clientID
}

const Analytics: React.FC<AnalyticsProps> = ({ clientID }) => {
  const influencerCampaigns = useContext(InfluencerCampaignsDataContext) as Record<string, any>;
  const collaborations = useContext(CollaborationsDataContext) as Record<string, any>;
  const influencerData = useContext(InfluencersDataContext) as Record<string, any>; // Use the influencerData context

  const getActiveCreators = (campaignId: string) => {
    if (!collaborations) return 0;
    const activeInfluencers = new Set(
      Object.values(collaborations)
        .filter(collab => collab.influencerCampaignID === campaignId)
        .map(collab => collab.influencerID)
    );
    return activeInfluencers.size;
  };

  const getTotalPosts = (campaignId: string) => {
    if (!collaborations) return 0;
    const relevantCollaborations = Object.values(collaborations).filter(
      collab => collab.influencerCampaignID === campaignId &&
      ['collabCompleted'].includes(collab.status)
    );
    return relevantCollaborations.reduce((total, collab) => {
      const { instagramPosts, instagramReels, instagramStories, tiktoks } = collab.deliverableCounts;
      return total + instagramPosts + instagramReels + instagramStories + tiktoks;
    }, 0);
  };

  const totalCreators = Object.values(influencerCampaigns).reduce((total: number, campaign) => {
    if (campaign.clientID === clientID) { // Filter by clientID
      return total + getActiveCreators(campaign.id);
    }
    return total; // Skip if not matching
  }, 0);

  const totalPosts = Object.values(influencerCampaigns).reduce((totalPosts: number, campaign) => {
    if (campaign.clientID === clientID) { // Filter by clientID
      return totalPosts + getTotalPosts(campaign.id);
    }
    return totalPosts; // Skip if not matching
  }, 0);

  const formatFollowersCount = (followers: number): string => {
    if (followers >= 1000 && followers < 1000000) {
      return (followers / 1000).toFixed(1) + "k";
    } else if (followers >= 1000000) {
      return (followers / 1000000).toFixed(1) + "M";
    }
    return followers.toString();
  }

  const getTotalReach = () => {
    if (!collaborations) return 0;
    const relevantCollaborations = Object.values(collaborations).filter(
      collab => 
        ['collabCompleted'].includes(collab.status) && // Only completed or pending verification collaborations
        influencerCampaigns[collab.influencerCampaignID]?.clientID === clientID // Check if campaign belongs to the clientID
    );

    const influencerIds = new Set(relevantCollaborations.map(collab => collab.influencerID));
    const totalFollowers = Array.from(influencerIds).reduce((total, influencerID) => {
      const influencer = influencerData[influencerID]; // Assuming influencerData is available in context
      return total + (influencer ? influencer.follower_count : 0);
    }, 0);

    return formatFollowersCount(totalFollowers); // Return the total reach based on followers
};

  const calculateCompletionRate = () => {
    const totalCollaborations = Object.values(collaborations).filter(
      collab => influencerCampaigns[collab.influencerCampaignID]?.clientID === clientID
    ).length;
    const completedCollaborations = Object.values(collaborations).filter(
      collab => 
        ['collabCompleted'].includes(collab.status) && 
        influencerCampaigns[collab.influencerCampaignID]?.clientID === clientID
    ).length;
    return totalCollaborations > 0 ? Math.round((completedCollaborations / totalCollaborations) * 100) : 0;
  };

  const totalReach = getTotalReach();
  const completionRate = calculateCompletionRate().toString() + '%';

  const boxContents = [
    { label: 'Collabs', value: totalCreators.toString(), completed: Object.values(collaborations).filter(
      collab => 
        ['collabCompleted'].includes(collab.status) && 
        influencerCampaigns[collab.influencerCampaignID]?.clientID === clientID
    ).length.toString() }, // Modified to count completed collabs
    { label: 'Total Posts', value: totalPosts.toString(), trend: totalPosts > 0 ? 'up' : undefined, trendValue: totalPosts.toString() },
    { label: 'Estimated Reach', value: totalReach, trend: totalReach !== '0' ? 'up' : undefined, trendValue: totalReach },
    { label: 'Completion Rate', value: completionRate, trend: completionRate !== '0%' ? 'up' : undefined, trendValue: completionRate },
  ];

  return (
    <Box width="100%" maxWidth="1200px" mx="auto" px={4}>
      <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={4} mt={4}>
        {boxContents.map(({ label, value, completed, trend, trendValue }, index) => (
          <Box
            key={index}
            bg="white"
            borderRadius="md"
            boxShadow="md"
            p={4}
            position="relative"
            height={{ base: '120px', md: '140px' }}
          >
            <VStack align="flex-start" spacing={2} height="100%">
              <Text
                fontSize={{ base: 'sm', md: 'md' }}
                fontWeight="bold"
                color="gray.700"
              >
                {label}
              </Text>
              <VStack align="flex-start" spacing={0} mt="auto">
                <HStack spacing={2} alignItems="baseline">
                  <Text
                    fontSize={{ base: '2xl', md: '3xl' }}
                    fontWeight="bold"
                    color="black"
                  >
                    {value}
                  </Text>
                  <HStack spacing={1} alignItems="center">
                    {trend && (
                      <>
                        <Icon
                          as={trend === 'up' ? FaArrowUp : FaArrowDown}
                          color={trend === 'up' ? 'green.500' : 'red.500'}
                          w={{ base: 2, md: 3 }}
                          h={{ base: 2, md: 3 }}
                        />
                        <Text
                          fontSize={{ base: 'xs', md: 'sm' }}
                          fontWeight="bold"
                          color={trend === 'up' ? 'green.500' : 'red.500'}
                        >
                          {trendValue}
                        </Text>
                      </>


                    )}
                  </HStack>
                </HStack>
              </VStack>
            </VStack>
            {completed && (
              <Text 
                fontSize={{ base: 'xs', md: 'sm' }}
                color="gray.500" 
                position="absolute"
                bottom="5px"
                left="16px"
              >
                <Text as="span" fontWeight="bold">{completed}</Text> completed
              </Text>
            )}
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Analytics;