import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import BackButton from "components/buttons/BackButton";
import MetricChartCard from "components/charts/MetricChartCard";
import StatsRow from "components/stats/StatsRow";
import { AccountsDataContext } from "contexts/accountsDataContext";
import { useContext, useEffect, useState } from "react";
import {
  MdImage, MdLocationPin,
  MdOutlineBarChart, MdPeople
} from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom";
import QrCodeButton from "../locations/components/QrCodeButton";
import LocationsPreview from "./components/LocationsPreview";
import OffersPreview from "./components/OffersPreview";


export default function AccountOverview({ ...props }) {
  const history = useHistory();
  const location = useLocation();
  const [isBackButtonHovered, setIsBackButtonHovered] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const clientID = queryParams.get("accountId");
  const accountsData = useContext(AccountsDataContext);

  const [clientData, setClientData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    if (clientID && accountsData && accountsData[clientID]) {
      setClientData(accountsData[clientID]);
      setIsLoading(false);
    }
  }, [clientID, accountsData]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!clientData) {
    return <div>No data available.</div>;
  }

  const clientStats = clientData.stats;

  const timestampedData = clientStats?.timestamps ?? [];
  const chartData = [
    {
      name: "Estimated Reach",
      data:
        timestampedData == null || timestampedData.length > 0 ? [] :
          Object.keys(timestampedData)
            .map((timestamp) => ({
              // Convert timestamp to a number and create a Date object
              x: new Date(parseInt(timestamp)).getTime(),
              y: Math.ceil(timestampedData[timestamp].reach / 3) || 0 // Use 0 if reach is undefined
            }))
            // Sort the data points by the 'x' property, which is the timestamp in milliseconds
            .sort((a, b) => a.x - b.x)
    }
  ];


  const statsList = [
    {
      title: "Locations",
      value: String(clientData?.locations?.length ?? 0),
      icon: MdLocationPin,
    },
    {
      title: "Signups",
      value: String(clientStats?.signups ?? 0),
      icon: MdPeople,
    },
    {
      title: "Number of Posts",
      value: String(clientStats?.posts ?? 0),
      icon: MdImage,
    },
    {
      title: "Estimated Reach",
      value: String(Math.floor((clientStats?.storyReach || 0) / 3)),
      icon: MdOutlineBarChart,
    },
  ];

  return (
    <Box>
      <Box width="100%" backgroundColor="white" borderRadius="17px" mb="25px">
        <Flex alignItems="center" justifyContent="space-between" pr="10px">
          <Flex alignItems="center" pt="25px" pb="25px">
            <BackButton
              isHovered={isBackButtonHovered}
              setIsHovered={setIsBackButtonHovered}
            />

            <Text ml="10px" fontWeight="bold" fontSize="30px">
              {clientData.accountName}
            </Text>
          </Flex>

          <Flex alignItems="center" width="12%" justifyContent="space-between">
            <QrCodeButton clientID={clientID} locationID={""} />
            <Box
              color="red"
              mt="5px"
              mb="5px"
              mr="10px"
              display="inline-block"
              width="65px"
              height="65px"
              borderRadius="50px"
              backgroundImage={clientData.instagramProfilePicture}
              backgroundSize="cover"
            />
          </Flex>
        </Flex>
      </Box>

      <StatsRow stats={statsList}></StatsRow>
      <MetricChartCard chartData={chartData}/>
      <Flex direction={{ base: "column", md: "row" }} my="3vh" mt="-10px">
        {clientData.locationless || (
          <Box flex="1" py="3vh"  pr={{ md: "4vh" }}>
            <LocationsPreview numLocations={3} clientID={clientID} />
          </Box>
        )}
        <Box flex="1" py="3vh">
          <OffersPreview numOffers={3} clientID={clientID} isInfluencerMode={false} allAccounts={false}/>
        </Box>
      </Flex>
    </Box>
  );
}
