import {
  Box,
  Text,
  FormControl,
  Input,
  FormLabel,
  Flex,
  Button,
  IconButton,
  Switch,
  Spinner,
  useToast,
} from "@chakra-ui/react";

import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import InstagramResults from "./InstagramResults";
import React, { useState, useContext } from "react";

import {
  getInstagramProfileData,
  loadImageViaProxy,
} from "services/flaskService";
import {
  uploadClientProfilePicToFirebase,
  createNewClientDoc,
  generateID,
  updateAdminUserClientIds,
  uploadClientBannerPicture,
} from "services/firebaseService";
import { AdminDataContext } from "contexts/adminDataContext";
import BackButton from "components/buttons/BackButton";
import { ActiveUidContext } from "contexts/activeUidContext";
import ImageUploader from "../offers/components/ImageUploader";

function AddAccount() {
  const history = useHistory();

  const [bannerPicture, setBannerPicture] = useState();
  const [isBannerPictureValid, setIsBannerPictureValid] = useState(false);
  const [isProfilePictureValid, setIsProfilePictureValid] = useState(false);

  const toast = useToast();

  const [accountName, setAccountName] = useState("");
  const [instagramHandle, setinstagramHandle] = useState("");
  const [tiktokHandle, setTiktokHandle] = useState("");

  const [website, setWebsite] = useState("");
  const { adminData, isLoading } = useContext(AdminDataContext);
  const { uid } = useContext(ActiveUidContext);
  const [isBackButtonHovered, setIsBackButtonHovered] = useState(false);
  const [isInstagramLoading, setIsInstagramLoading] = useState(false);
  const [isAddingAccount, setIsAddingAccount] = useState(false);

  const [isInstagramScraperWorking, setIsInstagramScraperWorking] =
    useState(true);

  const [instagramProfilePic, setInstagramProfilePic] =
    React.useState<Uint8Array | null>(null);

  const loadImageViaProxyLocal = async (imageURL: string) => {
    try {
      const instagramProfilePic = await loadImageViaProxy(imageURL);
      setInstagramProfilePic(instagramProfilePic);
      // return imageData;
    } catch (error) {
      console.error(error);
      throw error; // Optionally, you can rethrow the error if needed
    }
  };
  // const [instagramMap, setInstagramMap] = useState(new Map());
  // let instagramProfileMap: { [key: string]: any } | null = null;
  const [instagramProfileMap, setInstagramProfileMap] = useState(null);

  const [isInstagramValid, setIsInstagramValid] = useState(false);

  const [locationless, setlocationless] = useState(false);
  const handleChange = () => {
    setlocationless(!locationless);
  };

  async function handleInstagramInput(instagramHandle: string) {
    try {
      let data = await getInstagramProfileData(instagramHandle);
      if (data !== null) {
        setInstagramProfileMap(data);
        console.log(data);
        setIsInstagramValid(true);
        setIsInstagramLoading(false);
        loadImageViaProxyLocal(data.profile_pic_url_hd);
      } else {
        setIsInstagramValid(false);
        setIsInstagramLoading(false);
        setIsInstagramScraperWorking(false);
        toast({
          title:
            "Failed to fetch Instagram account, please upload your profile picture",
          status: "error",
          duration: 3000,
          isClosable: false,
        });
      }
    } catch (error) {
      setIsInstagramValid(false);
      setIsInstagramLoading(false);
      setIsInstagramScraperWorking(false);
      console.error("Error fetching Instagram profile data:", error);
      toast({
        title:
          "Failed to fetch Instagram account, please upload your profile picture",
        status: "error",
        duration: 3000,
        isClosable: false,
      });
    }
  }

  return (
    <Box
      backgroundColor="white"
      height="1650px"
      borderRadius="10px" //width="100%"
    >
      <Flex alignItems="center" pt="25px">
        <BackButton
          isHovered={isBackButtonHovered}
          setIsHovered={setIsBackButtonHovered}
        />

        <Text ml="10px" fontWeight="bold" fontSize="25px">
          Add new account
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        <Box borderTop="1.3px solid #E2E8F0" mt="15px" width="96%" />
      </Flex>

      <FormControl>
        <FormLabel
          mt="25px"
          display="flex"
          ms="30px"
          fontSize="lg"
          fontWeight="500"
          color="black"
          mb="12px"
        >
          Account Name<Text color="red">*</Text>
        </FormLabel>
        <Input
          isRequired={true}
          variant="auth"
          fontSize="lg"
          ml="30px"
          width="95%"
          type="text"
          borderRadius="13px"
          placeholder="Account Name"
          mb="24px"
          fontWeight="500"
          size="lg"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setAccountName(e.target.value)
          }
        />

        <FormLabel
          mt="15px"
          display="flex"
          ms="30px"
          fontSize="lg"
          fontWeight="500"
          color="black"
          mb="12px"
        >
          Website (optional)
        </FormLabel>

        <Flex>
          <Text fontSize="med" fontWeight="500" size="lg" ml="30px" mt="13px">
            https://
          </Text>
          <Input
            isRequired={true}
            variant="auth"
            fontSize="lg"
            ml="7.5px"
            width="90%"
            type="text"
            borderRadius="13px"
            placeholder="Website"
            mb="24px"
            fontWeight="500"
            size="lg"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setWebsite(e.target.value)
            }
          />
        </Flex>
      </FormControl>

      <FormLabel
        mt="15px"
        display="flex"
        ms="30px"
        fontSize="lg"
        fontWeight="500"
        color="black"
        mb="12px"
      >
        Locationless/Virtual/E-commerce (No physical locations)
        <Text color="red">*</Text>
      </FormLabel>
      <Switch
        // colorScheme="blue"
        isChecked={locationless}
        onChange={handleChange}
        paddingLeft="30px"
        size="lg"
      />

      <FormLabel
        mt="15px"
        display="flex"
        ms="30px"
        fontSize="lg"
        fontWeight="500"
        color="black"
        mb="12px"
      >
        Banner Picture
      </FormLabel>

      <Box px="27.5%" py="2%">
        <ImageUploader
          setExampleUGC={setBannerPicture}
          setIsImageValid={setIsBannerPictureValid}
          isButton={false}
          exampleUgcUrl={""}
        />
      </Box>

      <FormLabel
        mt="25px"
        display="flex"
        ms="30px"
        fontSize="lg"
        fontWeight="500"
        color="black"
        mb="12px"
      >
        Tiktok handle (optional)
      </FormLabel>
      <Input
        isRequired={true}
        variant="auth"
        fontSize="lg"
        ml="30px"
        width="95%"
        type="text"
        borderRadius="13px"
        placeholder="Tiktok Handle"
        mb="24px"
        fontWeight="500"
        size="lg"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setTiktokHandle(e.target.value)
        }
      />

      <FormLabel
        mt="15px"
        display="flex"
        ms="30px"
        fontSize="lg"
        fontWeight="500"
        color="black"
        mb="12px"
      >
        Instagram Handle<Text color="red">*</Text>
      </FormLabel>

      <Flex>
        <Text fontSize="med" fontWeight="500" size="lg" ml="30px" mt="10px">
          @
        </Text>

        <Input
          isRequired={true}
          variant="auth"
          fontSize="med"
          ml="10px"
          width={isInstagramScraperWorking ? "80%" : "93%"}
          type="text"
          borderRadius="13px"
          placeholder="Instagram Handle"
          mb="24px"
          fontWeight="500"
          size="lg"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setinstagramHandle(e.target.value)
          }
        />

        {isInstagramScraperWorking && (
          <Button
            isDisabled={instagramHandle.length === 0}
            onClick={() => {
              if (!isInstagramLoading) {
                setIsInstagramLoading(true);
                handleInstagramInput(instagramHandle);
              }
            }}
            backgroundColor="black"
            ml="15px"
            height="45px"
            width="150px"
            borderRadius="10px"
            transition="transform 0.2s, box-shadow 0.2s"
            transform="translateY(0)"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
            _hover={{
              transform: "scale(1.05)",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            _focus={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            _active={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Text
              fontWeight="500"
              fontSize="15px"
              textAlign="center"
              color="white"
            >
              {isInstagramLoading ? (
                <Spinner color="white" mt="5px" />
              ) : (
                "Find Instagram"
              )}
            </Text>
          </Button>
        )}
      </Flex>

      <Box
        height="25%"
        width="70%"
        ml={isInstagramScraperWorking ? "25px" : "0px"}
        borderRadius="10px"
      >
        {isInstagramScraperWorking && (
          <Flex alignItems="center" justifyContent="start">
            <Text
              fontWeight="400"
              color="grey"
              fontSize="15px"
              paddingLeft="20px"
              paddingTop="20px"
            >
              Instagram result (your instagram information will be displayed
              here):
            </Text>

            {isInstagramLoading && (
              <Spinner color="black" mt="20px" ml=" 20px" />
            )}
          </Flex>
        )}
        {!isInstagramScraperWorking && (
          <Flex
            alignItems="start"
            justifyContent="start"
            flexDirection="column"
          >
            <FormLabel
              mt="15px"
              display="flex"
              ms="30px"
              fontSize="lg"
              fontWeight="500"
              color="black"
              mb="12px"
            >
              Profile Picture
            </FormLabel>
            <Box px="27.5%" py="2%">
              <ImageUploader
                setExampleUGC={setInstagramProfilePic}
                setIsImageValid={setIsProfilePictureValid}
                isButton={false}
                exampleUgcUrl={""}
              />
            </Box>
          </Flex>
        )}

        {isInstagramValid && (
          <InstagramResults
            imageData={instagramProfilePic}
            // imageData={loadImageViaProxyLocal(instagramProfileMap?.profile_pic_url_hd)}
            instagramHandle={instagramProfileMap?.username}
            isVerified={instagramProfileMap?.is_verified}
            instagramBio={instagramProfileMap?.biography}
            instagramDisplayName={instagramProfileMap?.full_name}
          />
        )}
      </Box>
      <Button
        isDisabled={
          accountName.length === 0 || (!isInstagramValid && isInstagramScraperWorking) || !isBannerPictureValid
        }
        onClick={async () => {
          try {
            // Turn on spinner (isAddingAccount = true)
            setIsAddingAccount(true);

            let clientID = generateID();

            let fbProfilePicURL = await uploadClientProfilePicToFirebase(
              instagramProfilePic,
              clientID
            );

            let bannerPictureURL = await uploadClientBannerPicture(
              bannerPicture,
              clientID
            );

              await createNewClientDoc(
                clientID,
                fbProfilePicURL,
                accountName,
                website,
                locationless,
                adminData.email,
                isInstagramScraperWorking ? instagramProfileMap : {},
                tiktokHandle,
                bannerPictureURL, 
                instagramHandle
              );
            


            let newClientIDList = [...adminData.clientIDs, clientID];

            await updateAdminUserClientIds(uid, newClientIDList);

            setIsAddingAccount(false);

            history.push("/admin/accounts/accounts/");
          } catch (error) {
            console.error("Error:", error);
          }
        }}
        backgroundColor="black"
        ml="85%"
        height="50px"
        width="150px"
        borderRadius="10px"
        transition="transform 0.2s, box-shadow 0.2s"
        transform="translateY(0)"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        _hover={{
          transform: "scale(1.05)",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
        _focus={{
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
        _active={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Text fontWeight="500" fontSize="20px" textAlign="center" color="white">
          {isAddingAccount ? <Spinner color="white" mt="5px" /> : "Create"}
        </Text>
      </Button>
    </Box>
  );
}

export default AddAccount;
