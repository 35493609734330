import React, { useState, useEffect, useRef } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

interface DropdownMenuProps {
  setFilteredClientId: (clientId: string) => void;
  setSelectedAccountName: (name: string) => void;
  clientAccounts?: any[];
  filteredClientId: string;
  selectedAccountName: string;
}

const Dropdown: React.FC<DropdownMenuProps> = ({
  setFilteredClientId,
  setSelectedAccountName,
  clientAccounts,
  filteredClientId,
  selectedAccountName
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node) &&
      isOpen
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeDropdown);
    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, [isOpen]);

  useEffect(() => {
    const getAccountName = () => {
      let account = '';
      if (!filteredClientId && clientAccounts?.length) {
        const { accountName = '', clientID = '' } = clientAccounts[0];
        account = accountName;
        setFilteredClientId(clientID);
      }

      if (
        filteredClientId &&
        clientAccounts?.length &&
        filteredClientId !== 'allAccounts'
      ) {
        const { accountName = '' } = clientAccounts.find(
          (a) => a.clientID === filteredClientId
        );
        account = accountName;
      }
      return account;
    };

    setSelectedAccountName(getAccountName());
  }, [filteredClientId, clientAccounts]);

  const updateFilterName = (clientId: string) => {
    const { accountName: updatedAccountName = '' } = clientAccounts.find(
      (a) => a.clientID === clientId
    ) || {};
    setSelectedAccountName(updatedAccountName);
  };

  return (
    <Box position="relative">
      <Flex
        align="center"
        p="3"
        bg="#F4F4F4"
        borderRadius="10px"
        cursor="pointer"
        onClick={toggleDropdown}
        ref={dropdownRef}
      >
        <Text fontSize="15px" color="black" fontWeight="400" width={200}>
          {selectedAccountName}
        </Text>
        {isOpen ? (
          <ChevronDownIcon ml={10} w="5" h="5" color="grey" />
        ) : (
          <ChevronUpIcon ml={10} w="5" h="5" color="grey" />
        )}
      </Flex>

      {isOpen && (
        <Box
          position="absolute"
          top="100%"
          bg="#F4F4F4"
          boxShadow="sm"
          borderRadius="10px"
          mt="2"
          zIndex="2"
          maxHeight={190}
          overflow="auto"
          width={284}
        >
          <Flex direction="column" p="2">
            {clientAccounts?.map(({ accountName, clientID }, index) => (
              <Box
                key={index}
                cursor="pointer"
                p="2"
                borderRadius="10px"
                _hover={{
                  bg:
                    filteredClientId === clientID ? '#90F831' : 'gray.200'
                }}
                onClick={() => {
                  setFilteredClientId(clientID);
                  updateFilterName(clientID);
                  toggleDropdown();
                }}
              >
                <Text>{accountName}</Text>
              </Box>
            ))}
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default Dropdown;
