import { Box, Flex, Text } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import React, { useState, useContext, Dispatch, SetStateAction } from "react";
import { AccountsDataContext } from "contexts/accountsDataContext";

interface DropdownMenuCommunityProps {
  clientIDs: string[]; // Define the type of clientIDs here
  setfilteredClientID: Dispatch<SetStateAction<string>>;
}

const DropdownMenuCommunity: React.FC<DropdownMenuCommunityProps> = ({
  clientIDs,
  setfilteredClientID,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const accountsData = useContext(AccountsDataContext);

  const firstName = accountsData[clientIDs[0]]["accountName"];
  const [filteredName, setFilteredName] = useState(firstName);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleFilteredName = (filteredName: string) => {
    setFilteredName(filteredName);
  };

  function handleClick(clientID: string) {
    setfilteredClientID(clientID);
  }

  return (
    <Box position="relative" >
      <Flex
        align="center"
        p="3"
        bg="#F4F4F4"
        borderRadius="10px"
        cursor="pointer"
        onClick={toggleDropdown}
      >
        <Text fontSize="15px" textAlign="center" color="black" fontWeight="400">
          {filteredName}
        </Text>
        {isOpen ? (
  <ChevronUpIcon ml="20" w="5" h="5" color="grey" />
) : (
  <ChevronDownIcon ml="20" w="5" h="5" color="grey" />
)}
      </Flex>

      {isOpen && (
        <Box
          position="absolute"
          top="100%"
          bg="#F4F4F4"
          boxShadow="sm"
          borderRadius="10px"
          mt="2"
          zIndex="1"
        >
          <Flex direction="column" p="2">
            {clientIDs.map((clientID, index) => (
              <Box
                cursor="pointer"
                key={index}
                p="2"
                borderRadius="10px"
                _hover={{ bg: "gray.200" }}
                onClick={() => {
                  if (clientID !== "All accounts") {
                    handleClick(clientID);
                    setfilteredClientID(accountsData[clientID]["clientID"]);
                    toggleDropdown();
                    toggleFilteredName(accountsData[clientID]["accountName"]);
                  } else {
                    handleClick("All accounts");
                    setfilteredClientID("");
                    toggleDropdown();
                    toggleFilteredName("All accounts");
                  }
                }}
              >
                <Text>{accountsData[clientID]["accountName"]}</Text>
              </Box>
            ))}
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default DropdownMenuCommunity;
