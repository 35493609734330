import React, { useState, MutableRefObject, useContext } from "react";
import {
  Box,
  Input,
  InputRightElement,
  InputGroup,
  Button,
  FormControl,
  Text,
  Flex,
  FormLabel,
  Image,
} from "@chakra-ui/react";
import { usePlacesWidget } from "react-google-autocomplete";
import { SearchIcon } from "@chakra-ui/icons";
import InstagramProfilePic from "views/admin/accounts/components/InstagramProfilePic";
import {
  createNewLocationDoc as createNewLocationFile,
  updateClientLocationIds,
} from "services/firebaseService";
import { AccountsDataContext } from "contexts/accountsDataContext";
import { useHistory } from "react-router-dom";
import Environment from "environment";

const AddLocation = () => {
  const [locationInput, setLocationInput] = useState<string>("");
  const [selectedAddress, setSelectedAddress] = useState<string>("");
  const clientID = new URLSearchParams(window.location.search).get("accountId");
  const clientsData = useContext(AccountsDataContext);
  const clientData = clientsData[clientID];
  const history = useHistory();
  const [isLoadingCreateLocation, setIsLoadingCreateLocation] = useState(false);

  async function getLatLongFromAddress(address: string) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${Environment.GOOGLE_API_KEY}`;
    const response = await fetch(url);
    const data = await response.json();
    const { lat, lng } = data.results[0].geometry.location;
    return { lat, lng };
  }

  const onPlaceSelected = (
    place: google.maps.places.PlaceResult,
    inputRef: MutableRefObject<HTMLInputElement | null>
  ) => {
    try {
      if (place.formatted_address) {
        setLocationInput(place.formatted_address);
        setSelectedAddress(place.formatted_address);
      }
      if (inputRef.current) inputRef.current.focus();
    } catch (error) {
      console.error("An error occurred while selecting the place:", error);
      // Optionally handle the error by displaying user-friendly message
    }
  };

  const searchAddressOnGoogleMaps = (address: string) => {
    const query = encodeURIComponent(address);
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${query}`,
      "_blank"
    );
  };

  const { ref: inputRef } = usePlacesWidget({
    apiKey: Environment.GOOGLE_API_KEY || "",
    onPlaceSelected: (place) => onPlaceSelected(place, inputRef),
    options: {
      types: ["address"],
      fields: [
        "address_components",
        "geometry.location",
        "place_id",
        "formatted_address",
      ],
    },
  });

  return (
    <Box>
      <Box
        overflow="hidden"
        backgroundColor="white"
        borderRadius="10px"
        width=""
        height="600px" //'750px'
        position="relative"
      >
        <Box
          position="absolute" // Position the grid absolutely within the container
          mt="40px"
          left={5}
          height="90%"
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <Text ml="10px" mt="-17.5px" fontWeight="bold" fontSize="25px">
                Add Location
              </Text>
            </Flex>
          </Flex>

          <Flex alignItems="center">
            <Box borderTop="1.3px solid #E2E8F0" my="15px" width="1060px" />
          </Flex>
          <Box
            mt="10vh"
            position="relative"
            width="1060px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            height="80%"
            borderRadius="10px"
            backgroundColor="white"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
              backgroundColor="white"
            >
              <Box
                display="flex"
                borderRadius="10px"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                boxShadow={
                  "0 2px 6px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.06)"
                }
                width="90%"
                height="10vh"
              >
                <InputGroup width={"40%"}>
                  <Input
                    minWidth={"25%"}
                    ref={inputRef}
                    value={locationInput}
                    onChange={(e) => setLocationInput(e.target.value)}
                    placeholder="Search for an Address"
                    size="lg"
                    borderRadius="13px"
                    boxShadow={
                      "0 2px 6px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.06)"
                    }
                    backgroundColor={"#24282C"}
                    textColor={"#DDEAF5"}
                  />
                  <InputRightElement
                    pointerEvents="none"
                    children={<SearchIcon color="#B1E344" />}
                  />
                </InputGroup>
              </Box>
              {}
              {selectedAddress && (
                <Box
                  mt="20px"
                  fontSize="lg"
                  backgroundColor="white"
                  alignItems="center"
                >
                  <Text fontSize="lg" fontWeight="500" color="black">
                    Selected Address: {selectedAddress}{" "}
                  </Text>
                  {}
                </Box>
              )}

              {selectedAddress?.length === 0 || (
                <Text
                  fontWeight="500"
                  fontSize="15px"
                  textAlign="center"
                  color="blue.500"
                  mt="30px"
                  cursor="pointer"
                  textDecoration="underline"
                  onClick={() => searchAddressOnGoogleMaps(selectedAddress)}
                >
                  View in Google Maps &gt;
                </Text>
              )}
            </Box>
          </Box>

          <Box position="absolute" bottom="10px" right="10px">
            <Button
              onClick={async () => {
                setIsLoadingCreateLocation(true);
                const latLngMap = await getLatLongFromAddress(selectedAddress);
                const newLocationId = await createNewLocationFile(
                  clientID,
                  selectedAddress,
                  latLngMap.lat,
                  latLngMap.lng
                );
                clientData?.locations?.push(newLocationId); // add location to list of client locations
                await updateClientLocationIds(clientID, clientData?.locations);
                setIsLoadingCreateLocation(false);
                history.goBack();
              }}
              isLoading={isLoadingCreateLocation}
              isDisabled={locationInput.length === 0}
              backgroundColor="black"
              height="45px"
              width="150px"
              borderRadius="10px"
              transition="transform 0.2s, box-shadow 0.2s"
              transform="translateY(0)"
              boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
              _hover={{
                transform: "scale(1.05)",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
              _focus={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
              _active={{
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Text
                fontWeight="500"
                fontSize="15px"
                textAlign="center"
                color="white"
              >
                Create
              </Text>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddLocation;
