import React, { useContext, useState, useEffect } from "react";
import { Box, Text, Flex, Spinner, background, Button } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import { CustomersDataContext } from "contexts/customersDataContext";
import BackButton from "components/buttons/BackButton";
import Card from "components/card/Card";
import CustomerCard from "../components/customerTile";
import CustomerGroupsTable from "../components/CustomerGroups";
import { ChevronRightIcon } from "@chakra-ui/icons";

const AllCustomerGroups = () => {
  const history = useHistory();
  const location = useLocation();
  const { data: customersData, isLoading } = useContext(CustomersDataContext);
  const queryParams = new URLSearchParams(location.search);
  const clientID = queryParams.get('accountId');

  
  const [isBackButtonHovered, setIsBackButtonHovered] = useState(false);

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="200px">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box backgroundColor="white" borderRadius="10px" height="100%">
      <Flex alignItems="center" justifyContent="space-between" pr="10px">
        <Flex alignItems="center" pt="25px" pb="25px">
          <BackButton
            isHovered={isBackButtonHovered}
            setIsHovered={setIsBackButtonHovered}
          />
          <Text ml="10px" fontWeight="bold" fontSize="30px">
            All customer groups
          </Text>
        </Flex>
        <Flex mr="1.5%">
          <Button
            onClick={() => {
              history.push("/admin/community/create-customer-group/");
            }}
            backgroundColor="black"
            float={"right"}
            p="10px 15px"
            mb="10px"
            borderRadius="10px"
            transition="transform 0.2s, box-shadow 0.2s"
            transform="translateY(0)"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
            _hover={{
              transform: "scale(1.05)",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            _focus={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            _active={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Text
              fontWeight="500"
              fontSize="18px"
              textAlign="center"
              color="white"
            >
              Add +
            </Text>
          </Button>
        </Flex>
      </Flex>

      <Flex alignItems="center" justifyContent="center">
        <Box borderTop="1.3px solid #E2E8F0" mb="10px" width="96%" />
      </Flex>
      <Box width="100%">
        <Flex flexDirection="column" alignItems="center" px="2%">
          <CustomerGroupsTable isPreview={false} clientID={clientID} />
        </Flex>
      </Box>
    </Box>
  );
};

export default AllCustomerGroups;
