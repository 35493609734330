import React from "react";
import { Text } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Spinner
} from "@chakra-ui/react";

interface DeleteOfferConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  handleDelete: () => void;
  offerName: string;
  isDeletingOffer: boolean;
}

const DeleteOfferConfirmation: React.FC<DeleteOfferConfirmationProps> = ({
  isOpen,
  onClose,
  handleDelete,
  offerName,
  isDeletingOffer,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Confirmation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>Are you sure you want to delete offer: {offerName}?</p>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" onClick={() => handleDelete()}>
            {isDeletingOffer ? <Spinner size="md" /> : <Text>Delete</Text>}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteOfferConfirmation;
