import React, { useContext } from "react";
import {
  Box,
  Avatar,
  Text,
  Flex,
  SimpleGrid,
  Spinner,
  Button,
  useToast,
} from "@chakra-ui/react";
import { CustomersDataContext } from "contexts/customersDataContext";
import CustomerTile from "./customerTile";

interface CustomerPreviewProps {
  clientID: string;
  isInfluencerMode: boolean;
  inviteLink: string;
}

const CustomerPreview: React.FC<CustomerPreviewProps> = ({
  clientID,
  isInfluencerMode,
  inviteLink,
}) => {
  const { data: customersData, isLoading } = useContext(CustomersDataContext);
  const toast = useToast();

  // const customersDataFiltered = clientID === "" || clientID === "allAccountsID" ? customersData : customersData[clientID];
  const handleCopy = async (url: string) => {
    navigator.clipboard.writeText(url);

    toast({
      title: "Invite link copied to clipboard",
      status: "success",
      duration: 1250,
      isClosable: false,
    });
  };

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="200px">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (!customersData || Object.keys(customersData).length === 0) {
    return <Text ml="5%">No Data Available</Text>;
  }

  const customerMap = new Map();
  const processCustomerData = (clientData: any) => {
    Object.keys(clientData)
      .filter((key) => key !== "id")
      .forEach((phoneNumber) => {
        const customer = clientData[phoneNumber];

        if (isInfluencerMode && !customer.isInfluencer) {
          return; // Skip this customer if not an influencer when in influencer mode
        }

        if (customerMap.has(phoneNumber)) {
          const existingCustomer = customerMap.get(phoneNumber);
          existingCustomer.acceptedOffers = Array.from(
            new Set([
              ...(existingCustomer?.acceptedOffers ?? []),
              ...(customer?.acceptedOffers ?? []),
            ])
          );
          existingCustomer.postedOffers = Array.from(
            new Set([
              ...(existingCustomer?.postedOffers ?? []),
              ...(customer?.postedOffers ?? []),
            ])
          );
          existingCustomer.redeemedOffers = Array.from(
            new Set([
              ...(existingCustomer?.redeemedOffers ?? []),
              ...(customer?.redeemedOffers ?? []),
            ])
          );
        } else {
          customerMap.set(phoneNumber, {
            ...customer,
            phoneNumber: phoneNumber,
          });
        }
      });
  };

  if (clientID) {
    // If a specific clientID is provided, process only that client's customers
    if (customersData[clientID]) {
      processCustomerData(customersData[clientID]);
    }
  } else {
    // If no specific clientID is provided, process all clients' customers
    Object.keys(customersData).forEach((clientId) => {
      const clientData = customersData[clientId];

      processCustomerData(clientData);
    });
  }

  // Sort and select the top 5 customers based on the specified criteria
  const customers = Array.from(customerMap.values())
    .sort((a, b) => {
      const redeemedOffersDiff =
        b.redeemedOffers.length - a.redeemedOffers.length;
      if (redeemedOffersDiff !== 0) return redeemedOffersDiff;

      const postedOffersDiff = b.postedOffers.length - a.postedOffers.length;
      if (postedOffersDiff !== 0) return postedOffersDiff;

      const acceptedOffersDiff =
        b.acceptedOffers.length - a.acceptedOffers.length;
      if (acceptedOffersDiff !== 0) return acceptedOffersDiff;

      return b.instagramFollowerCount - a.instagramFollowerCount;
    })
    .slice(0, 5);

  return customers.length === 0 && isInfluencerMode ? (
    <Flex flexDirection="column" alignItems="center">
      <Text mt="10%" mb="5%" fontSize="18px" fontWeight="500">
        Invite your first influencer
      </Text>
      {clientID !== "" && clientID !== "allAccountsID" && (
        <Button
          backgroundColor="black"
          borderRadius="10px"
          transition="transform 0.2s, box-shadow 0.2s"
          transform="translateY(0)"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          _hover={{
            transform: "scale(1.05)",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          _focus={{
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          _active={{
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
          onClick={() => {
            handleCopy(inviteLink);
          }}
        >
          <Text color="white" fontSize="12.5px" fontWeight="500">
            Invite more
          </Text>
        </Button>
      )}{" "}
    </Flex>
  ) : (
    <SimpleGrid spacing={2}>
      {customers.map((customer, index) => (
        <CustomerTile
          customer={customer}
          key={index}
          showStats={false}
          clientID={""}
        />
      ))}
    </SimpleGrid>
  );
};

export default CustomerPreview;
