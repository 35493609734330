import React, { useState } from "react";
import { Text, Flex, IconButton } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { AccountsDataContext } from "contexts/accountsDataContext";
import { FaChevronLeft } from "react-icons/fa";
import { MouseEventHandler } from "react";

interface TitleAndDescriptionProps {
  title: String;
  description: String;
  backFunction: MouseEventHandler<HTMLButtonElement>;
  hideBackButton: boolean;
}

function TitleAndDescription({
  title,
  description,
  backFunction,
  hideBackButton,
}: TitleAndDescriptionProps) {
  // Global Offer vars

  return (
    <Flex ml="-2.5%">
      {!hideBackButton && (
        <IconButton
          aria-label="back-button"
          icon={<FaChevronLeft size="20" />}
          onClick={backFunction}
          style={{
            background: "transparent",
            border: "none",
            paddingLeft: 25,
            paddingRight: 5,
            transition: "transform 0.3s, shadow 0.3s",
          }}
        />
      )}
      <Flex flexDirection="column" height="10%" alignItems="start" ml="1%">
        <Text fontSize="19px" fontWeight="500" color="black">
          {title}
        </Text>
        <Text
          fontSize="15px"
          fontWeight="300"
          color="#667085"
          mt=".5%"
          width="120%"
        >
          {description}
        </Text>
      </Flex>
    </Flex>
  );
}

export default TitleAndDescription;
