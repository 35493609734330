export const lineChartDataTotalSpent = [
	{
		name: 'Reach',
		data: [50, 64, 321, 66, 49, 68, 489, 43, 832]
	},
];

export const lineChartOptionsTotalSpent: any = {
	chart: {
		toolbar: {
			show: false
		},
		dropShadow: {
			enabled: true,
			top: 13,
			left: 0,
			blur: 10,
			opacity: 0.1,
			color: '#90F831'
		}
	},
	colors: ['#90F831'],
	markers: {
		size: 0,
		colors: 'white',
		strokeColors: '#7551FF',
		strokeWidth: 3,
		strokeOpacity: 0.9,
		strokeDashArray: 0,
		fillOpacity: 1,
		discrete: [],
		shape: 'circle',
		radius: 2,
		offsetX: 0,
		offsetY: 0,
		showNullDataPoints: true
	},
	tooltip: {
		theme: 'dark'
	},
	dataLabels: {
		enabled: false
	},
	stroke: {
		curve: 'smooth',
		type: 'line'
	},
	xaxis: {
		type: 'numeric',
		categories: ['SEP', 'OCT', 'NOV', 'DEC', 'JAN', 'FEB', 'MAR', 'APR', 'MAY'],
		labels: {
			style: {
				colors: '#A3AED0',
				fontSize: '12px',
				fontWeight: '500'
			}
		},
		axisBorder: {
			show: false
		},
		axisTicks: {
			show: false
		}
	},
	yaxis: {
		type: 'numeric',
		labels: {
			style: {
				colors: '#A3AED0',
				fontSize: '10px',
				fontWeight: '500'
			}
		},
		axisBorder: {
			show: false
		},
		axisTicks: {
			show: true
		}
	},
	legend: {
		show: false
	},
	grid: {
		show: false,
		column: {
			color: ['#7551FF', '#39B8FF'],
			opacity: 0.5
		}
	},
	color: ['#7551FF', '#39B8FF']
};
