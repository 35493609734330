import { Text, Flex, Switch, Button, Box } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";

interface ContentTypePublishedProps {
  contentType: string;
  isActive: boolean;
  toggleSwitch: Function;
  clientID: string;
}

function ContentTypePublished({
  contentType,
  isActive,
  toggleSwitch,
  clientID,
}: ContentTypePublishedProps) {
  const history = useHistory();

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex width="15%" justifyContent="start" alignItems="center">
        <Text fontSize="18px">
          {contentType === "instagramStory"
            ? "Instagram Story"
            : contentType === "instagramPost"
              ? "Instagram Post"
              : contentType === "instagramReel"
                ? "Instagram Reel"
                : contentType === "ugc"
                  ? "UGC"
                  : "Tiktok Video"}
        </Text>
        {contentType === "tiktokVideo" && (
          <Box
            ml="4%"
            backgroundColor="#D2DFFF"
            borderRadius="10px"
            width="60px"
            p="1"
          >
            <Flex width="100%" alignItems="center" justifyContent="center">
              <Text fontSize="11px" color="#2A85FF">
                Beta
              </Text>
            </Flex>
          </Box>
        )}
      </Flex>
      <Flex width="15%" justifyContent="center" alignItems="center">
        {/* {!isDisabled && ( */}
        <Switch
          color="#2A85FF"
          size="10px"
          defaultChecked={isActive}
          onChange={() => {
            toggleSwitch(clientID, contentType, !isActive);
            //   toggleSwitch(contentType);
          }}
        />
        {/* )} */}

        {/* {isDisabled && (
          <Button
            ml="auto"
            onClick={() => {}}
            backgroundColor="black"
            width="100px"
            p="10px 15px"
            borderRadius="10px"
            transition="transform 0.2s, box-shadow 0.2s"
            transform="translateY(0)"
            _hover={{
              transform: "scale(1.05)",
            }}
            _active={{}}
          >
            <Text
              fontWeight="500"
              fontSize="15px"
              textAlign="center"
              color="white"
            >
              Set up
            </Text>
          </Button>
        )} */}
      </Flex>
      <Flex width="15%" justifyContent="end" alignItems="center">
        <Button
          onClick={() => {
            history.push(
              "/admin/create-autopilot-offer/?accountId=" +
              clientID +
              "&isEditing=true&contentType=" +
              contentType
            );
          }}
          size="30px"
          backgroundColor="transparent"
        >
          <EditIcon color="black" />
        </Button>
      </Flex>
    </Flex>
  );
}

export default ContentTypePublished;
