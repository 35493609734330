/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { Flex, Box, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { decimalFraction, valueToNumberConvert } from "utils";


interface CashAmountProps {
    inputValues: {
        compensation: number
        percentOrCashOff: string
        isCustomerOffer: boolean,
        contentType: string,
        minimumFollowerCount: number
    };
    onInputChange: (fieldName: keyof CashAmountProps["inputValues"], value: any) => void;
    data: any,
}

const CashAmount: React.FC<CashAmountProps> = ({
    inputValues, onInputChange, data: additionalData
}) => {

    const { isEditing } = additionalData
    return (
        <Box py="60px">
            <Flex flexFlow={'column'} gap={'60px'}>
                <FormControl display="flex" alignItems="center" gap="20px">
                    <FormLabel m="0" variant={'chakra_label_default'}>Reward Amount ($)</FormLabel>
                    <Box w={'100%'}>
                        <Input
                            width="100%"
                            variant="chakra_input_default"
                            size="chakra_md_medium"
                            fontSize={'chakra_sm'}
                            fontWeight={'500'}
                            readOnly={isEditing}
                            value={inputValues?.compensation === 0 ? "" : inputValues?.compensation}
                            type="text" // Change type to text

                            onChange={(e) => {
                                // Extract the value entered by the user
                                let value = e?.target?.value;
                                onInputChange("compensation", decimalFraction(value));
                                onInputChange("percentOrCashOff", "Cash")
                            }}
                        />
                    </Box>
                </FormControl>
                {inputValues.isCustomerOffer && ["ugcPicture", "ugcVideo"]?.indexOf(inputValues?.contentType) === -1 && (
                    <FormControl display="flex" alignItems="center" gap="20px">
                        <FormLabel m="0" variant={"chakra_label_default"}>
                            Minimum Follower Count
                        </FormLabel>
                        <Box w={"100%"}>
                            <Input
                                width="100%"
                                variant="chakra_input_default"
                                size="chakra_md_medium"
                                fontSize={"chakra_sm"}
                                fontWeight={"500"}
                                type="text"
                                maxLength={6}
                                value={inputValues?.minimumFollowerCount}
                                onChange={(e) => {
                                    let value: any = valueToNumberConvert(e?.target?.value);
                                    if (!isNaN(value)) {
                                        onInputChange("minimumFollowerCount", value);
                                    } else {
                                        onInputChange("minimumFollowerCount", 0);
                                    }
                                }}
                            />
                        </Box>
                    </FormControl>
                )}
            </Flex>
        </Box>
    );
}

export default CashAmount;
