import React, { FC, useContext, useRef } from "react";
import {
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Center,
  Button,
  Box,
  Text,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { PiQrCodeBold } from "react-icons/pi";
import QRCode from "qrcode.react";
import Environment from "environment";
import { AccountsDataContext } from "contexts/accountsDataContext";
import { CopyIcon } from "@chakra-ui/icons";
import { useState } from "react";
import CopyLink from "./CopyLink";

interface QrCodeButtonProps {
  clientID: string;
  locationID: string;
  touchpointID?: string;
}

const QrCodeButton: FC<QrCodeButtonProps> = ({
  clientID,
  locationID,
  touchpointID = "",
  ...rest
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const qrRef = useRef(null);
  const accountsData = useContext(AccountsDataContext);
  const clientData = accountsData[clientID];
  const touchpointIdParam = touchpointID !== "" ? `&touchpointID=${touchpointID}` : "";

  const url = clientData?.isWhiteLabeled
    ? clientData?.locationless
      ? `https://${clientData?.whiteLabelDomain}/clients?clientID=${clientID}${touchpointIdParam}`
      : locationID !== ""
        ? `https://${clientData?.whiteLabelDomain}/clients?clientID=${clientID}&locationID=${locationID}${touchpointIdParam}`
        : `https://${clientData?.whiteLabelDomain}/clients?clientID=${clientID}${touchpointIdParam}`
    : locationID !== ""
      ? `https://${Environment.USE_TEST_MODE
        ? "storyitapp-dev.web.app"
        : "offers.storyit.app"
      }/clients?clientID=${clientID}&locationID=${locationID}${touchpointIdParam}`
      : `https://${Environment.USE_TEST_MODE
        ? "storyitapp-dev.web.app"
        : "offers.storyit.app"
      }/clients?clientID=${clientID}${touchpointIdParam}`;
  const downloadQR = () => {
    if (qrRef.current) {
      const canvas = qrRef.current.firstChild; // Accessing the canvas element
      if (canvas) {
        const qrUrl = canvas.toDataURL("image/png");
        const a = document.createElement("a");
        a.href = qrUrl;
        a.download = `QR_${locationID}.png`;
        a.click();
      }
    }
  };

  return (
    <>
      <IconButton
        onClick={() => {
          console.log(`QR Code Button Clicked on location: ${locationID}`);
          onOpen();
        }}
        backgroundColor="transparent"
        icon={<PiQrCodeBold size={30} color="#1a1a1a"/>}
        size="lg"
        _hover={{
          transform: "scale(1.15)",
          cursor: "pointer",
        }}
        aria-label="QR Code"
        {...rest}
      />

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="10px">
          <ModalHeader>QR Code</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Center mb="4">
              <div ref={qrRef}>
                <QRCode value={url} size={150} />
              </div>
            </Center>
          </ModalBody>
          <Center>
            <Button backgroundColor="black" onClick={downloadQR}>
              <Text color="white">Download QR code</Text>
            </Button>
          </Center>

          <Box p="5%">
            <CopyLink url={url} />
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default QrCodeButton;
