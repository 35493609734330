import { useState, useEffect, useContext } from 'react';
import { Box, Flex, Grid, Text, Button, Input, Table, Tr, Thead, Tbody, Td, Th, Link, IconButton, HStack } from '@chakra-ui/react';
import { SuperUserDataContext } from 'contexts/superUserDataContext'; // Adjust import based on actual context file path
import { ReviewContestsDataContext } from 'contexts/reviewContestsDataContext';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/DatePicker.css';
import { createReviewContest, updateReviewContest, updateClientReviewContestInformation,selectReviewContestWinner } from 'services/firebaseService';
import ReviewContestInformation from './components/reviewContestInformation';
import { Timestamp } from 'firebase/firestore';

type ReviewContest = {
    id?: string;
    name?: string
    compensation?: number;
    contestants?: Record<string, any>;
    endDate?: Timestamp;
    isActive?: boolean;
    winner?: string;
};
interface Client {
    id: string;
    accountName: string;
    googleReviewLink?: string;
    reviewContestID?: string
}

export default function ReviewContestsPage() {
    const superUserData = useContext(SuperUserDataContext);
    const { reviewContestsData, clientsData } = useContext(ReviewContestsDataContext)

    const [activeContests, setActiveContests] = useState<ReviewContest[]>([]);
    const [inactiveContests, setInactiveContests] = useState<ReviewContest[]>([]);

    const today = new Date();

    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    
    tomorrow.setHours(0, 0, 0, 0); 
    
    const endDate = Timestamp.fromDate(tomorrow);

    const newContest ={
        name: '',
        compensation: 0,
        endDate: endDate,
        isActive: true,
        contestants: {}
    }

    useEffect(() => {
        refreshContestsData();
    }, [reviewContestsData, clientsData]);

    const refreshContestsData = () => {
        const active = Object.values(reviewContestsData).filter((contest: ReviewContest) => contest.isActive);
        const inactive = Object.values(reviewContestsData).filter((contest: ReviewContest) => !contest.isActive);

        setActiveContests(active);
        setInactiveContests(inactive);
    };

    const [selectedClients, setSelectedClients] = useState([])
    
    const clients = Object.values(clientsData).reduce((acc, item) => {
        const key = item["reviewContestID"] || "N/A";
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push({
            id: item["id"], accountName: item["accountName"], googleReviewLink: item["googleReviewLink"], reviewContestID: item["reviewContestID"]
        });
        return acc;
    }, {});

    
    const handleClientDeselected = (clientID: string) => {
        setSelectedClients(prev => prev.filter(prevClientID => prevClientID !== clientID));
    };

    const handleClientSelected = (clientID: string) => {
        setSelectedClients(prevClients => {
                return [...prevClients, clientID];
        });
    };

    const handleCreateContest = async (newContest: ReviewContest, newClients: Client[]) => {

        const { name, compensation, endDate, isActive, contestants } = newContest;
        const reviewContestID = await createReviewContest(name,compensation, endDate, isActive, contestants);

        newClients.forEach(client=> {
            handleAddClient(client.id, reviewContestID, client.googleReviewLink)
        })
    };

    const handleUpdateContest = async (contest: ReviewContest, newClients: Client[]) => {
        const { id, name, compensation, endDate, isActive } = contest;
        await updateReviewContest(id, name, compensation, endDate, isActive)
        
        for (const client of newClients) {
            await handleAddClient(client.id, id, client.googleReviewLink);
        }
    
        // Remove clients that are no longer present
        if (clients[id]) {
            for (const client of clients[id]) {
                if (!newClients.some(newClient => newClient.id === client.id)) {
                    await handleDeleteClient(client.id);
                }
            }
        }
    }

    
    const handleAddClient = async (clientID: string, reviewContestID: string, googleReviewLink: string) => {

        await updateClientReviewContestInformation(clientID, reviewContestID, googleReviewLink)
    }
    const handleDeleteClient = async (clientID: string) => {
        const googleReviewLink = clientsData[clientID].googleReviewLink;
        await updateClientReviewContestInformation(clientID, null, googleReviewLink)
    }
    const handleEndContest = async (contestID: string) => {
        await selectReviewContestWinner(contestID);
        if(clients[contestID])  {
            for (const client of clients[contestID]) {
                    await handleDeleteClient(client.id);
                }
        }
    }

    const firestoreTimestampToDateString = (timestamp: Timestamp | null): String | null => {
        if (!timestamp) return null;

        const date = timestamp.toDate();

        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
        };
    return (
        <Box p="5">
            <Box backgroundColor="white" borderRadius="10px" width="100%" height="80%" position="relative">
                <Flex alignItems="center" mb="5">
                    <Text ml="10px" my="15px" fontWeight="bold" fontSize="25px">
                        Review Contests
                    </Text>
                </Flex>

                {/* Create Contest Section */}
                <Box mb="5" width="60%">
                    <Flex direction="column" gap="5">
                        <Text fontSize="2xl" fontWeight="bold">Create a New Contest</Text>
                         <ReviewContestInformation clients={[]} availableClients={clients["N/A"]} contest={newContest} handleSaveContest={handleCreateContest}  handleClientSelected={handleClientSelected} handleClientDeselected={handleClientDeselected} handleEndContest={handleEndContest} selectedClients={selectedClients} isNewContest={true}/> 
                    </Flex>
                </Box>

                {/* Ongoing Contests Section */}
                <Box mb="5">
                    <Text fontWeight="bold" mb="3" fontSize="2xl">Ongoing Contests</Text>
                    <Box overflowX="auto" w="full">
                    <HStack gap={8} spacing={8} align="flex-start">
                        {activeContests.map((contest) => (
                            <Box
                                key={contest.id}
                                w="1000px"
                                minW="50%"
                                bg="#DDEAF5"
                                borderRadius="17px"
                                shadow="md"
                                borderWidth="1px"
                                p="3"
                            >
                                 <ReviewContestInformation clients={clients[contest.id]} availableClients={clients["N/A"]} contest={contest} handleSaveContest={handleUpdateContest} handleClientSelected={handleClientSelected} handleClientDeselected={handleClientDeselected} handleEndContest={handleEndContest} selectedClients={selectedClients} isNewContest={false}/> 
                            </Box>

                        ))}
                    </HStack>
                    </Box>
                </Box>

                {/* Past Winners Section */}
                <Box mb="20">
                    <Text fontWeight="bold" mb="3" fontSize="2xl">Past Winners</Text>
                    <Box overflowX="auto" w="full">
                    <HStack gap={8} spacing={8} align="flex-start">
                        {inactiveContests.map((contest, index) => (
                            <Box
                                key={index}
                                w="1000px"
                                minW="30%"
                                h="40"
                                bg="#DDEAF5"
                                borderRadius="17px"
                                shadow="md"
                                borderWidth="1px"
                                p="3"
                            >
                                <Text fontWeight="bold" fontSize="xl">{contest.name}</Text>
                                <Text fontSize="xl">Winner: {contest.winner}</Text>
                                <Text fontSize="xl">End Date: {firestoreTimestampToDateString(contest.endDate)}</Text>
                            </Box>
                        ))}
                    </HStack>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
