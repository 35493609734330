import React, { createContext, useState, useEffect } from "react";
import { collection, onSnapshot, doc } from "firebase/firestore";
import { firestore } from "../firebase";

export const InfluencerCampaignsDataContext = createContext();

export const InfluencerCampaignsDataProvider = ({ children, clientIds }) => {
    const [influencerCampaigns, setInfluencerCampaigns] = useState({});
    useEffect(() => {
        const influencerCollectionRef = collection(firestore, "influencerCampaigns");

        const unsubscribe = onSnapshot(influencerCollectionRef, (snapshot) => {
            const fetchedInfluencerCampaigns = {};
            snapshot.forEach((docSnap) => {
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    fetchedInfluencerCampaigns[docSnap.id] = { id: docSnap.id, ...data };
                }
            });
            setInfluencerCampaigns(fetchedInfluencerCampaigns);
        });

        // Cleanup the listener when the component is unmounted
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <InfluencerCampaignsDataContext.Provider value={influencerCampaigns}>
            {children}
        </InfluencerCampaignsDataContext.Provider>
    );
};

export default InfluencerCampaignsDataProvider;
