import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  Box,
  Input,
} from '@chakra-ui/react';
import { MdDelete } from "react-icons/md";
import DeleteConfirmation from 'components/buttons/confirmDelete';

import { updateTouchpointForClient,generateID,DeleteTouchpointForClient } from 'services/firebaseService';

const default_touchpoint = {
  name: '',
  type: '',
  design: null,
  impressions: [],
  enabled: true,
}

type UpdateTouchpointModalProps = {
isOpen: boolean;
onClose: () => void;
clientID: string;
touchpoint?: any;
touchpointID: string;
};

const UpdateTouchpointModal: React.FC<UpdateTouchpointModalProps> = ({isOpen, onClose, clientID, touchpoint, touchpointID}) => {
    const [newTouchpoint, setNewTouchpoint] = useState(default_touchpoint);

    const [newTouchpointID, setNewTouchpointID] = useState("");
    const [isDeletionConfirmationOpen, setIsDeletionConfirmationOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const openDeletionConfirmation = () => {
      setIsDeletionConfirmationOpen(true);
    };
  
    const closeDeletionConfirmation = () => {
      setIsDeletionConfirmationOpen(false);
    };

    const touchpoint_types = ["Website link", "Table tent", "Bag drop", "Print out"]

 const [isOtherType, setIsOtherType] = useState(false);


      useEffect(() => {
        if (touchpoint) {
          
          setIsOtherType(!touchpoint_types.includes(touchpoint.type))
          
          setNewTouchpoint(touchpoint);
          setNewTouchpointID(touchpointID)
          
        } else {
          setNewTouchpoint(default_touchpoint);
          setNewTouchpointID(generateID())
        }
      }, [isOpen]);

 


  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewTouchpoint({ ...newTouchpoint, name: value });
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { type, value } = e.target;
    setNewTouchpoint({ ...newTouchpoint, type: value });
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if(e.target.value == "Other"){
        setIsOtherType(true);
        setNewTouchpoint({ ...newTouchpoint, type: "" });
        
    }
    else{
        setIsOtherType(false)
        setNewTouchpoint({ ...newTouchpoint, type: e.target.value });
    }
  };

  const handleClose = () => {
    setNewTouchpoint(default_touchpoint);
    setIsOtherType(false);
    onClose();
  }

  const handleUpdateTouchpoint = () => {
    updateTouchpointForClient(clientID, newTouchpointID, newTouchpoint);
    
    handleClose();
  };
 const handleDeletePressed = () => {
  if(touchpointID != ""){
    openDeletionConfirmation()
  }
  else{
    handleClose()
  }
 }
  const handleDeleteTouchpoint = async() => {
    setIsDeleting(true)
    if(touchpointID != ""){
      await DeleteTouchpointForClient(clientID, newTouchpointID);
    }
    setIsDeleting(false);
    closeDeletionConfirmation();
    handleClose();
  };


  return (
    <Modal isOpen={isOpen} onClose={()=>{handleClose()}}>
     {isDeletionConfirmationOpen && 
     ( <DeleteConfirmation 
        isOpen={isDeletionConfirmationOpen} 
        onClose={closeDeletionConfirmation}
        handleDelete={handleDeleteTouchpoint}
        deletedName={newTouchpoint.name}
        isDeleting={isDeleting}/> )}

      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
        {touchpointID!=""?"Edit":"Create New"} Touchpoint</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            placeholder="Enter name"
            name="name"
            value={newTouchpoint.name}
            onChange={handleNameChange}
            mb={3}
          />
          <Select
            placeholder="Select type"
           
            value={isOtherType?"Other":newTouchpoint.type}
            onChange={handleSelectChange}
            mb={3}
          >
            {touchpoint_types.map(type => (
              <option value={type}>{type}</option>
            ))}

          <option value="Other">Other</option>
          </Select>
          {isOtherType && (
            <Input
              placeholder="Enter custom type"
              name="type"
              value={newTouchpoint.type}
              onChange={handleTypeChange}
              mb={3}
            />
          )}
          
        </ModalBody>
        <ModalFooter justifyContent={"space-between"}>
        <Button colorScheme="red" onClick={handleDeletePressed}>
           {touchpointID!=""?"Delete":"Cancel"}
          </Button>
          <Button style={{backgroundColor:"#1a1a1a", color:"white"}} onClick={handleUpdateTouchpoint} isDisabled={newTouchpoint.name === '' || newTouchpoint.type === ''}>
          {touchpointID!=""?"Save":"Submit"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateTouchpointModal;
