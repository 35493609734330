
/**
 * @info
 * set data to localstorage.
 */
export const setData = (key: string, value: any) => {
    return localStorage.setItem(key, JSON.stringify(value))
}


/**
 * @info
 * get data from localstorage.
 */
export const getData = (key: string) => {
    return JSON.parse(localStorage.getItem(key)) ?? {}
}

export const removeData = (key: string) => {
    return localStorage.removeItem(key);
}