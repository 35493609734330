import React, { createContext, useState, useEffect } from 'react';
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from '../firebase';

export const CustomerGroupsContext = createContext();

export const CustomerGroupsProvider = ({ children, customerGroupIds }) => {
    const [CustomerGroupsData, setCustomerGroupsData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);

        if (!customerGroupIds || customerGroupIds.length === 0) {
            setIsLoading(false);
            return;
        }

        // Initialize an object to keep track of the unsubscribe functions for cleanup
        const unsubscribeFns = {};

        customerGroupIds.forEach((groupId) => {
            // Listening to customer group data
            const groupDocRef = doc(firestore, 'customerGroups', groupId);
            unsubscribeFns[groupId] = onSnapshot(groupDocRef, (docSnap) => {
                if (docSnap.exists()) {
                    setCustomerGroupsData(prevData => ({
                        ...prevData,
                        [groupId]: { id: docSnap.id, ...docSnap.data() }
                    }));
                }
            });

            // Listening to customer group stats
            const statsDocRef = doc(firestore, 'customerGroupStats', groupId);
            unsubscribeFns[`${groupId}_stats`] = onSnapshot(statsDocRef, (docSnap) => {
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    const latestTimestamp = Object.keys(data).pop();
                    const latestData = data[latestTimestamp];
                    setCustomerGroupsData(prevData => ({
                        ...prevData,
                        [groupId]: {
                            ...prevData[groupId],
                            stats: { ...latestData, timestamps: data }
                        }
                    }));
                }
            });
        });

        setIsLoading(false);

        // Cleanup function to unsubscribe from the onSnapshot listeners
        return () => {
            Object.values(unsubscribeFns).forEach(unsubscribeFn => {
                if (typeof unsubscribeFn === 'function') {
                    unsubscribeFn();
                }
            });
        };
    }, [customerGroupIds]);

    return (
        <CustomerGroupsContext.Provider value={{ isLoading, data: CustomerGroupsData }}>
            {children}
        </CustomerGroupsContext.Provider>
    );
};

export default CustomerGroupsProvider;
