import { Icon, SimpleGrid, useColorModeValue, Flex } from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React from "react";
import {
  MdDiscount,
  MdLocationPin,
  MdOutlineBarChart,
  MdSend,
} from "react-icons/md";

interface Stat {
  title: string;
  value: string;
  icon: any;
}

type StatsRowProps = {
  [x: string]: any;
  stats: Stat[];
};

function StatsRow({ stats, ...rest }: StatsRowProps) {
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColor = useColorModeValue("black", "white");

  return (
    <Flex
      justifyContent="space-evenly"
      alignItems="start"
      //   columns={{ base: 1, md: 2, lg: 4, "2xl": 6 }}
      //   gap={{ base: "20px", md: "30px" }}
      mb="20px"
    >
      {stats.map((stat, index) => (
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={stat.icon} color={textColor} />}
            />
          }
          name={stat.title}
          value={stat.value}
          key={index}
        />
      ))}
    </Flex>
  );
}

export default StatsRow;
