import { Flex, Spinner, Text, Button } from "@chakra-ui/react";
import Card from "components/card/Card";
import { OffersDataContext } from "contexts/offersDataContext";
import { useContext, useEffect, useState } from "react";
import OfferCard from "./OfferCard";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import CreateOfferButton from "../../offers/components/createOfferButton";
import useOffersData from "services/offerDataService";
import { AdminDataContext } from "contexts/adminDataContext";

export default function OffersPreview(props: {
  numOffers: number;
  [x: string]: any;
  clientID: string;
  isInfluencerMode: boolean;
  allAccounts: boolean;
}) {

  const { numOffers, clientID, isInfluencerMode, allAccounts } = props;
  // const offersData = useContext(OffersDataContext);
  const history = useHistory();

  /**
    * @info changes made by nirmal on date :- 16/05/2024
    * Added custom hook to get offerData
    */
  const { adminData } = useContext(AdminDataContext);

  const { isLoading: offerLoading = false, offersData, totalOffers } = useOffersData({ clientID: clientID, perPage: 5, userId: adminData?.userID || "", isInfluencerOffer: isInfluencerMode });

  if (offerLoading || !adminData) {
    return (
      <Card flexDirection="column" mt="25px">
        <Flex justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
        </Flex>
      </Card>
    );
  }

  const filteredOffersData = Object.values(offersData)
    .filter(
      (offer: any) =>
        (allAccounts || offer.clientID === clientID) &&
        (!isInfluencerMode || offer.isInfluencerOffer)
    )
    .sort((a: any, b: any) => b.date - a.date)
    .slice(0, numOffers);

  return (
    <Card
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="column"
      w="100%"
      h="100%"
      mb="0px"
      mt={"20px"}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        pe="20px"
        pt="5px"
        mb="15px"
      >
        <Text
          color={"black"}
          fontSize="24px"
          textAlign="start"
          fontWeight="700"
          lineHeight="100%"
        >
          {isInfluencerMode && "Influencer"} Offers
        </Text>


        <Button
          backgroundColor="black"
          borderRadius="10px"
          transition="transform 0.2s, box-shadow 0.2s"
          transform="translateY(0)"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          _hover={{
            transform: "scale(1.05)",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          _focus={{
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          _active={{
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
          onClick={() => {
            history.push(
              "/admin/view-all-offers/?accountId=" +
              clientID +
              "&isInfluencerMode=" +
              isInfluencerMode
            );
          }}
          rightIcon={<ChevronRightIcon color="white" fontSize="20px" />}
        >
          <Text color="white" fontSize="15px" fontWeight="500">
            All offers
          </Text>
        </Button>

      </Flex>
      {filteredOffersData.length === 0 ? (
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          height="250px"
          pe="20px"
          pt="5px"
          mb="15px"
        >
          <Text fontSize="18px" fontWeight="500" pb="5%">
            Create your first {isInfluencerMode ? "influencer" : ""} offer!
          </Text>
          <CreateOfferButton clientID={clientID} />
        </Flex>
      ) : (
        filteredOffersData.map((offer: any, index: number) => (
          <OfferCard
            key={index}
            offer={offer}
          />
        ))
      )}
    </Card>
  );
}
