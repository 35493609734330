/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import {
  Text,
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import {
  contentTypeText,
  decimalFraction,
  offerRequirementSummary,
  valueToNumberConvert,
} from "utils";

interface OfferRequirementsLimitsProps {
  inputValues: {
    minPurchaseAmount: number;
    minimumFollowerCount: number;
    expirationLimit?: number;
    isCustomerOffer?: Boolean;
    prerequisiteQuantity?: number;
    discountItem?: string;
    prerequisiteName?: string;
    isPrerequisite: Boolean;
    rewardClientIDs: any[];
    rewardClientNames: any[];
    compensation: number;
    contentType: string;
    percentOrCashOff: string;
    specificProduct: string;
    isFreeReward: boolean;
    discountType: string;
    hashtag: string;
    ecommerceDiscountCode?: string;
    isEvergreenCode?: boolean
  };
  onInputChange: (
    fieldName: keyof OfferRequirementsLimitsProps["inputValues"],
    value: any
  ) => void;
  data: any;
}

const OfferRequirementsLimits: React.FC<OfferRequirementsLimitsProps> = ({
  inputValues,
  onInputChange,
  data: additionalDetails,
}) => {
  const { accountData, isEditing } = additionalDetails;
  const { instagramHandle, offers = [], accountName } = accountData;

  useEffect(() => {
    if (!isEditing) {
      onInputChange("rewardClientIDs", []);
      onInputChange("rewardClientNames", []);
    }
  }, []);

  return (
    <Box py="60px">
      <Flex flexFlow={"column"} gap={"60px"}>
        <FormControl display="flex" alignItems="center" gap="20px">
          <FormLabel m="0" variant={"chakra_label_default"}>
            Minimum Purchase Amount
          </FormLabel>
          <Box w={"100%"}>
            <Input
              width="100%"
              variant="chakra_input_default"
              size="chakra_md_medium"
              fontSize={"chakra_sm"}
              fontWeight={"500"}
              type="text"
              value={inputValues.minPurchaseAmount}
              onChange={(e) => {
                let value = e?.target?.value;
                if (value && !Number(value)) {
                  // Update state with the cleaned value
                  onInputChange("minPurchaseAmount", 0);
                } else {
                  // Update state with the cleaned value
                  onInputChange("minPurchaseAmount", decimalFraction(value));
                }
              }}
            />
          </Box>
        </FormControl>
        {inputValues.isCustomerOffer && ["ugcPicture", "ugcVideo"]?.indexOf(inputValues?.contentType) === -1 && (
          <FormControl display="flex" alignItems="center" gap="20px">
            <FormLabel m="0" variant={"chakra_label_default"}>
              Minimum Follower Count
            </FormLabel>
            <Box w={"100%"}>
              <Input
                width="100%"
                variant="chakra_input_default"
                size="chakra_md_medium"
                fontSize={"chakra_sm"}
                fontWeight={"500"}
                type="text"
                maxLength={6}
                value={inputValues?.minimumFollowerCount}
                onChange={(e) => {
                  let value: any = valueToNumberConvert(e?.target?.value);
                  if (!isNaN(value)) {
                    onInputChange("minimumFollowerCount", value);
                  } else {
                    onInputChange("minimumFollowerCount", 0);
                  }
                }}
              />
            </Box>
          </FormControl>
        )}
        <FormControl display="flex" alignItems="center" gap="20px">
          <FormLabel m="0" variant={"chakra_label_default"}>
            Expiration Limit(days)
          </FormLabel>
          <Box w={"100%"}>
            <Input
              width="100%"
              variant="chakra_input_default"
              size="chakra_md_medium"
              fontSize={"chakra_sm"}
              fontWeight={"500"}
              type="text"
              value={
                inputValues?.expirationLimit === 0
                  ? ""
                  : inputValues?.expirationLimit
              }
              maxLength={6}
              onChange={(e) => {
                let value: any = valueToNumberConvert(e?.target?.value);
                if (!isNaN(value)) {
                  onInputChange("expirationLimit", value);
                } else {
                  onInputChange("expirationLimit", 0);
                }
              }}
            />
          </Box>
        </FormControl>
      </Flex>

      <>
        <Box
          maxW={"434px"}
          mx="auto"
          mt="60px"
          py={"15px"}
          px={"14px"}
          bg={"#D4FEAE"}
          borderRadius={"8px"}
        >
          <Flex alignItems={"center"} textAlign={"center"}>
            <Text fontSize={"15px"} fontWeight={"600"}>
              {offerRequirementSummary({
                isPrerequisite: inputValues.isPrerequisite,
                contentText: contentTypeText(inputValues.contentType),
                specificProduct: inputValues.specificProduct,
                discountType: inputValues.discountType,
                accountName,
                instagramHandle,
                isFreeReward: inputValues.isFreeReward,
                compensation: inputValues.compensation,
                percentOrCashOff: inputValues.percentOrCashOff,
                discountItem: inputValues.discountItem,
                prerequisiteQuantity: inputValues.prerequisiteQuantity,
                prerequisiteName: inputValues.prerequisiteName,
                minimumFollowerCount: inputValues.minimumFollowerCount,
                expirationLimit: inputValues.expirationLimit,
                minPurchaseAmount: inputValues.minPurchaseAmount,
                hashtag: inputValues.hashtag
              })}
              {inputValues?.minimumFollowerCount > 0 &&
                ` Redeemers should have at least ${inputValues?.minimumFollowerCount} followers on Instagram.`}{" "}
              {`This coupon expires ${inputValues?.expirationLimit} days after the
                            user receives it.`}
              {
                inputValues?.isEvergreenCode &&
                ` Code ${inputValues?.ecommerceDiscountCode} will be distributed to the people who complete the offer.`
              }
            </Text>
          </Flex>
        </Box>
      </>
    </Box>
  );
};

export default OfferRequirementsLimits;
