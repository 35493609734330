import { Text, Flex, Box, Grid, Button, Checkbox } from "@chakra-ui/react";

interface ContentTypeCheckboxProps {
  header: string;
  description: string;
  isChecked: boolean;
  handleCheckboxChange: Function;
  contentType: string;
  isComplete: boolean;
}

function ContentTypeCheckbox({
  header,
  description,
  isChecked,
  handleCheckboxChange,
  contentType,
  isComplete,
}: ContentTypeCheckboxProps) {
  return (
    <Flex alignItems="center" justifyContent="start" ml="1%" width="40%">
      <Checkbox
        borderRadius="full"
        size="lg"
        isChecked={isChecked}
        onChange={() => handleCheckboxChange(contentType, !isChecked)}
      />
      <Flex
        flexDirection="column"
        height="10%"
        alignItems="start"
        justifyContent="center"
        ml="5%"
      >
        <Flex justifyContent="center" alignItems="center">
          <Text fontSize="18px" fontWeight="400" color="black">
            {header}
          </Text>

          {isComplete && (
            <Box
              ml="10px"
              backgroundColor="#D3FFD2"
              borderRadius="10px"
              width="125px"
              p="1"
            >
              <Flex width="100%" alignItems="center" justifyContent="center">
                <Text fontSize="11px" color="#117C00">
                  Setup complete
                </Text>
              </Flex>
            </Box>
          )}
        </Flex>
        <Text
          fontSize="14px"
          fontWeight="300"
          color="#667085"
          mt=".5%"
          width="120%"
        >
          {description}
        </Text>
      </Flex>
    </Flex>
  );
}

export default ContentTypeCheckbox;
