/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { Box, FormControl, FormLabel, Input, Flex } from "@chakra-ui/react";
interface OfferTitleProps {
  inputValues: {
    offerName: string;
  };
  onInputChange: (
    fieldName: keyof OfferTitleProps["inputValues"],
    value: string
  ) => void;
}

const OfferTitlePage: React.FC<OfferTitleProps> = ({
  inputValues,
  onInputChange,
}) => {
  const handleTitleChange = (event: any) => {
    const { value } = event?.target;
    onInputChange("offerName", value);
  };

  return (
    <Box py="60px">
      <Flex flexFlow={"column"} gap={"60px"}>
        <FormControl display="flex" alignItems="center" gap="20px">
          <FormLabel m="0" variant={"chakra_label_default"}>
            Name
          </FormLabel>
          <Box w={"100%"}>
            <Input
              width="100%"
              variant="chakra_input_default"
              size="chakra_md_medium"
              fontSize={"chakra_sm"}
              fontWeight={"500"}
              type="text"
              value={inputValues.offerName}
              onChange={handleTitleChange}
              maxLength={100}
            />
          </Box>
        </FormControl>
      </Flex>
    </Box>
  );
};

export default OfferTitlePage;
