/* eslint-disable */

import React from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button, Flex, FormControl,
  FormLabel,
  Heading,
  Icon,
  Input, Spinner, Text,
  useColorModeValue
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";

// Custom components
import AuthDecoration from "layouts/auth/decoration";
import { FaChevronLeft } from "react-icons/fa"; // Import the chevron left icon from react-icons
import { useHistory } from "react-router-dom";
import { createNewWaitListDoc } from "services/firebaseService";

// Assets

function WaitListScreen() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.500";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);

  const handleClick = () => setShow(!show);
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");

  const history = useHistory();

  const uploadWaitListInformation = async (
    name: string,
    email: string,
    company: string,
    phoneNumber: string
  ) => {
    try {
      console.log(name, email, company, phoneNumber);
      await createNewWaitListDoc(name, email, phoneNumber, company);
    } catch (error) {
      console.log(error);
    }
  };

  const [hasSubmittedInfo, setHasSubmittedInfo] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <AuthDecoration>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        {hasSubmittedInfo && (
          <Text mb="20px" fontWeight="500" letterSpacing="0.0175em">
            <Flex alignItems="center">
              {isLoading ? (
                <Spinner color="green.500" mr="10px" />
              ) : (
                <Icon as={FaCheckCircle} color="green.500" mr="10px" />
              )}
              {isLoading
                ? "Submitting your information..."
                : "You have joined the waitlist! We'll be in touch."}
            </Flex>
          </Text>
        )}

        <Box me="auto">
          <Flex alignItems="center">
            <NavLink to="/login">
              <FaChevronLeft size="18px" />
            </NavLink>
            <Heading color={textColor} fontSize="36px" mb="10px" ml="10px">
              Join our waitlist 👋
            </Heading>
          </Flex>

          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Request an account to start using storyit
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Name<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              // borderColor="" // Change to the desired color
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              borderRadius="13px"
              placeholder="Your name" //gray.500
              mb="24px"
              fontWeight="500"
              size="lg"
              onChange={(e) => setName(e.target.value)}
            />

            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              // borderColor="" // Change to the desired color
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              borderRadius="13px"
              placeholder="you@email.com"
              mb="24px"
              fontWeight="500"
              size="lg"
              onChange={(e) => setEmail(e.target.value)}
            />

            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Company <Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              // borderColor="" // Change to the desired color
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              borderRadius="13px"
              placeholder="Your company"
              mb="24px"
              fontWeight="500"
              size="lg"
              onChange={(e) => setCompany(e.target.value)}
            />

            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Phone Number<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              // borderColor="" // Change to the desired color
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="tel"
              borderRadius="13px"
              placeholder="Your phone number"
              mb="24px"
              fontWeight="500"
              size="lg"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />

            <Button
              fontSize="sm"
              bg="black"
              color="white"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              transition="transform 0.2s" // Add transition property
              _hover={
                email !== "" &&
                  phoneNumber !== "" &&
                  company !== "" &&
                  name !== "" ?
                  {
                    transform: "scale(1.05)",
                    cursor: "pointer", // Add cursor pointer on hover
                  } :
                  {}
              }
              isDisabled={
                email === "" ||
                name === "" ||
                phoneNumber === "" ||
                company === ""
              } // Use logical OR here
              onClick={async () => {
                try {
                  setHasSubmittedInfo(true);
                  setIsLoading(true);
                  await uploadWaitListInformation(
                    name,
                    email,
                    company,
                    phoneNumber
                  );
                } catch (error) {
                  console.error("An error occurred:", error);
                } finally {
                  setIsLoading(false);
                  setEmail("");
                  setName("");
                  setCompany("");
                  setPhoneNumber("");
                }
              }}
            >
              Submit
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <NavLink to="/login">
              <Text color={textColorDetails} fontWeight="400" fontSize="14px">
                Already have an account?
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Log in
                </Text>
                {/* {currentPage === 'wait-list' && history.push()} */}
              </Text>
            </NavLink>
          </Flex>
        </Flex>
      </Flex>
    </AuthDecoration>
  );
}

export default WaitListScreen;
